import Coaches from './coaches';
import Users from './users';
import Auth from './auth';
import Subscriptions from './subscriptions';
import Format from './format';
import Theme from './theme';
import Sessions from './sessions';
import SubCoachee from './subscription-coachee';
import Scopes from './scopes';
import Programs from './programs';
import Objectives from './objectives';
import Program_Documents from './program_documents';
import Actions from './actions';
import Session_Notes from './session_notes';
import Companies from './companies';
import Emails from './emails';
import CompanyRessources from './company-ressources';
import Application from './application';
import Guests from './guests';
import Stats from './stats';
import SesstionTemplates from './session_templates';
import TargetTemplateItems from './target_template_items';
import FormSettings from './formSettings';
import Departments from './departments';
import SeniorityLevels from './seniority_levels';
import Reminders from './reminders';
import CoachAvailability from './coach-availability';
import SelfRegistration from './self-registration';

export default {
  Coaches,
  Users,
  Auth,
  Subscriptions,
  Format,
  Theme,
  Programs,
  Sessions,
  SubCoachee,
  Scopes,
  Objectives,
  Program_Documents,
  Actions,
  Session_Notes,
  Companies,
  Emails,
  CompanyRessources,
  Application,
  Guests,
  Stats,
  SesstionTemplates,
  TargetTemplateItems,
  FormSettings,
  Departments,
  SeniorityLevels,
  Reminders,
  CoachAvailability,
  SelfRegistration,
};
