import React, { useState, useRef } from 'react';

// UI Lib dependencies
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import dayjs from 'dayjs';
import Button from 'ui/components/Button/Button';

// UI Local Lib dependencies
import DataGrid from 'ui/components/DataGrid';
import Input from 'ui/components/Input';
import DeleteModal from '../DeleteModal';
import LoadingButton from 'ui/components/LoadingButton';

// Hooks
import { useTranslation } from 'react-i18next';

//import useTargetTemplateItems from 'hooks/usecase/useTargetTemplateItems';
import useDepartments from 'hooks/usecase/useDepartments';

// Configs
import { tableSchemaBase as columns } from './Departments.config';

// Validation
import validation from './validators/company.validator';

// Icons
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { useMeQuery } from 'hooks/queries';
import { useParams } from 'react-router-dom';

const Departments = ({ isAdmin, disableInformationText = false }) => {
  const { t } = useTranslation();

  // States
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const { data: user } = useMeQuery();
  let { companyId } = useParams();
  if (!companyId) {
    companyId = user.companyId;
  }

  const [queryParams, setQueryParams] = useState({ page: 1, size: 10, companyId: companyId });

  // Hook
  const {
    departments,
    control,
    isLoading,
    isEditLoading,
    isAddLoading,
    onRemoveDepartment,
    onAddDepartment,
    isOpenAddModal,
    setIsOpenAddModal,
    reset,
    isOpenEditModal,
    setIsOpenEditModal,
    onEditDepartment,
    totalItems,
    totalPages,
    setEditingDepartment,
  } = useDepartments(companyId, queryParams);

  // Refs
  const refName = useRef();

  // Helper
  const updateList = (data) =>
    data?.rows?.reduce((acc, currentItem) => {
      const modifiedItem = {
        ...currentItem,
        createdAt: dayjs(currentItem.createdAt).format('DD-MM-YYYY'),
      };
      acc.push(modifiedItem);

      return acc;
    }, []);

  const handleOnFilter = (filters, currentPage, currentSize) => {
    let queryParamsFilter = {};
    if (filters.items) {
      filters.items.map((filter) => {
        queryParamsFilter = { ...queryParamsFilter, [filter.field]: filter.value };
      });
    }

    setQueryParams({
      ...{ orderBy: queryParams.orderBy, orderDirection: queryParams.orderDirection },
      page: currentPage,
      size: currentSize,
      ...queryParamsFilter,
    });
  };

  React.useEffect(() => {
    console.log('QUERY PARAMS:', queryParams);
  }, [queryParams]);

  const handleOnSort = (sort, currentPage, currentSize) => {
    let queryParamsSort = {};
    switch (sort.orderBy) {
      case 'Name':
        queryParamsSort = { ...sort, orderBy: 'name' };
        break;
      case '':
        queryParamsSort = { orderBy: '' };
        break;
      default:
        queryParamsSort = { ...sort };
        break;
    }
    setQueryParams({ ...queryParams, page: currentPage, size: currentSize, ...queryParamsSort });
  };

  const handleOpenModal = () => {
    setIsOpenAddModal(true);
  };

  const handleCloseModal = () => {
    setIsOpenAddModal(false);
    reset();
  };

  const handleCloseEditModal = () => {
    setIsOpenEditModal(false);
    reset({ name: '' });
  };

  return (
    <Stack
      sx={
        isAdmin
          ? { minHeight: '630px', paddingLeft: '90px', backgroundColor: 'white' }
          : { minHeight: '630px', width: '100%', padding: '25px 0px', backgroundColor: 'white' }
      }>
      {!isAdmin && !disableInformationText && (
        <Paper
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#0288d12b', // Blue background color
            padding: '16px',
            boxShadow: 'none', // Remove box-shadow
            width: 'fit-content', // Adjust width for content
          }}>
          <InfoOutlinedIcon sx={{ color: '#0288D1', marginRight: '8px' }} />
          <Box>
            <Typography
              variant="subtitle1"
              sx={{
                color: 'var(--Light-Info-Shades-160p, #0288D1)',
                fontFamily: 'Karla',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '150%', // 24px
                letterSpacing: '0.15px',
                fontFeatureSettings: "'clig' off, 'liga' off",
              }}>
              {t('settings_departments_tab_info')}
            </Typography>
          </Box>
        </Paper>
      )}
      <Stack sx={isAdmin ? { paddingBottom: '9px' } : { paddingBottom: '9px', padding: '25px 0px' }}>
        <Button endIcon={<AddIcon />} onClick={handleOpenModal}>
          {t('add')}
        </Button>
      </Stack>
      <DataGrid
        columns={columns}
        rows={departments ? updateList(departments) : []}
        onPaginationChange={(page, nbrPages) => {
          setQueryParams({ ...queryParams, page: page, size: nbrPages });
        }}
        loading={isLoading}
        totalItems={totalItems}
        totalPages={totalPages ? totalPages : 0}
        onFilter={handleOnFilter}
        onSort={handleOnSort}
        onDelete={(item) => {
          setSelectedItem(item);
          setIsOpenDeleteModal(true);
        }}
        onEdit={(scope) => {
          setEditingDepartment(scope);
        }}
        onRowClicked={(scope, event) => {
          if (!event.defaultMuiPrevented) {
            setEditingDepartment(scope.row);
          }
        }}
      />

      <Dialog open={isOpenAddModal} onClose={handleCloseModal}>
        <DialogTitle>
          <Typography
            style={{
              color: '#000000DE',
              fontSize: '24px',
              fontWeight: 700,
              lineHeight: '32px',
              marginTop: '15px',
            }}>
            {t('create_department')}
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            overflowX: 'hidden',
            '&::-webkit-scrollbar': {
              '-webkit-appearance': 'none',
              width: '4px',
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '25px',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              '-webkit-box-shadow': '0 0 1px rgba(255, 255, 255, 0.5)',
            },
          }}>
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}>
            <CloseIcon />
          </IconButton>
          <Box sx={{ padding: '8px 24px', height: '70px', minWidth: '300px' }}>
            <Input
              ref={refName}
              control={control}
              variant="outlined"
              label="Name"
              name="name"
              rules={validation.name}
              fullWidth
            />
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '19px 77px',
            borderTop: '1px solid #dfe0e0',
          }}>
          <Button disabled={isAddLoading} variant="secondary" onClick={handleCloseModal}>
            {'BACK'}
          </Button>
          <LoadingButton
            isloading={isAddLoading}
            onClick={() => {
              onAddDepartment();
            }}
            title="SAVE"
          />
        </DialogActions>
      </Dialog>

      <Dialog open={isOpenEditModal} onClose={handleCloseEditModal}>
        <DialogTitle>
          <Typography
            style={{
              color: '#000000DE',
              fontSize: '24px',
              fontWeight: 700,
              lineHeight: '32px',
              marginTop: '15px',
            }}>
            {t('edit_department')}
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            overflowX: 'hidden',
            '&::-webkit-scrollbar': {
              '-webkit-appearance': 'none',
              width: '4px',
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '25px',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              '-webkit-box-shadow': '0 0 1px rgba(255, 255, 255, 0.5)',
            },
          }}>
          <IconButton
            aria-label="close"
            onClick={() => setIsOpenEditModal(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}>
            <CloseIcon />
          </IconButton>
          <Box sx={{ padding: '8px 24px', height: '70px', minWidth: '300px' }}>
            <Input
              ref={refName}
              control={control}
              variant="outlined"
              label="Name"
              name="name"
              rules={validation.name}
              fullWidth
            />
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '19px 77px',
            borderTop: '1px solid #dfe0e0',
          }}>
          <Button disabled={isEditLoading} variant="secondary" onClick={handleCloseEditModal}>
            {'BACK'}
          </Button>
          <LoadingButton
            isloading={isEditLoading}
            onClick={() => {
              onEditDepartment();
            }}
            title="SAVE"
          />
        </DialogActions>
      </Dialog>

      <DeleteModal
        title="Delete department"
        description="Are you sure you want to delete this department"
        open={isOpenDeleteModal}
        onDelete={() => {
          onRemoveDepartment(selectedItem.id);
        }}
        handelModal={() => {
          setIsOpenDeleteModal(false);
        }}
      />
    </Stack>
  );
};

export default Departments;
