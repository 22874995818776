const initialState = {
  scopes: [],
  completScopes: [],
  allScopes: [],
  currentPage: 1,
  size: 10,
  totalPages: 0,
};

export const createScopeSlice = (set) => ({
  ...initialState,
  setScopes: (newArray) => set({ scopes: newArray }),
  setCompleteScopes: (newArray, size) =>
    set({ completScopes: newArray, totalPages: Math.ceil(newArray.length / size) }),
  setCurrentPage: (newPage) => set({ currentPage: newPage }),
  setSize: (newSize) => set({ size: newSize }),
  setTotalPages: (newTotalPages) => set({ totalPages: newTotalPages }),
  setAllScopes: (newAllScopes) => set({ allScopes: newAllScopes }),
});
