export const dateParser = (dateUnformatted, format) => {
  let options = {};
  if (format == 'day') {
    options = { year: 'numeric', month: 'numeric', day: 'numeric' };
  } else {
    options = {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    };
  }
  const timestamp = Date.parse(dateUnformatted);

  const date = new Date(timestamp).toLocaleDateString('fr-FR', options);

  return date.toString();
};

export function sortByAttribute(list, attribute) {
  return [...list].sort((a, b) => {
    const valueA = a[attribute];
    const valueB = b[attribute];

    if (valueA == null && valueB == null) {
      return 0;
    }
    if (valueA == null) {
      return 1;
    }
    if (valueB == null) {
      return -1;
    }
    if (typeof valueA === 'string' && typeof valueB === 'string') {
      return valueA.localeCompare(valueB);
    }
    return valueA - valueB;
  });
}

export function groupByAttribute(list, attribute) {
  const grouped = {};
  list.forEach((element) => {
    const key = element[attribute];
    if (!grouped[key]) {
      grouped[key] = [];
    }
    grouped[key].push(element);
  });

  return grouped;
}

export function truncateString(string, maxLength, options = {}) {
  const { separator = '...', omission = separator } = options;

  if (string.length <= maxLength) {
    return string;
  }

  let truncatedString = string.substr(0, maxLength - omission.length);

  if (separator) {
    const lastSeparatorIndex = truncatedString.lastIndexOf(separator);

    if (lastSeparatorIndex !== -1) {
      truncatedString = truncatedString.substr(0, lastSeparatorIndex) + omission;
    } else {
      truncatedString += omission;
    }
  } else {
    truncatedString += omission;
  }

  return truncatedString;
}

export function removeEmptyFields(obj) {
  const newObj = {};
  for (const key in obj) {
    if (obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
      newObj[key] = obj[key];
    }
  }
  return newObj;
}

export function mostFrequent(arr) {
  return Object.entries(
    arr.reduce((a, v) => {
      a[v] = (a[v] ?? 0) + 1;
      return a;
    }, {})
  ).reduce((a, v) => (v[1] >= a[1] ? v : a), [null, 0])[0];
}

export function getIsoLanguageWithI18nLanguage(i18nLanguage) {
  const mapping = { fr: 'fr-FR' };

  return mapping[i18nLanguage] || i18nLanguage;
}

export function getContrastTextColor(colorBg, blackColor = 'black', whiteColor = 'white') {
  colorBg = colorBg.replace('#', '');

  // Converts hexadecimal color to RGB values
  var r = parseInt(colorBg.substr(0, 2), 16);
  var g = parseInt(colorBg.substr(2, 2), 16);
  var b = parseInt(colorBg.substr(4, 2), 16);

  // Calculates contrast using the relative brightness formula
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;

  return yiq >= 128 ? blackColor : whiteColor;
}
