/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

/* eslint-disable react/react-in-jsx-scope */
import React, { useRef, useEffect } from 'react';
// UI Lib dependencies
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { useController } from 'react-hook-form';
import EventIcon from '@mui/icons-material/Event';
import i18next from 'i18next';
import { getIsoLanguageWithI18nLanguage } from 'common/utils';
import { useTranslation } from 'react-i18next';

// Importing locale configurations for different languages in the Day.js library
import 'dayjs/locale/fr';
import 'dayjs/locale/en';
import 'dayjs/locale/pt';
import 'dayjs/locale/it';
import 'dayjs/locale/es';

import styles from './DatePicker.styles';
const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const DatePicker = ({
  handleDateChange,
  control,
  name,
  exportDate,
  setToggleDatePicker,
  toggleDatePicker,
  sx,
  disabled,
  disablePast = false,
  ...props
}) => {
  const {
    field: { onChange: handleChange, value },
  } = useController({ control, name, defaultValue: null });
  const ref = useRef();

  const { t } = useTranslation();

  /* ********************************  HELPERS ******************************* */

  const changeHandler = (value) => {
    if (value) {
      handleChange(dayjs(value.$d).toISOString());
    } else {
      handleChange(null);
    }
    if (handleChange && exportDate) {
      console.log('value.$d -> ', value.$d);
      handleDateChange(dayjs(value.$d).toISOString());
      console.log('dayjs(value.$d).toISOString() -> ', dayjs(value.$d).toISOString());
      exportDate(dayjs(value.$d).toISOString());
    }
  };
  /* ********************************** HOOKS ********************************* */

  useEffect(() => {
    if (toggleDatePicker) {
      setTimeout(() => {
        ref.current.click();
      }, 0);
      setToggleDatePicker(!toggleDatePicker);
    }
  }, [toggleDatePicker]);

  /* ********************************** CONSTANTS ********************************* */

  const ADAPTER_LOCALE = i18next ? getIsoLanguageWithI18nLanguage(i18next.language) : 'en';

  return (
    <LocalizationProvider adapterLocale={ADAPTER_LOCALE} dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DateTimePicker']}>
        <DesktopDateTimePicker
          label={t('date_picker')}
          disabled={disabled}
          closeOnSelect={false}
          name="date"
          disablePast={disablePast}
          onChange={changeHandler}
          format="MMMM DD, YYYY - HH:mm"
          sx={{ ...styles.dateTimePicker, ...sx }}
          value={!value || value.invalid ? null : dayjs(value.ts || value)}
          slots={{
            openPickerIcon: () => (
              <div style={{ display: 'flex' }} ref={ref}>
                <EventIcon sx={disabled ? {} : { color: 'primary.main' }} />
              </div>
            ),
          }}
          {...props}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
};

export default DatePicker;
