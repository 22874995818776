export const getTags = (skills, languages) => {
  let result = [];
  if (skills && Array.isArray(skills)) {
    result = [...result, ...skills];
  }
  if (languages && Array.isArray(languages)) {
    result = [...result, ...languages];
  }
  return result;
};
