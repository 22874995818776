import React from 'react';
import { Box, Button, Grid } from '@mui/material';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import { truncateString } from 'common/utils';
import Typography from '@mui/material/Typography';

import styles from './CardProgram.styles';
import { MINIMUM, SHOW_MORE, SESSIONS } from 'litterals';

const imageNoData = `https://craftsnippets.com/articles_images/placeholder/placeholder.jpg`;

const CardProgram = ({
  title,
  nbSessions,
  objectives,
  onSelect,
  onShowMoreInfo,
  picture,
  t,
  showSelectButton,
  selected = false,
}) => {
  /* ********************************** CONSTANTS ********************************* */

  const containerStyles = !selected ? styles.container : { ...styles.container, ...styles.containerSelected };

  return (
    <Tooltip title={title && title.length > 25 ? title : ''}>
      <Box
        onClick={() => {
          onSelect();
        }}
        sx={containerStyles}>
        <Box component="img" src={picture ? picture : imageNoData} sx={styles.picture} />

        <Box sx={{ display: 'flex', flexDirection: 'column', padding: '16px' }}>
          <Box sx={{ minHeight: '60px', marginBottom: '16px' }}>
            <Grid container sx={{ flexGrow: 1 }} spacing={1}>
              <Grid item>
                <Chip
                  label={`${nbSessions} ${t(SESSIONS)} ${t(MINIMUM)}`}
                  sx={{ color: '#B18225', backgroundColor: '#FEBA361F', fontSize: '13px', fontWeight: 400 }}
                />
              </Grid>
            </Grid>

            <Typography sx={{ fontSize: '20px', lineHeight: '32px', fontWeight: 500 }}>
              {truncateString(`${title}`, 25)}
            </Typography>

            <Grid container sx={{ flexGrow: 1 }} spacing={1}>
              {objectives &&
                Array.isArray(objectives) &&
                objectives.slice(0, 8).map((objective) => (
                  <Tooltip key={'id-' + Math.random()} title={objective && objective.length > 12 ? objective : ''}>
                    <Grid item>
                      <Chip
                        size="small"
                        label={truncateString(`${objective}`, 12)}
                        color="secondary"
                        sx={{ color: '#000000', fontSize: '12px', fontWeight: 400 }}
                      />
                    </Grid>
                  </Tooltip>
                ))}
            </Grid>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            {showSelectButton && (
              <Button
                onClick={(event) => {
                  event.stopPropagation();
                  onSelect();
                }}
                sx={{ width: '75px' }}
                variant="contained">
                {t('select')}
              </Button>
            )}
            <Button
              onClick={(event) => {
                event.stopPropagation();
                onShowMoreInfo();
              }}
              sx={showSelectButton ? {} : { margin: 'auto' }}
              variant="text">
              {t(SHOW_MORE)}
            </Button>
          </Box>
        </Box>
      </Box>
    </Tooltip>
  );
};

export default CardProgram;
