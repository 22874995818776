import { useState, useEffect } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import api from 'api';
import { DateTime } from 'luxon';
import { useAlert } from 'common/alertContext';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
var timezone = require('dayjs/plugin/timezone');
dayjs.extend(timezone);

const useCoachAvailability = (coachId) => {
  const { setStateAlert } = useAlert();
  const [availabilitySelected, setAvailabilitySelected] = useState({});
  const { t } = useTranslation();

  const defaultValues = {
    date: DateTime.fromISO(availabilitySelected.date).setZone('local') || null,
    id: availabilitySelected.id,
    duration: 60,
  };

  const queryClient = useQueryClient();
  const { control, formState, reset, getValues } = useForm({
    mode: 'all',
    defaultValues,
  });

  useEffect(() => {
    reset({
      date: DateTime.fromISO(availabilitySelected.date).setZone('local') || null,
      duration: availabilitySelected.duration || 60,
    });
  }, [availabilitySelected]);

  const mutation = useMutation(api.CoachAvailability.edit, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-coach-availabilities', coachId]);
      setStateAlert({ open: true, message: `${t('edit_availability')}` });
    },
    onError: (err) => {
      if (err?.response?.data?.code === 6003) {
        setStateAlert({ open: true, message: `${t('collision_availability')}`, type: 'error' });
      }
    },
  });

  const mutationDeleteAvailability = useMutation(api.CoachAvailability.delete, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-coach-availabilities', coachId]);
      setStateAlert({ open: true, message: t('delete_availability') });
      setAvailabilitySelected({});
    },
  });

  const mutationAddCoachAvailability = useMutation(api.CoachAvailability.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-coach-availabilities', coachId]);
      setStateAlert({ open: true, message: t('create_availability') });
      reset();
    },
    onError: (err) => {
      if (err?.response?.data?.code === 6003) {
        setStateAlert({ open: true, message: `${t('collision_availability')}`, type: 'error' });
      }
    },
  });

  const submit = () => {
    const data = getValues();
    const payload = {
      duration: data.duration || 0,
    };

    if (data.date && typeof data.date === 'string') {
      payload.date = DateTime.fromISO(data.date).toUTC().toSQL();
    } else {
      setStateAlert({ open: true, message: `${t('invalid_data_availability')}`, type: 'error' });
      return;
    }

    if (availabilitySelected.id) {
      return mutation.mutate({ coachId, availabilityId: availabilitySelected.id, payload });
    }

    return mutationAddCoachAvailability.mutate({ coachId, payload });
  };

  const deleteAvailability = (availabilityId) => {
    return mutationDeleteAvailability.mutate({ coachId, availabilityId });
  };

  return {
    formState,
    state: { form: { control }, availabilitySelected, getValues },
    actions: { submit, deleteAvailability },
    setAvailabilitySelected,
  };
};

export default useCoachAvailability;
