import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import api from 'api';
import { useAlert } from 'common/alertContext';
import { useCompanyQuery, useEmailTemplateQuery } from 'hooks/queries';
import { MESSAGE_SUCCESS_CHANGES } from 'litterals';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';

const defaultValues = {
  companyId: '',
  name: '',
  hasTerms: '',
  linkTerms: '',
  documentationCollaborator: '',
  documentationExpert: '',
  feedbackLink: '',
};

const useEmailTemplates = () => {
  const { setStateAlert } = useAlert();
  const { t } = useTranslation();
  const { handleSubmit, control, reset } = useForm({
    mode: 'all',
    defaultValues: defaultValues,
  });
  const [showDialog, setShowDialog] = useState(false);
  const queryClient = useQueryClient();

  const mutation = useMutation(api.Emails.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-email-template']);
      queryClient.invalidateQueries(['get-list-emails']);
      setShowDialog(false);
      reset();
    },
  });

  const submitForm = handleSubmit((data) => {
    const payload = {
      name: data.name,
      companyId: data.companyId,
      subject: data?.subject,
      from: data?.from,
      fromName: data?.fromName,
      content: data?.content,
    };
    mutation.mutate(payload);
    setStateAlert({ open: true, message: `${t(MESSAGE_SUCCESS_CHANGES)}` });
  });

  const close = () => {
    setShowDialog(false);
    reset();
  };

  const clickAdd = () => setShowDialog(true);

  return {
    state: { form: { control }, nav: { showDialog } },
    actions: { clickAdd, close, submitForm },
  };
};

export const useEditEmailTemplates = (id) => {
  const queryClient = useQueryClient();
  const { data, isLoading } = useEmailTemplateQuery(id);
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    watch,
    reset,
    resetField,
    formState: { isDirty },
  } = useForm({ mode: 'all', defaultValues });
  const { setStateAlert } = useAlert();

  useEffect(() => {
    reset({
      name: data?.name || undefined,
      subject: data?.subject || undefined,
      from: data?.from || undefined,
      fromName: data?.fromName || undefined,
      content: data?.content || undefined,
      companyId: data?.companyId || undefined,
    });
  }, [data, reset]);

  const onNavigate = () => {
    navigate(`/email_templates/${id}`);
  };

  const mutation = useMutation(api.Emails.edit, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-email-template']);
      queryClient.invalidateQueries(['get-list-emails']);
      setStateAlert({ open: true, message: t(MESSAGE_SUCCESS_CHANGES) });
    },
    onError: (error) => {
      console.log('error', error.response.data.error);
      setStateAlert({ open: true, message: error.response.data.error, type: 'error' });
    },
  });

  const submitForm = handleSubmit(async (data) => {
    mutation.mutate({ id: id, data: data });
  });

  return {
    state: {
      form: { control: control, resetField, isDirty },
      query: { data, isLoading },
    },
    actions: { watch, submitForm, onNavigate },
  };
};

export const useDeleteEmailTemplates = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(api.Companies.delete, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-list-companies']);
    },
  });

  const remove = (id) => {
    mutation.mutate(id);
  };

  return {
    actions: { remove },
  };
};

export default useEmailTemplates;
