import React from 'react';

import { Avatar, Tooltip } from '@mui/material';
import AvatarGroupMui from '@mui/material/AvatarGroup';

import styles from './AvatarGroup.styles';

const AvatarGroup = ({ avatarConfig, max = 10, width = 48 }) => {
  const addMarginLeft = (index) => {
    if (index) return { marginLeft: '-14px !important' };
    return {};
  };
  return (
    <AvatarGroupMui max={max}>
      {avatarConfig.map(({ id, firstname, lastname, picture }, index) => (
        <Tooltip key={id} title={`${firstname} ${lastname}`}>
          {picture && firstname && lastname ? (
            <Avatar
              sx={{ ...styles.avatar, ...styles.avatarPicture, width, height: width, ...addMarginLeft(index) }}
              src={picture}
              alt={`${firstname} ${lastname}`}
            />
          ) : (
            <Avatar
              sx={{
                ...styles.avatar,
                width,
                height: width,
                ...addMarginLeft(index),
              }}>{`${firstname[0]} ${lastname[0]}`}</Avatar>
          )}
        </Tooltip>
      ))}
    </AvatarGroupMui>
  );
};

export default AvatarGroup;
