import { post } from './request';

class SelfRegistration {
  static validateSelfRegistration({ id }) {
    return post(`/self-registration/${id}/validate`).then((res) => res.data);
  }

  static createSelfRegistration(payload) {
    return post('/self-registration/create', payload).then((res) => res.data);
  }
}

export default SelfRegistration;
