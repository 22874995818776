import React, { useEffect, useState } from 'react';
import { Box, Grid, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import MultiSelect from 'ui/pages/evergreen-link/components/MultiSelect/MultiSelect';
import MultiSelectLanguages from 'ui/pages/evergreen-link/components/MultiSelectLanguages/MultiSelectLanguages';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { LANGUAGES_OPTIONS } from 'utils/languages';
import Pagination from '@mui/material/Pagination';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import useStore from 'store';
import useViewModal from 'hooks/shared/useViewModal';
import CoachViewModal from 'ui/components/shared/coaches/CoachViewModal';
import Chip from '@mui/material/Chip';
import useFormPublic from 'hooks/usecase/useFormPublic';
import { getTags } from 'common/coaches';
import FilterListIcon from '@mui/icons-material/FilterList';
import Button from '../Button/Button';
import { userModalNextPaginationManagement, userModalPreviousPaginationManagement } from 'utils/pagination';

// Components
import CardCoach from 'ui/pages/evergreen-link/components/Cards/CardCoach/CardCoach';
import { FILTERS, LANGUAGES, NAME, PAGE_SIZE, RESET } from 'litterals';

const imageNoData = `${process.env.PUBLIC_URL}/uploads/images/file.png`;

const ChooseExpertContent = ({
  company,
  queryParams,
  setQueryParams,
  totalPages,
  coaches,
  isLoadingCoaches,
  isFetched,
  onlyOneSelectableCoach = false,
  onShowExpert,
  onSelect,
  showFilterButton = false,
  showSelectButton = true,
  contentCenter = false,
  showAvailabilitiesCount = false,
}) => {
  const { t } = useTranslation();
  const {
    toggleSelected,
    experts,
    setExperts,
    selectedExperts,
    addExpert,
    removeExpert,
    addOrderedExpert,
    removeOrderedExpert,
  } = useStore();
  const [labels, setLabels] = useState([]);
  const [name, setName] = useState('');
  const [showFilter, setShowFilter] = useState(!showFilterButton);
  const [seniorityLevelsOptions, setSeniorityLevelsOptions] = useState([]);
  const [timeZonesOptions, setTimeZonesOptions] = useState([]);
  const [departmentsOptions, setDepartmentsOptions] = useState([]);
  const [modalCoachActionType, setModalCoachActionType] = useState('');

  const sxContainer = contentCenter ? { justifyContent: 'center' } : {};

  useEffect(() => {
    if (coaches && Array.isArray(coaches) && isFetched) {
      setExperts(
        [...coaches].map((item) => {
          return { ...item, isSelected: false };
        })
      );
    }
  }, [coaches]);

  useEffect(() => {
    if (stateModal.open && coaches && coaches.length && !isLoadingCoaches) {
      if (modalCoachActionType === 'next') {
        actions.openModal(coaches?.[0]?.id);
      } else {
        actions.openModal(coaches?.[coaches.length - 1]?.id);
      }
    }
  }, [queryParams.page, isLoadingCoaches]);

  const capitalizeString = (child) => {
    return child;
  };
  const { departments, seniorityLevels, timeZones } = useFormPublic(company.evergreenLink);

  useEffect(() => {
    if (seniorityLevels) {
      setSeniorityLevelsOptions(
        seniorityLevels.map((level) => {
          return { label: capitalizeString(t(level.name)), value: level.id };
        })
      );
    }
  }, [seniorityLevels]);

  useEffect(() => {
    if (timeZones?.length) {
      setTimeZonesOptions(
        timeZones.map((timeZone) => {
          return { label: `${timeZone.label} (${timeZone.value})`, value: timeZone.value };
        })
      );
    }
  }, [timeZones]);

  useEffect(() => {
    if (departments) {
      setDepartmentsOptions(
        departments.map((department) => {
          return { label: capitalizeString(t(department.name)), value: department.id };
        })
      );
    }
  }, [departments]);

  const handleTransformListForOptionsSelect = (list) =>
    list.map((item) => ({
      label: item.name,
      value: item.name,
    }));

  const handleChangePagination = (event, value) => {
    const filter = { ...queryParams, ...{ page: value } };
    setQueryParams(filter);
  };

  const handleChangeSize = (event) => {
    const filter = { ...queryParams, ...{ size: event.target.value } };
    setQueryParams(filter);
  };

  const handleDeleteFilter = (key, value) => {
    setLabels(labels.filter((obj) => obj.value !== value));
    setQueryParams((prevState) => {
      const updatedFilters = { ...prevState };
      updatedFilters[key] = prevState[key].filter((v) => v !== value);
      return updatedFilters;
    });
  };

  const { state: stateModal, actions } = useViewModal();
  return (
    <Box>
      {showFilterButton && (
        <Button
          variant="secondary"
          sx={{ marginTop: '24px', color: '#495465', borderColor: 'primary.border' }}
          onClick={() => setShowFilter(!showFilter)}>
          <FilterListIcon sx={{ marginRight: '4px' }} />
          {t(FILTERS)}
        </Button>
      )}
      {showFilter && (
        <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: '15px' }}>
          <Typography
            sx={{
              paddingTop: '32px',
              fontSize: '15px',
              lineHeight: '20px',
              fontWeight: 400,
              color: '#00000099',
            }}>
            {t(FILTERS)}
          </Typography>

          <TextField
            sx={{}}
            onChange={(event) => {
              const filter = { ...queryParams, page: 1, ...{ name: event.target.value } };
              setQueryParams(filter);
              setName(event.target.value);
            }}
            value={name}
            variant="standard"
            label={t(NAME)}
          />
          <MultiSelect
            label="Department"
            queryParams={queryParams.departmentId}
            options={departmentsOptions}
            keyLabel={'departmentId'}
            labels={labels}
            setLabels={setLabels}
            onChange={(data) => {
              const filter = { ...queryParams, page: 1, ...{ departmentId: data.map((item) => item.value) } };
              setQueryParams(filter);
            }}
          />
          <MultiSelect
            label={t('Expertises')}
            queryParams={queryParams.coachingSkills}
            options={handleTransformListForOptionsSelect(company.target_template_items)}
            keyLabel={'coachingSkills'}
            labels={labels}
            setLabels={setLabels}
            onChange={(data) => {
              const filter = { ...queryParams, page: 1, ...{ coachingSkills: data.map((item) => item.value) } };
              setQueryParams(filter);
            }}
          />
          <MultiSelect
            label={t('seniority_level')}
            queryParams={queryParams.seniorityLevelId}
            options={seniorityLevelsOptions}
            keyLabel={'seniorityLevelId'}
            labels={labels}
            setLabels={setLabels}
            onChange={(data) => {
              const filter = { ...queryParams, page: 1, ...{ seniorityLevelId: data.map((item) => item.value) } };
              setQueryParams(filter);
            }}
          />
          <MultiSelect
            label={t('time_zones')}
            queryParams={queryParams.timeZone}
            options={timeZonesOptions}
            keyLabel={'timeZone'}
            labels={labels}
            setLabels={setLabels}
            onChange={(data) => {
              const filter = { ...queryParams, page: 1, ...{ timeZone: data.map((item) => item.value) } };
              setQueryParams(filter);
            }}
          />
          <MultiSelectLanguages
            label={t(LANGUAGES)}
            options={LANGUAGES_OPTIONS}
            queryParams={queryParams.languagesSpoken}
            keyLabel={'languagesSpoken'}
            labels={labels}
            setLabels={setLabels}
            onChange={(data) => {
              const filter = { ...queryParams, page: 1, ...{ languagesSpoken: data.map((item) => item.value) } };
              setQueryParams(filter);
            }}
          />

          <Button
            onClick={() => {
              setName('');
              setLabels([]);
              setQueryParams({
                ...queryParams,
                page: 1,
                name: '',
                coachingSkills: [],
                departmentId: [],
                languagesSpoken: [],
                seniorityLevelId: [],
                timeZone: [],
              });
            }}
            sx={{
              marginTop: '25px',
              fontSize: '15px',
              lineHeight: '20px',
              fontWeight: 500,
              color: 'primary.main',
            }}
            variant="tertiary">
            {t(RESET)}
          </Button>
        </Box>
      )}
      <Box height={'15px'} />
      <Grid container spacing={'12px'}>
        {labels.map((obj) => (
          <Grid key={obj.label} className="filter-chip" item>
            <Chip
              size="small"
              label={obj.label}
              color="default"
              onDelete={() => handleDeleteFilter(obj.key, obj.value)}
            />
          </Grid>
        ))}
      </Grid>
      <CoachViewModal
        coach={experts && experts.find((item) => item.id === stateModal.viewId)}
        company={company}
        open={stateModal.open}
        onClose={actions.closeModal}
        directionalArrowsActivated={!!experts.length}
        availabilitiesDisabled={true}
        onClickNextCoach={() => {
          const coachIndex = experts.findIndex((item) => item.id === stateModal.viewId);
          setModalCoachActionType('next');

          userModalNextPaginationManagement({
            userIndex: coachIndex,
            users: coaches,
            totalPages,
            queryParams,
            setUserIdSelected: actions.openModal,
            setQueryParams,
          });
        }}
        onClickPreviousCoach={() => {
          const coachIndex = experts.findIndex((item) => item.id === stateModal.viewId);
          setModalCoachActionType('previous');

          userModalPreviousPaginationManagement({
            userIndex: coachIndex,
            users: coaches,
            totalPages,
            queryParams,
            setUserIdSelected: actions.openModal,
            setQueryParams,
          });
        }}
      />
      <Box height={'30px'} />

      {isLoadingCoaches ? (
        <Grid sx={{ paddingTop: '100px', paddingLeft: '50px' }} container spacing={5}>
          <Grid item>
            <Skeleton variant="rounded" width={368} height={406} />
          </Grid>
          <Grid item>
            <Skeleton variant="rounded" width={368} height={406} />
          </Grid>
          <Grid item>
            <Skeleton variant="rounded" width={368} height={406} />
          </Grid>
          <Grid item>
            <Skeleton variant="rounded" width={368} height={406} />
          </Grid>
          <Grid item>
            <Skeleton variant="rounded" width={368} height={406} />
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={'24px'} sx={sxContainer}>
          {experts &&
            Array.isArray(experts) &&
            experts.map((coach) => (
              <Grid key={'id-' + Math.random()} item>
                <CardCoach
                  id={coach.id}
                  title={coach.firstname + ' ' + coach.lastname}
                  description={coach.descriptionExperience ? coach.descriptionExperience : ''}
                  job={coach.title ? coach.title : ''}
                  skills={getTags(coach.coachingSkills, coach.languagesSpoken)}
                  timeZone={coach.timeZoneDescription}
                  showSelectButton={showSelectButton}
                  availabilitiesCount={showAvailabilitiesCount ? coach.availabilitiesCount : null}
                  isSelected={selectedExperts.findIndex((item) => item.id == coach.id) !== -1}
                  onSelect={(id) => {
                    toggleSelected(id);
                    onSelect && onSelect(id);

                    if (onlyOneSelectableCoach) {
                      selectedExperts.map(({ id }) => {
                        removeExpert(id);
                        removeOrderedExpert(id);
                      });
                    }

                    if (selectedExperts.findIndex((item) => item.id == id) == -1) {
                      addExpert(coach);
                      addOrderedExpert(coach);
                    } else {
                      removeExpert(id);
                      removeOrderedExpert(id);
                    }
                  }}
                  onShowExpert={(id) => {
                    if (!onShowExpert) {
                      actions.openModal(id);
                    } else {
                      onShowExpert(id);
                    }
                  }}
                  picture={coach.picture ? coach.picture : ''}
                />
              </Grid>
            ))}
          {experts && Array.isArray(experts) && experts.length === 0 ? (
            <Grid key={'id-' + Math.random()} item>
              <Stack alignItems={'center'}>
                {imageNoData ? <Box onClick={() => {}} component="img" src={imageNoData} /> : null}
                <Typography>{'No data'}</Typography>
              </Stack>
            </Grid>
          ) : null}
        </Grid>
      )}
      <Box sx={{ display: 'flex', paddingTop: '20px', flexDirection: { xs: 'column', sm: 'row' } }}>
        <Box sx={{ alignSelf: { xs: 'center' }, marginBottom: '10px' }}>
          <Pagination
            page={queryParams.page}
            count={totalPages}
            sx={{ color: '#0000001F' }}
            onChange={handleChangePagination}
          />
        </Box>

        <Box
          sx={{
            mt: '10px',
          }}
        />
        <Box sx={{ minWidth: '180px' }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">{t(PAGE_SIZE)} </InputLabel>
            <Select
              value={queryParams.size}
              size="small"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label={t(PAGE_SIZE)}
              defaultValue={20}
              onChange={handleChangeSize}>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};

export default ChooseExpertContent;
