import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import EmailField from 'ui/components/form/EmailField';
import SignInWithMicrosoftButton from 'ui/components/form/SignInWithMicrosoftButton';
import SignInWithCompanyButton from 'ui/components/form/SignInWithCompanyButton';
import EmailCompanyModal from 'ui/components/EmailCompanyModal/EmailCompanyModal';
import ButtonMui from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Button from 'ui/components/Button/Button';
import CheckBoxItem from 'ui/components/shared/CheckBoxItem';
import PasswordField from 'ui/components/form/PasswordField';
import { useTranslation } from 'react-i18next';
import { EMAIL, FORGET_PASSWORD, LOGIN, PASSWORD, REMEMBER_ME, WELCOME } from 'litterals';
import { Box, borderColor } from '@mui/system';
import { showIntercomVisitor } from 'utils/intercom';

const styleBox = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'space-between',
  overflow: 'scroll',
};

const styleInput = {
  margin: '12px',
};
const styleLabel = {
  color: 'primary.black',
  paddingInline: '12px',
  fontSize: '14px',
  lineHeight: '20px',
  letter: '0.25px',
  marginTop: '6px',
};

const LoginForm = ({
  control,
  errorMessage,
  subTitle,
  onSubmit,
  onSubmitCompany,
  onSubmitMicrosoft,
  onClickReset,
  onClickSelfRegistration,
  activeSelfRegistrationLink = false,
}) => {
  const [isChecked, setIsChecked] = useState(true);
  const [openEmailCompanyModal, setOpenEmailCompanyModal] = useState(false);
  const { t } = useTranslation();

  showIntercomVisitor();

  return (
    <Box sx={styleBox} component="form">
      <Typography
        gutterBottom
        component="h4"
        fontSize={'24px'}
        lineHeight={'52px'}
        color={'primary.title'}
        m={'12px'}
        textTransform={'capitalize'}
        margin={'auto'}
        fontWeight={'bold'}>
        {t(WELCOME)} !
      </Typography>
      {subTitle && (
        <Typography
          sx={{
            fontSize: '16px',
            color: 'primary.grey',
            marginBottom: '32px',
            margin: 'auto',
            maxWidth: '400px',
            textAlign: 'center',
          }}
          component="h5">
          {t(subTitle)}
        </Typography>
      )}
      <Typography sx={styleLabel}>{t(EMAIL)}</Typography>
      <EmailField
        required
        name="email"
        label=""
        placeholder="hannah.green@test.com"
        control={control}
        style={styleInput}
        InputLabelProps={{
          shrink: true,
        }}
        // InputProps={{
        //   sx: {
        //     '& input': {
        //       color: '#828282',
        //     },
        //     '& placeholder': {
        //       color: 'red',
        //     },
        //   },
        // }}
      />

      <Typography sx={styleLabel}>{t(PASSWORD)}</Typography>
      <PasswordField
        required
        name="password"
        label=""
        placeholder="Password123@"
        control={control}
        style={styleInput}
        InputLabelProps={{ shrink: true }}
      />
      <Box sx={{ margin: '6px 12px' }}>
        <CheckBoxItem
          onClick={(e) => setIsChecked(e.target.checked)}
          checked={isChecked}
          label={t(REMEMBER_ME)}
          checkedColor={'primary.main'}
        />
      </Box>

      {errorMessage && (
        <Typography
          gutterBottom
          component="h4"
          color="red"
          fontSize={'16px'}
          marginLeft={'12px'}
          lineHeight={'24px'}
          fontWeight={400}
          letterSpacing={'0.15px'}>
          {errorMessage}
        </Typography>
      )}

      <Button
        type="submit"
        variant="primary"
        onClick={onSubmit}
        sx={{
          height: '48px',
          m: '12px',
          width: 'fill-available',
          fontSize: '16px',
          marginTop: 0,
          marginBottom: '8px',
        }}>
        {t(LOGIN)}
      </Button>

      <Divider
        sx={{
          m: '12px',
          fontSize: '14px',
          ':before': {
            borderTopColor: '#a9a9a9d9',
          },
          ':after': {
            borderTopColor: '#a9a9a9d9',
          },
          color: '#6a6a6ad9',
        }}>
        {t('other_connections')}
      </Divider>

      <SignInWithCompanyButton
        label={t('login_form_connection_company')}
        sx={{ mt: '4px' }}
        onSubmit={() => setOpenEmailCompanyModal(true)}
      />

      <SignInWithMicrosoftButton onSubmitMicrosoft={onSubmitMicrosoft} label={t('login_form_connection_microsoft')} />

      <Box sx={{ display: 'flex' }}>
        {activeSelfRegistrationLink && (
          <ButtonMui
            onClick={onClickSelfRegistration}
            variant="subtitle2"
            sx={{
              fontSize: '14px',
              color: '#475462',
              maxWidth: '400px',
              margin: 'auto',
              mt: '12px',
            }}
            fullWidth={false}>
            {t('login_form_self_regirstration')}
          </ButtonMui>
        )}
        <ButtonMui
          onClick={onClickReset}
          variant="subtitle2"
          sx={{
            fontSize: '14px',
            color: '#475462',
            maxWidth: '400px',
            margin: 'auto',
            mt: '12px',
          }}
          fullWidth={false}>
          {t(FORGET_PASSWORD)}
        </ButtonMui>
      </Box>

      <EmailCompanyModal
        control={control}
        open={openEmailCompanyModal}
        onClose={() => setOpenEmailCompanyModal(false)}
        onSubmit={onSubmitCompany}
      />
    </Box>
  );
};

export default LoginForm;
