import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useAdmins } from 'hooks/usecase/useAdmins';
import { useNavigate } from 'react-router-dom';
import DeleteModal from 'ui/components/users/DeleteModal';
import DataGrid from 'ui/components/DataGrid';
import dayjs from 'dayjs';
import AddModal from 'ui/components/users/AddModal';
import EditModal from 'ui/components/users/EditModal';
import validation from '../validators/user.validator';

// Configs
import { tableSchemaBase as columns, sampleDataBase as rows } from './Admins.config';
import { ADD } from 'litterals';

const Admins = ({ role, companyId }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const [queryParams, setQueryParams] = useState({ page: 1, size: 10, companyId: companyId });
  const navigate = useNavigate();
  const [coachToDelete, setCoachToDelete] = useState(null);

  // Hooks
  const {
    admins,
    isLoading,
    isOpenAddModal,
    setIsOpenAddModal,
    totalPages,
    totalItems,
    onRemove,
    control,
    isOpenEditModal,
    setIsOpenEditModal,
    onAdd,
    onEdit,
    setEditingItem,
    reset,
    watch,
  } = useAdmins(companyId, queryParams);

  // Filter & Sort
  const handleOnFilter = (filters, currentPage, currentSize) => {
    let queryParamsFilter = {};
    if (filters.items) {
      filters.items.map((filter) => {
        switch (filter.field) {
          case 'scopeName':
            queryParamsFilter = { ...queryParamsFilter, userScope: filter.value };
            break;
          case 'user':
            queryParamsFilter = { ...queryParamsFilter, name: filter.value };
            break;
          case 'registrationDate':
            queryParamsFilter = {
              ...queryParamsFilter,
              createdAt: filter.value && dayjs(filter.value).format('YYYY-MM-DD'),
            };
            break;
          default:
            queryParamsFilter = { ...queryParamsFilter, [filter.field]: filter.value };
            break;
        }
      });
    }

    setQueryParams({
      ...{ orderBy: queryParams.orderBy, orderDirection: queryParams.orderDirection },
      page: currentPage,
      size: currentSize,
      companyId: queryParams.companyId,
      ...queryParamsFilter,
    });
  };

  const handleOnSort = (sort, currentPage, currentSize) => {
    let queryParamsSort = {};
    switch (sort.orderBy) {
      case 'scopeName':
        queryParamsSort = { ...sort, orderBy: 'userScope' };
        break;
      case 'user':
        queryParamsSort = { ...sort, orderBy: 'firstname' };
        break;
      case 'registrationDate':
        queryParamsSort = { ...sort, orderBy: 'createdAt' };
        break;
      case '':
        queryParamsSort = { orderBy: '' };
        break;
      default:
        queryParamsSort = { ...sort };
        break;
    }
    setQueryParams({ ...queryParams, page: currentPage, size: currentSize, ...queryParamsSort });
  };

  return (
    <Stack sx={{ height: '630px', backgroundColor: 'white' }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', paddingBottom: '72px' }}>
        <Button
          onClick={() => {
            setIsOpenAddModal(true);
          }}
          sx={{ marginTop: '10px', minWidth: '170px', backgroundColor: 'primary.dark' }}
          variant="contained">
          {t(ADD) + ' ' + 'ADMIN'}
        </Button>
      </Box>
      {/** DataGrid */}
      <DataGrid
        columns={columns}
        rows={admins}
        role={role}
        onDelete={(item) => {
          setIsOpen(true);
          setCoachToDelete(item);
        }}
        totalItems={totalItems}
        onShow={(coach) => {}}
        onRowClicked={(coach, event) => {
          if (!event.defaultMuiPrevented) {
            setEditingItem(coach.row);
          }
        }}
        onEdit={(user) => {
          setEditingItem(user);
        }}
        onFilter={handleOnFilter}
        onSort={handleOnSort}
        onPaginationChange={(page, nbrPages) => {
          setQueryParams({ ...queryParams, page: page, size: nbrPages });
        }}
        totalPages={totalPages ? totalPages : 0}
        loading={isLoading}
      />
      {/** Modal to add */}
      <AddModal
        title={'Create a new Admin '}
        control={control}
        isOpen={isOpenAddModal}
        onClose={() => {
          reset();
          setIsOpenAddModal(false);
        }}
        onAdd={onAdd}
        validation={validation}
        companyId={companyId}
      />
      {/** Modal to edit */}
      <EditModal
        title={'Edit admin  '}
        control={control}
        isOpen={isOpenEditModal}
        onClose={() => {
          reset();
          setIsOpenEditModal(false);
        }}
        onEdit={onEdit}
        validation={validation}
        watch={watch}
        companyId={companyId}
      />

      {/** Modal to delete */}
      <DeleteModal
        itemToDelete={coachToDelete}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onValidate={() => {
          onRemove({ id: coachToDelete.id });
          setIsOpen(false);
        }}
      />
    </Stack>
  );
};

export default Admins;
