import React, { useEffect, useMemo, useRef, useState } from 'react';
import Box from 'ui/components/system/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
// import RadioButtons from 'ui/components/form/RadioButtons';
import TextField from 'ui/components/form/TextField';
import Switch from 'ui/components/form/Switch';
import Fab from '@mui/material/Fab';
import Skeleton from '@mui/material/Skeleton';
import Select from 'ui/components/form/Select';
import MultiSelect from 'ui/components/shared/MultiSelect';
import MultiSelectChipCoach from 'ui/components/shared/MultiSelectChipCoach';
import AvatarEdit from 'ui/components/shared/AvatarEdit/AvatarEdit';
import { LANGUAGES_OPTIONS } from 'utils/languages';

import { useTranslation } from 'react-i18next';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import {
  ACTIVATE_PROFILE,
  CITY,
  EXPERIENCE_ENUM_1,
  EXPERIENCE_ENUM_2,
  EXPERIENCE_ENUM_3,
  EXPERIENCE_ENUM_4,
  LANGUAGES,
  LINKEDIN_PROFILE,
  PHONE_NUMBER,
  SAVE_CHANGES,
  SPECIAL_SKILLS,
  TITLE,
  EDUCATION,
  HOURS_OF_EXPERIENCE,
  VIDEO,
  VIEW_THIS_EXPERT,
  ADD_PHOTO,
  VIEW_MY_PROFILE,
  PLACEHOLDER_CHIPS,
  FIRSTNAME,
  LASTNAME,
  EMAIL,
  SCOPE,
  GENDER_IDENTIFY,
  SENIORITY_LEVEL,
  COACH_MAX_CAPACITY,
} from 'litterals';
import { EditorField } from 'ui/components/form/Editor';
import UploadField from 'ui/components/form/UploadField';
import { Button } from '@mui/material';
import { useListTimeZones } from 'hooks/queries';
import { roleList } from 'utils/constUtils';
import useSeniorityLevels from 'hooks/usecase/useSeniorityLevels';

export const HOURS_OF_EXPERIENCE_VALUES = [
  { label: 'Beginner (0 to 100 practice hours)', value: 'Less than 100 hours of practice' },
  { label: 'ACC - 100 hours of practice', value: 'ACC - 100 hours of practice' },
  { label: 'PCC - 500 hours of practice', value: 'PCC - 500 hours of practice' },
  { label: 'MCC - 2 500 hours of practice', value: 'MCC - 2 500 hours of practice' },
];

const handleTransformListForOptionsSelectTargets = (list) =>
  list.map((item) => ({
    label: item.name,
    value: item.name,
  }));

const Header = ({
  picture,
  firstname,
  lastname,
  t,
  onSave,
  onNavigate,
  labelBView,
  labelBSave,
  cacheBuster,
  showBackToProfile,
  showSaveButton,
  onClickOpenAvatarUploader,
  onDeleteAvatar,
}) => {
  const img = picture ? picture : undefined;
  return (
    <Grid item xs={10}>
      <Box
        variant="flex"
        sx={{
          alignItems: 'center',
          margin: '12px',
          alignSelf: 'center',
          justifyContent: 'space-between',
        }}>
        <Box variant="flex" sx={{ alignItems: 'center', margin: '12px', alignSelf: 'center' }}>
          <AvatarEdit
            firstname={firstname}
            lastname={lastname}
            src={img ? `${img}?${cacheBuster}` : undefined}
            width="120px"
            height="120px"
            onClickUploader={onClickOpenAvatarUploader}
            onDeleteAvatar={onDeleteAvatar}
          />
          <Typography variant="h5" textTransform="capitalize" ml="24px" color="ternary.main">
            {firstname}&nbsp;{lastname}
          </Typography>
        </Box>
        <Box variant="flexColumn" sx={{ gap: '5px' }}>
          {showBackToProfile && (
            <Button variant="contained" onClick={onNavigate}>
              {labelBView}
            </Button>
          )}
          {showSaveButton && (
            <Button type="submit" variant="contained" onClick={onSave}>
              {labelBSave}
            </Button>
          )}
        </Box>
      </Box>
    </Grid>
  );
};

export default function EditCoach({
  coachId,
  state,
  actions,
  cacheBuster,
  user,
  showBackToProfile = true,
  showSaveButton = true,
  showActiveProfile = true,
}) {
  const { t } = useTranslation();
  const inputRef = useRef();
  const { data: timeZones, isLoading: isLoadingTimeZone } = useListTimeZones();
  const [seniorityLevelValues, setSeniorityLevelValues] = useState([]);

  const { seniorityLevelMentor } = useSeniorityLevels({});

  const onClickOpenAvatarUploader = () => {
    inputRef?.current?.click();
  };

  const timeZoneValues = (timeZones || []).map((timeZone) => {
    return { label: `${timeZone.label} (${timeZone.value})`, value: timeZone.value };
  });

  const HOURS_OF_EXPERIENCE_VALUES = useMemo(() => {
    return [
      { label: t(EXPERIENCE_ENUM_1), value: 'Less than 100 hours of practice' },
      { label: t(EXPERIENCE_ENUM_2), value: 'ACC - 100 hours of practice' },
      { label: t(EXPERIENCE_ENUM_3), value: 'PCC - 500 hours of practice' },
      { label: t(EXPERIENCE_ENUM_4), value: 'PCC - 500 hours of practice' },
    ];
  }, [t]);

  useEffect(() => {
    if (seniorityLevelMentor.seniorityLevels?.rows.length > 0) {
      setSeniorityLevelValues(() =>
        seniorityLevelMentor.seniorityLevels?.rows.map((row) => {
          return { label: row.name, value: row.id };
        })
      );
    }
  }, [seniorityLevelMentor.isLoading]);

  const GENDER_VALUES = useMemo(() => {
    return [
      { label: t('F'), value: 'F' },
      { label: t('M'), value: 'M' },
      { label: t('-'), value: '-' },
    ];
  }, [t]);

  const labelBView = useMemo(() => {
    if (user?.role === roleList.COACH) {
      return t(VIEW_MY_PROFILE);
    }
    return t(VIEW_THIS_EXPERT);
  }, [t, user?.role]);

  if (state.query.isLoading || isLoadingTimeZone) return <p>Loading...</p>;

  return (
    <Container component="form" onSubmit={actions.submitForm}>
      <Grid container sx={{ justifyContent: 'center' }} spacing={2}>
        <Header
          {...state.query.data}
          onClickOpenAvatarUploader={onClickOpenAvatarUploader}
          onDeleteAvatar={actions.deleteImage}
          t={t}
          onSave={actions.submitForm}
          onNavigate={actions.onNavigate}
          labelBView={labelBView}
          labelBSave={t(SAVE_CHANGES)}
          cacheBuster={cacheBuster}
          showBackToProfile={showBackToProfile}
          showSaveButton={showSaveButton}
        />
        <Box style={{ display: 'none' }}>
          <UploadField
            label="picture"
            name="picture"
            folder={'profile-pictures/'}
            fileId={coachId}
            control={state.form.control}
            ref={inputRef}
            setDisabledButton={() => {}}
            accept={'picture'}
            submitForm={actions.submitForm}
            onUploadSuccess={actions.updateImage}
          />
        </Box>
        {/* <Grid item xs={8}>
          <RadioButtons
            row
            control={state.form.control}
            name="coachType"
            options={[
              { label: t(RADIO_TYPE_COACH), value: 'COACHING' },
              { label: t(RADIO_TYPE_MENTOR), value: 'MENTORING' },
            ]}
            defaultValue={state.query.data?.coachType}
          />
        </Grid> */}
        {showActiveProfile && (
          <Grid item xs={10}>
            <Switch control={state.form.control} label={t(ACTIVATE_PROFILE)} name="active" color="primary" />
          </Grid>
        )}
        <Grid item xs={10} md={5}>
          <TextField
            name="firstname"
            label={t(FIRSTNAME)}
            placeholder="Ex: Lila"
            rules={{ required: 'This field is required' }}
            control={state.form.control}
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={10} md={5}>
          <TextField
            name="lastname"
            label={t(LASTNAME)}
            placeholder="Ex: Dupont"
            rules={{ required: 'This field is required' }}
            control={state.form.control}
            sx={{ width: '100%' }}
          />
        </Grid>
        {/**New row rami */}
        <Grid item xs={10} md={5}>
          <TextField
            name="maxCapacity"
            inputProps={{ type: 'number' }}
            label={t(COACH_MAX_CAPACITY) + '*'}
            placeholder={''}
            rules={{
              pattern: {
                value: /^[1-9]\d*$/, // Pattern for positive numbers (excluding zero)
                message: 'Please enter a positive number',
              },
              required: 'This field is required',
            }}
            control={state.form.control}
            sx={{ width: '100%' }}
            defaultValue={state.query.data.maxCapacity}
          />
        </Grid>
        <Grid item xs={10} md={5}>
          <Select
            name="seniorityLevelId"
            label={t(SENIORITY_LEVEL)}
            control={state.form.control}
            options={seniorityLevelValues}
          />
        </Grid>
        <Grid item xs={10} md={5}>
          <Select name="gender" label={t(GENDER_IDENTIFY)} control={state.form.control} options={GENDER_VALUES} />
        </Grid>
        {/**New row rami */}
        <Grid item xs={10} md={5}>
          <TextField
            name="title"
            label={t(TITLE)}
            placeholder="Ex: Certified coach, CNV Expert, ..."
            control={state.form.control}
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={10} md={5}>
          <Select
            name="userScope"
            label={t(SCOPE)}
            rules={{
              required: 'This field is required',
            }}
            control={state.form.control}
            options={state.query.scopeOption}
          />
        </Grid>
        <Grid item xs={10} md={5}>
          <TextField
            name="email"
            label={t(EMAIL)}
            placeholder="Ex: lila.dupont@gmail.com"
            rules={{
              required: 'This field is required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Please provide a valid email address',
              },
            }}
            control={state.form.control}
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={10} md={5}>
          <TextField
            name="linkedinUrl"
            label={t(LINKEDIN_PROFILE)}
            placeholder="https://www.linkedin.com/in/lbenamer/"
            control={state.form.control}
            sx={{ width: '100%' }}
            rules={{
              pattern: {
                value: /^https:\/\/(?:www\.|[a-z]{2}\.)?linkedin\.com\/in\/[\w\-\.%]+\/?(?:\?.*)?$/,
                message: 'Lien LinkedIn invalide',
              },
            }}
          />
        </Grid>
        <Grid item xs={10} md={5}>
          <TextField
            name="phoneNumber"
            label={t(PHONE_NUMBER)}
            placeholder="+33 6 21 18 19 17"
            control={state.form.control}
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={10} md={5}>
          <TextField
            name="city"
            label={t(CITY)}
            placeholder="Paris"
            control={state.form.control}
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={10} md={5}>
          <TextField
            name="calendarLink"
            label={'Link to Calendar'}
            placeholder="ex : https://calendly.com"
            control={state.form.control}
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={10}>
          <Select
            name="hoursOfExperience"
            label={t(HOURS_OF_EXPERIENCE, { sessionLabel: user.company.sessionLabel })}
            control={state.form.control}
            options={HOURS_OF_EXPERIENCE_VALUES}
            defaultValue={state.query.data.hoursOfExperience}
          />
        </Grid>
        <Grid item xs={10}>
          <Select
            name="timeZone"
            label={t('application_form_field_time_zone')}
            control={state.form.control}
            options={timeZoneValues}
            defaultValue={state.query.data.timeZone}
          />
        </Grid>
        <Grid item xs={10}>
          <MultiSelect
            options={LANGUAGES_OPTIONS}
            name="languagesSpoken"
            label={t(LANGUAGES)}
            placeholder={t(PLACEHOLDER_CHIPS)}
            required={false}
            control={state.form.control}
            sx={{ width: '100%', height: '100%' }}
          />
        </Grid>
        <Grid item xs={10}>
          {state.query.company &&
          state.query.company.target_template_items &&
          Array.isArray(state.query.company.target_template_items) ? (
            <MultiSelectChipCoach
              name="coachingSkills"
              label={t(SPECIAL_SKILLS)}
              placeholder={t(PLACEHOLDER_CHIPS)}
              required={false}
              options={handleTransformListForOptionsSelectTargets(state.query.company.target_template_items)}
              control={state.form.control}
              sx={{ width: '100%', height: '100%' }}
            />
          ) : (
            <Skeleton sx={{ width: '100%' }} />
          )}
        </Grid>
        <Grid item xs={10}>
          <EditorField control={state.form.control} name="descriptionExperience" title={t('biography')} />
        </Grid>
        <Grid item xs={10}>
          <EditorField control={state.form.control} name="expectations" title={t('expectations')} />
        </Grid>
        <Grid item xs={10}>
          <TextField
            name="videoLink"
            label={t(VIDEO)}
            placeholder="Put here the video link of your presentation"
            control={state.form.control}
            sx={{ width: '100%' }}
          />
        </Grid>
        {showSaveButton && (
          <Grid item xs={10}>
            <Fab variant="extended" color="primary" type="submit">
              {t(SAVE_CHANGES)}
            </Fab>
          </Grid>
        )}
      </Grid>
    </Container>
  );
}
