import React, { useState, useEffect } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import { useMutation } from '@tanstack/react-query';
import api from 'api';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Tooltip } from '@mui/material';
import { truncateString } from 'common/utils';

import { useController } from 'react-hook-form';

const index = ({ ...props }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [themesList, setThemesList] = useState(null);
  const [isFetched, setIsFetched] = useState(false);
  const [values, setValues] = useState([]);
  const {
    field: { onChange, value, ref },
    fieldState: { error },
  } = useController({
    name: props.name,
    control: props.control,
    rules: props.rules,
    ref: props.ref,
  });

  const mutateGetScopesList = useMutation(api.Theme.list, {
    onSuccess: (data) => {
      setThemesList(data);
      setIsLoading(false);
    },
  });

  useEffect(() => {
    if (!isFetched) {
      mutateGetScopesList.mutate();
      setIsFetched(true);
      console.log('mount scope filter');
    }
  }, [isFetched]);

  useEffect(() => {
    if (value.length === 0) {
      setValues([]);
    }
  }, [value]);

  return (
    <Box variant="standard">
      {isLoading ? (
        <Skeleton sx={{ width: '211px' }} />
      ) : (
        <Autocomplete
          multiple
          value={values}
          disableCloseOnSelect
          sx={{ width: '180px' }}
          options={themesList.sort((a, b) => -b.groupLabel.localeCompare(a.groupLabel))}
          getOptionLabel={(option) => option.name || ''}
          onChange={(event, newValue) => {
            console.log('NEW VALUE : ', newValue);
            const idsList = newValue.map((item) => item.id);
            if (idsList.length == 0) {
              onChange('');
            } else {
              onChange(idsList);
              setValues(newValue);
            }
          }}
          groupBy={(option) => option.groupLabel}
          renderTags={(value, getTagProps) => {
            const numTags = value.length;
            const limitTags = 1;
            console.log('OPTION OPTION ', value.slice(0, limitTags));
            return (
              <>
                {value.slice(0, limitTags).map((option, index) => (
                  <Tooltip key={index} title={option.name}>
                    <Chip
                      sx={{ height: '20px', fontSize: '10px' }}
                      size="small"
                      {...getTagProps({ index })}
                      label={truncateString(`${option.name}`, 11)}
                    />
                  </Tooltip>
                ))}
                {numTags > limitTags && <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>...</Typography>}
              </>
            );
          }}
          renderInput={(params) => <TextField sx={{ height: '50px' }} {...params} variant="standard" label="theme" />}
        />
      )}
    </Box>
  );
};

export default index;
