import React, { useState, useEffect, Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import Box from 'ui/components/system/Box';
import Paper from '@mui/material/Paper';
import ProgramsTable from './ProgramsTable';
import { useListProgramsQuery, useStatsProgramsQuery } from 'hooks/queries';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { useTranslation } from 'react-i18next';
import { NEW_PROGRAM, ONGOING_COACHING, PROGRAMS } from 'litterals';
import useViewSubscription from 'hooks/usecase/useViewSubscription';
import { roleList } from 'utils/constUtils';
import Button from 'ui/components/Button/Button';
import TabNavigation from 'ui/pages/program/components/TabNavigationSettings';
import api from 'api';
import { useMutation } from '@tanstack/react-query';
import { useAlert } from 'common/alertContext';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const StatItem = ({ label, value }) => (
  <Box variant="flex">
    <Typography color="text.secondary" variant="subtitle2" fontStyle="italic" mr="6px">
      {label}:
    </Typography>
    <Typography color="ternary.main" fontWeight="bold">
      {value}
    </Typography>
  </Box>
);

const CoacheeTableHeader = ({ coachingCount }) => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'space-between',
        pl: '12px',
        pr: '12px',
        marginBottom: '12px',
      }}>
      <Typography
        style={{
          color: 'rgba(0, 0, 0, 0.87)',
          fontFamily: 'karla',
          fontSize: '24px',
          fontWeight: 700,
          lineHeight: '29.64px',
          letterSpacing: '0.25px',
          fontFeatureSettings: " 'clig' off, 'liga' off",
        }}>
        {t(PROGRAMS)}
      </Typography>

      <Stack direction="row" spacing={2} divider={<Divider orientation="vertical" flexItem />} sx={{ pr: '24px' }}>
        {/* <StatItem label={t(ONGOING_COACHING)} value={coachingCount} /> */}
      </Stack>
      <Button
        onClick={() => navigate('/subscription/create')}
        sx={{ marginTop: '10px', minWidth: '170px' }}
        startIcon={<RocketLaunchIcon />}>
        {t(NEW_PROGRAM)}
      </Button>
    </Box>
  );
};

const extraColumns = ['Coach', 'Scope-Coach', 'Scope-Coachee', 'Coachee', 'Status'];
const initParamsActivePrograms = { page: 1, size: 10, status: ['done', 'draft', 'pending', 'in progress'] };
const initParamsEvergreenPrograms = { page: 1, size: 10, type: 'EVERGREEN' };
const initParamsDeactivatedPrograms = {
  page: 1,
  size: 10,
  status: ['deactivated', 'archived'],
  isStatusBeforeDeactivation: true,
};
const disabledColumnsForTableActivePrograms = ['scopeMentee', 'scopeMentor', 'deactivatedAt', 'daysToDeletion'];
const disabledColumnsForTableDeactivatedPrograms = ['scopeMentee', 'scopeMentor', 'status', 'progress'];

const HomeRH = ({ user }) => {
  const { t } = useTranslation();
  const { setStateAlert } = useAlert();
  const [queryParams, setQueryParams] = useState(initParamsActivePrograms);
  const [selectedTab, setSelectedTab] = useState('tab-1');

  const { state, actions, query } = useViewSubscription({ initialFilterState: queryParams });

  useEffect(() => {
    if (queryParams) {
      actions.updateFilterSubscription(queryParams);
    }
  }, [queryParams]);

  const mutateProgramStatus = useMutation(api.Programs.update, {
    onSuccess: (data) => {
      const type = data.status === 'deactivated' ? 'deactivation' : 'reactivation';
      setStateAlert({
        message: t(`program_${type}_success`),
        open: true,
      });

      actions.reloadListPrograms();
    },
  });

  const onDeactivateProgram = ({ programId, sendEmailToCoach, sendEmailToCoachee, isArchived }) => {
    const status = isArchived ? 'archived' : 'deactivated';
    mutateProgramStatus.mutate({ id: programId, body: { status, sendEmailToCoach, sendEmailToCoachee } });
  };

  const onReactivateProgram = ({ programId, sendEmailToCoach, sendEmailToCoachee }) => {
    const status = 'reactivated';
    mutateProgramStatus.mutate({ id: programId, body: { status, sendEmailToCoach, sendEmailToCoachee } });
  };

  const disableColumnsRh = (selectedTab) => {
    switch (selectedTab) {
      case 'tab-1':
        return disabledColumnsForTableActivePrograms;
      case 'tab-2':
        return [];
      case 'tab-3':
        return disabledColumnsForTableDeactivatedPrograms;
    }
  };

  const renderComponent = (selectedTab) => {
    return (
      <>
        {selectedTab === 'tab-2' ? (
          <Paper
            sx={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#0288d12b', // Blue background color
              padding: '11px',
              boxShadow: 'none', // Remove box-shadow
              width: 'fit-content', // Adjust width for content
              marginTop: '10px',
              marginBottom: '10px',
            }}>
            <InfoOutlinedIcon sx={{ color: '#0288D1', marginRight: '8px' }} />
            <Box>
              <Typography
                variant="subtitle1"
                sx={{
                  color: 'var(--Light-Info-Shades-160p, #0288D1)',
                  fontFamily: 'Karla',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '150%', // 24px
                  letterSpacing: '0.15px',
                  fontFeatureSettings: "'clig' off, 'liga' off",
                }}>
                {t('info_history_evergreen_requests')}
              </Typography>
            </Box>
          </Paper>
        ) : (
          <></>
        )}

        <ProgramsTable
          isActiveEvergreenColumns={selectedTab === 'tab-2'}
          isDisablesRowClick={selectedTab === 'tab-2'}
          user={user}
          setQueryParams={(params) => {
            const initParams = getParamsWithTab(selectedTab);
            setQueryParams({ ...initParams, ...params });
          }}
          disableColumnsRh={disableColumnsRh(selectedTab)}
          queryParams={queryParams}
          extraColumns={extraColumns}
          programs={query.data ? query.data.rows : []}
          isLoading={query.isLoading}
          updateFilter={actions.updateFilterSubscription}
          filterState={state.filterSubscriptionState}
          sx={{ height: '75vh' }}
          role={roleList.RH}
          scopeOption={state.scopeOption}
          totalPage={query.data ? query.data.totalPages : 0}
          totalItems={query.data && query.data.totalItems}
          onDeactivateProgram={onDeactivateProgram}
          onReactivateProgram={onReactivateProgram}
        />
      </>
    );
  };

  const tabsConfiguration = [
    { id: 'tab-1', text: t('active_programs'), selected: true, disabled: false },
    {
      id: 'tab-2',
      text: t('mentee_request', { sessionLabel: user?.company?.sessionLabel }),
      selected: false,
      disabled: false,
    },
    { id: 'tab-3', text: t('disabled_programs'), selected: false, disabled: false },
  ];

  const getParamsWithTab = (tab) => {
    if (tab === 'tab-1') return initParamsActivePrograms;
    if (tab === 'tab-2') return initParamsEvergreenPrograms;
    if (tab === 'tab-3') return initParamsDeactivatedPrograms;
  };

  return (
    <Paper elevation={3} sx={{ m: '24px', p: '12px', pb: '24px', position: 'relative' }}>
      <CoacheeTableHeader coachingCount={query.data ? query.data.totalItems : 0} />

      <Stack sx={{ p: '0 10px 0 10px' }}>
        <TabNavigation
          sx={{ padding: '10px' }}
          options={tabsConfiguration}
          onChange={(tab) => {
            setQueryParams(getParamsWithTab(tab));
            setSelectedTab(tab);
          }}
        />

        <Suspense fallback={<div>Loading...</div>}>{renderComponent(selectedTab)}</Suspense>
      </Stack>
    </Paper>
  );
};

export default HomeRH;
