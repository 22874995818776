/**
 * Jodit Editor configuration options.
 *
 * @type {Object}
 * @property {boolean} readonly - Determines if the editor is in read-only mode.
 * @property {boolean} insertImageAsBase64URI - Indicates whether to insert images as Base64 URIs.
 * @property {string[]} imagesExtensions - Allowed image file extensions.
 * @property {function} isSuccess - A function to determine if an action is successful.
 * @property {string} toolbarButtonSize - Size of toolbar buttons ('small', 'middle', 'large').
 * @property {string} theme - Editor theme ('default' or 'dark').
 * @property {boolean} saveModeInCookie - Whether to save the mode in a cookie.
 * @property {boolean} spellcheck - Enables or disables spellcheck.
 * @property {boolean} triggerChangeEvent - Triggers a change event when the content changes.
 * @property {string|number} width - Width of the editor.
 * @property {string|number} height - Height of the editor.
 * @property {number} minHeight - Minimum height of the editor.
 * @property {boolean} toolbar - Shows or hides the toolbar.
 * @property {string} enter - Default behavior when pressing the Enter key ('P').
 * @property {boolean} useSplitMode - Enables or disables split mode.
 * @property {Object} colors - Color configuration object.
 * @property {string} colorPickerDefaultTab - Default tab for the color picker ('background').
 * @property {number} imageDefaultWidth - Default width for inserted images.
 * @property {string[]} removeButtons - Buttons to remove from the toolbar.
 * @property {string[]} disablePlugins - Plugins to disable.
 * @property {string[]} extraButtons - Extra buttons to add to the toolbar.
 * @property {number} sizeLG - Large size breakpoint.
 * @property {number} sizeMD - Medium size breakpoint.
 * @property {number} sizeSM - Small size breakpoint.
 * @property {string[]} buttons - Buttons to include in the toolbar.
 * @property {Object} events - Custom events.
 * @property {boolean} textIcons - Use text-based icons instead of images.
 */

export const options = {
  readonly: false,
  insertImageAsBase64URI: false,
  imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
  isSuccess: function (e) {
    return e.success;
  },
  toolbarButtonSize: 'middle', // small middle large
  theme: 'default', // default or dark
  saveModeInCookie: false,
  spellcheck: false,
  triggerChangeEvent: false,
  width: 'auto',
  height: 300,
  minHeight: 100,
  toolbar: true,
  enter: 'P',
  useSplitMode: false,
  colors: {
    greyscale: [
      '#000000',
      '#434343',
      '#666666',
      '#999999',
      '#B7B7B7',
      '#CCCCCC',
      '#D9D9D9',
      '#EFEFEF',
      '#F3F3F3',
      '#FFFFFF',
    ],
    palette: [
      '#980000',
      '#FF0000',
      '#FF9900',
      '#FFFF00',
      '#00F0F0',
      '#00FFFF',
      '#4A86E8',
      '#0000FF',
      '#9900FF',
      '#FF00FF',
    ],
    full: [
      '#E6B8AF',
      '#F4CCCC',
      '#FCE5CD',
      '#FFF2CC',
      '#D9EAD3',
      '#D0E0E3',
      '#C9DAF8',
      '#CFE2F3',
      '#D9D2E9',
      '#EAD1DC',
      '#DD7E6B',
      '#EA9999',
      '#F9CB9C',
      '#FFE599',
      '#B6D7A8',
      '#A2C4C9',
      '#A4C2F4',
      '#9FC5E8',
      '#B4A7D6',
      '#D5A6BD',
      '#CC4125',
      '#E06666',
      '#F6B26B',
      '#FFD966',
      '#93C47D',
      '#76A5AF',
      '#6D9EEB',
      '#6FA8DC',
      '#8E7CC3',
      '#C27BA0',
      '#A61C00',
      '#CC0000',
      '#E69138',
      '#F1C232',
      '#6AA84F',
      '#45818E',
      '#3C78D8',
      '#3D85C6',
      '#674EA7',
      '#A64D79',
      '#85200C',
      '#990000',
      '#B45F06',
      '#BF9000',
      '#38761D',
      '#134F5C',
      '#1155CC',
      '#0B5394',
      '#351C75',
      '#733554',
      '#5B0F00',
      '#660000',
      '#783F04',
      '#7F6000',
      '#274E13',
      '#0C343D',
      '#1C4587',
      '#073763',
      '#20124D',
      '#4C1130',
    ],
  },
  colorPickerDefaultTab: 'background',
  imageDefaultWidth: 300,
  removeButtons: ['video', 'symbol', 'table', 'print', 'copyformat'], // Remove buttons from toolbar
  disablePlugins: [],
  extraButtons: [],
  sizeLG: 900,
  sizeMD: 700,
  sizeSM: 400,
  buttons: [
    'source',
    '|',
    'bold',
    'strikethrough',
    'underline',
    'italic',
    '|',
    'ul',
    'ol',
    '|',
    'outdent',
    'indent',
    '|',
    'font',
    'fontsize',
    'brush',
    'paragraph',
    '|',
    'image',
    'video',
    'link',
    '|',
    'align',
    'undo',
    'redo',
    '|',
    'hr',
    '|',
    'symbol',
    'fullsize',
    '|',
  ],
  events: {},
  textIcons: false,
};
