import React, { useState, useRef } from 'react';
import Stack from '@mui/material/Stack';
import styles from './Users.styles';
import { useTranslation } from 'react-i18next';
import TabNavigation from '../program/components/TabNavigationUser';
import { useNavigate } from 'react-router-dom';
import InvitationLinkModal from './components/InvitationLinkModal';
import SelfRegistrationLinkModal from './components/SelfRegistrationLinkModal/SelfRegistrationLinkModal';
import { useMeQuery, useListGuestsWithParams } from 'hooks/queries';
import { Typography } from '@mui/material';
import Experts from './Experts';
import Coachees from './Coachees';
import Admins from './Admins';
import { Roles } from 'utils/roles';
import AddIcon from '@mui/icons-material/Add';
import Button from 'ui/components/Button/Button';
import { t } from 'i18next';

const getOptionsTab = (expertLabel, clientLabel, selfRegistrationActivated, role, countRhValidation) => {
  return [
    { id: 'tab-1', text: expertLabel.toUpperCase(), selected: true },
    { id: 'tab-2', text: clientLabel.toUpperCase(), selected: false },
    ...(selfRegistrationActivated
      ? [{ id: 'tab-3', text: t('application_request_tab'), selected: false, badge: countRhValidation }]
      : []),
    ...(role === Roles.RH_MANAGER ? [{ id: 'tab-4', text: 'Admin'.toUpperCase(), selected: false }] : []),
  ];
};

const Users = () => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState('tab-1');
  const navigate = useNavigate();
  const [isOpenInvitationLink, setIsOpenInvitationLink] = useState(false);
  const [isOpenSelfRegistration, setIsOpenSelfRegistration] = useState(false);
  const { data: user } = useMeQuery();
  const adminsRef = useRef();

  const renderContent = () => {
    switch (selectedTab) {
      case 'tab-1':
        return <Experts role={user.role} user={user} company={user?.company} />;
      case 'tab-2':
        return <Coachees role={user.role} />;
      case 'tab-3':
        return (
          <Coachees
            role={user.role}
            typeCoachees="RH_Validation"
            noDataDescription={t('no_candidatures_to_be_validated')}
            visibleColumns={['user', 'scopeName', 'createdAt', 'actionsRhValidationSelfRegistration']}
          />
        );
      case 'tab-4':
        return <Admins ref={adminsRef} companyId={user ? user.companyId : ''} role={user.role} />;

      default:
        break;
    }
  };

  const getInvitationButtonText = () => {
    switch (selectedTab) {
      case 'tab-1':
        return `${t('add')} ${t('user')}`;
      case 'tab-2':
        return `${t('add')} ${t('user')}`;
      case 'tab-3':
        return `${t('generate_application')}`;
      case 'tab-4':
        return `${t('add')} ADMIN`;

      default:
        break;
    }
  };

  const { data } = useListGuestsWithParams({ type: 'RH_Validation', page: 1, size: 10 });

  const countRhValidation = data?.totalItems || 0;

  return (
    <Stack sx={{ padding: '30px 60px' }}>
      {/** Header */}
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{ backgroundColor: 'white', padding: '10px 38px' }}>
        <div style={{ width: '100%' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div>
              <Typography sx={styles.fontHeader}>{t('users')}</Typography>
            </div>
            <div>
              <Button
                onClick={() => {
                  if (selectedTab === 'tab-3') {
                    setIsOpenSelfRegistration(true);
                  } else if (selectedTab !== 'tab-4') {
                    setIsOpenInvitationLink(true);
                  } else {
                    adminsRef.current.handleAddAdmin();
                  }
                }}
                sx={{ marginTop: '10px' }}
                startIcon={<AddIcon />}>
                {getInvitationButtonText()}
              </Button>
            </div>
          </div>
          <div>
            <TabNavigation
              options={getOptionsTab(
                user?.company?.expertLabel,
                user?.company?.clientLabel,
                user?.company?.selfRegistrationActivated,
                user.role,
                countRhValidation
              )}
              onChange={(tab) => {
                setSelectedTab(tab);
              }}
              onReturn={() => navigate(-1)}
            />
          </div>
        </div>
      </Stack>
      {renderContent()}
      {/** Modal to delete */}
      <InvitationLinkModal
        applicationLink={user ? user.company.applicationLink : {}}
        open={isOpenInvitationLink}
        onClose={() => setIsOpenInvitationLink(false)}
        onValidate={() => {
          setIsOpenInvitationLink(false);
        }}
      />
      <SelfRegistrationLinkModal
        applicationLink={user?.company?.applicationLink || ''}
        companyId={user?.companyId || ''}
        selfRegistrationLink={user?.company?.selfRegistrationLink || ''}
        open={isOpenSelfRegistration}
        onClose={() => setIsOpenSelfRegistration(false)}
      />
    </Stack>
  );
};

export default Users;
