import { Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'ui/components/system/Box';
import { appVersion } from 'utils/constUtils';

const styleBox = {
  maxWidth: 600,
  width: '100%',
  minHeight: 400,
  bgcolor: 'white',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'space-between',
  padding: '64px',
};

const Layout = ({ children, logo }) => {
  const { i18n, t } = useTranslation();

  useEffect(() => {
    const language = i18n.language.split('-')[0];
    if (['fr', 'en', 'it', 'es', 'pt'].includes(language)) {
      i18n.changeLanguage(language);
    } else {
      i18n.changeLanguage('en');
    }
  }, []);

  return (
    <Box
      style={{
        backgroundColor: '#FFF6F1',
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        paddingTop: '20px',
      }}>
      <Grid
        container
        style={{
          width: '100%',
          zIndex: 10,
          backgroundImage: `url(${__dirname}uploads/images/login-bg.png)`,
          backgroundSize: 'cover',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: 'auto',
          padding: '32px',
          paddingRight: '100px',
          minHeight: '75vh',
        }}
        columnSpacing={4}>
        <Grid item sx={{ display: { xs: 'none', md: 'flex' }, maxWidth: '600px' }} md={6}>
          <img
            src={`${__dirname}uploads/images/login-img.png`}
            width={'80%'}
            style={{ maxWidth: '550px', margin: 'auto', marginRight: '2%' }}
          />
        </Grid>
        <Grid item xs={12} md={6} display={'flex'} padding={'0px'} sx={{ maxWidth: '600px' }}>
          <Box sx={styleBox}>
            <Box
              alt="logo-pathline"
              component="img"
              sx={{
                marginLeft: '-8px',
                alignSelf: 'center',
                height: {
                  xs: '70px',
                  sm: '70px',
                  md: '70px',
                  lg: '100px',
                  xl: '100px',
                },
                width: '223px',
                objectFit: 'contain',
              }}
              src={logo}
            />

            {children}
          </Box>
        </Grid>
      </Grid>
      <img
        src={`${__dirname}uploads/images/login-motif.png`}
        width={'50px'}
        style={{ width: '23px', marginLeft: '25px', marginBottom: '20px' }}
      />
      <Typography variant="p" sx={{ mt: '12px', ml: '12px', color: 'secondary.main' }}>
        {t('VERSION') + ' ' + appVersion.versionNumber}
      </Typography>
    </Box>
  );
};

export default Layout;
