import React, { useEffect, useState } from 'react';
import Box from 'ui/components/system/Box';
import ImageCard from 'ui/components/shared/ImageCard';
import IconButton from 'ui/components/system/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ThemeViewModal from 'ui/components/shared/theme/ThemeViewModal';
import useViewModal from 'hooks/shared/useViewModal';
import { Typography } from '@mui/material';
import { SESSIONS } from 'litterals';
import { groupByAttribute } from 'common/utils';
import TabNavigation from '../../program/components/TabNavigation';
import Stack from '@mui/material/Stack';

const ChooseTheme = ({ themes, onClickSelect, t }) => {
  const { state, actions } = useViewModal();
  const [groups, setGroups] = useState({});
  const [selectedGroup, setSelectedGroup] = useState(null);

  useEffect(() => {
    if (themes) {
      const groupedThemes = groupByAttribute(themes, 'groupLabel');
      setGroups(groupedThemes);

      if (themes && themes.length && !selectedGroup) {
        const firstGroupKey = Object.keys(groupedThemes)[0];
        setSelectedGroup(firstGroupKey);
      }
    }
  }, [themes]);

  // Créer des options pour TabNavigation
  const tabOptions = Object.keys(groups).map((groupLabel) => ({
    id: groupLabel,
    // text: `${nbSession} ${t(SESSIONS)}`,
    text: groupLabel,
    selected: groupLabel === selectedGroup,
  }));

  const handleTabChange = (selectedTabId) => {
    setSelectedGroup(selectedTabId);
  };

  return (
    <>
      <Box
        sx={{
          ml: '20px',
          mr: '20px',
        }}>
        {Object.keys(groups).length > 1 && (
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{ ml: '10%' }}>
            <TabNavigation options={tabOptions} onChange={handleTabChange} />
          </Stack>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'left',
            overflowY: 'auto',
            ml: '10%',
          }}>
          {groups &&
            groups[selectedGroup]?.map((theme) => (
              <ImageCard
                title={theme.name}
                sxTitle={{ minHeight: '41px' }}
                sxCard={{ height: '100%', mr: '12px', mb: '12px', mt: '12px', position: 'relative' }}
                key={`theme-card-${theme.id}`}
                src={theme.marketingPicture}
                onClick={() => onClickSelect(theme)}>
                <Typography
                  component={'span'}
                  sx={{
                    position: 'absolute',
                    top: '10px',
                    left: '10px',
                    height: '30px',
                    padding: '10px',
                    paddingBottom: '0px',
                    backgroundColor: 'primary.main',
                    color: 'white',
                    borderRadius: '20px',
                  }}>
                  {theme?.nbSessions || '0'} {t(SESSIONS)}*
                </Typography>
                <IconButton onClick={() => actions.openModal(theme.id)} sx={{ padding: '0px' }}>
                  <VisibilityIcon />
                </IconButton>
              </ImageCard>
            ))}
        </Box>
        <Typography sx={{ ml: '10%', marginTop: '20px' }}>*{t('minimum_number_of_sessions')}</Typography>
      </Box>
      <ThemeViewModal themeId={state.viewId} open={state.open} onClose={actions.closeModal} />
    </>
  );
};

export default ChooseTheme;
