import React, { Suspense, useState } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import GoBackButton from 'ui/components/shared/GoBackButton';
import { useTranslation } from 'react-i18next';
import TabNavigation from 'ui/pages/program/components/TabNavigationSettings';
import RegistrationForm from './components/registrationForm/RegistrationForm';
import RemindersSettings from './components/reminders/RemindersSettings';
import theme from '../../../theme';

const StandardTargetTemplateItems = React.lazy(() =>
  import('../settings/components/standardTargetTemplateItems/TargetTemplateItems')
);

const SettingsPage = () => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState('tab-1');

  const renderComponent = () => {
    switch (selectedTab) {
      case 'tab-1':
        return <StandardTargetTemplateItems />;
      case 'tab-2':
        return <RegistrationForm />;
      case 'tab-3':
        return <RemindersSettings />;

      default:
        return null;
    }
  };

  const tabsConfiguration = [
    { id: 'tab-1', text: t('objectives_expertises_title'), selected: true, disabled: false },
    { id: 'tab-2', text: t('registration_form'), selected: false, disabled: false },
    { id: 'tab-3', text: t('reminders'), selected: false, disabled: false },
  ];

  return (
    <Box>
      <GoBackButton sx={{ mt: '12px', ml: '12px' }} />
      <Paper
        sx={{
          width: '90%',
          alignSelf: 'center',
          mt: '24px',
          overflowY: 'auto',
          p: '24px',
          marginX: '24px',
        }}>
        <Typography sx={theme.fontHeader}>{t('customization')}</Typography>
        <Typography variant="h8" sx={{ display: 'block' }}>
          {t('settings_subtitle')} {t('settings_subtitle2')}
        </Typography>
        {tabsConfiguration.length > 1 && (
          <TabNavigation
            options={tabsConfiguration}
            onChange={(tab) => {
              setSelectedTab(tab);
            }}
          />
        )}
        <Stack>
          <Suspense fallback={<div>Loading...</div>}>{renderComponent()}</Suspense>
        </Stack>
      </Paper>
    </Box>
  );
};

export default SettingsPage;
