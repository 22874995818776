const getUrlApplicationLink = (link = '') => {
  return `${window.location.origin}/program-application?applicationLink=${link}`;
};

const getUrlEvergreenLink = (link = '') => {
  return `${window.location.origin}/evergreen-link?evergreenLink=${link}`;
};

const getUrlSelfRegistrationLink = (selfRegistration = '', applicationLink = '', themeId = '') => {
  return `${window.location.origin}/self-registration-link/${selfRegistration}/${applicationLink}/${themeId}`;
};

const getUrlSelfRegistrationApplicationLink = (selfRegistration = '', applicationLink = '', themeId = '') => {
  return `/program-application/${selfRegistration}/${themeId}?applicationLink=${applicationLink}`;
};

export {
  getUrlApplicationLink,
  getUrlEvergreenLink,
  getUrlSelfRegistrationLink,
  getUrlSelfRegistrationApplicationLink,
};
