import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translations from './translations.json';
import i18next from 'i18next';

const options = {
  order: ['navigator'],
};

// export function getInitialLocale() {
//   const [browserSetting] = window.navigator.language.split("-");
//   console.log(browserSetting)
//   return "fr";

// }
// getInitialLocale()
i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    detection: options,
    fallbackLng: 'fr',
    resources: translations,
    // lng: 'fr', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
