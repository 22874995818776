const initialState = {
  themes: [],
  completThemes: [],
  allThemes: [],
  currentPage: 1,
  size: 10,
  totalPages: 0,
};

export const createThemeSlice = (set) => ({
  ...initialState,
  setThemes: (newArray) => set({ themes: newArray }),
  setCompleteThemes: (newArray, size) =>
    set({ completThemes: newArray, totalPages: Math.ceil(newArray.length / size) }),
  setCurrentPage: (newPage) => set({ currentPage: newPage }),
  setSize: (newSize) => set({ size: newSize }),
  setTotalPages: (newTotalPages) => set({ totalPages: newTotalPages }),
  setAllThemes: (newAllThemes) => set({ allThemes: newAllThemes }),
});
