/* -------------------------------------------------------------------------- */
/*                                Helper                                      */
/* -------------------------------------------------------------------------- */

/**
 * Generates a set of variables for replacing placeholders in a message related to coach information.
 *
 * @param {Object} coach - The coach object containing information.
 * @param {Object} rh - The human resources (HR) object containing information.
 * @param {string} theme - The theme information.
 * @returns {Object} - An object containing variables for coach-related information.
 */
const variablesReplacementCoachInformations = (coach, rh, theme) => {
  const variables = {
    coach_firstname: coach.firstname,
    coach_lastname: coach.lastname,
    theme: theme,
    admin_firstname: rh.firstname,
  };
  return variables;
};

/**
 * Generates a set of variables for replacing placeholders in a message related to coachee information.
 *
 * @param {Object} coachee - The coachee object containing information.
 * @param {Object} rh - The human resources (HR) object containing information.
 * @param {string} theme - The theme information.
 * @returns {Object} - An object containing variables for coachee-related information.
 */
const variablesReplacementCoacheeInformations = (coachee, rh, theme) => {
  const variables = {
    coachee_firstname: coachee.firstname,
    coachee_lastname: coachee.lastname,
    theme: theme,
    admin_firstname: rh.firstname,
  };
  return variables;
};

const variablesReplacementProgramDeactivation = (sessionLabel) => {
  return {
    sessionLabel,
  };
};

/**
 * Replaces variables in the original text with corresponding values.
 *
 * @param {string} originalText - The original text containing placeholders.
 * @param {Object} variables - An object containing key-value pairs for variable replacement.
 * @returns {string} - The text after replacing variables.
 */
const variablesReplacementContent = (originalText, variables) => {
  const replacedText = Object.keys(variables).reduce((text, variable) => {
    const regex = new RegExp(`\\$\\$${variable}`, 'g');
    return text.replace(regex, variables[variable]);
  }, originalText);

  return replacedText;
};

export {
  variablesReplacementContent,
  variablesReplacementCoachInformations,
  variablesReplacementCoacheeInformations,
  variablesReplacementProgramDeactivation,
};
