import React from 'react';
import { CircularProgress } from '@mui/material';
import Button from '../Button/Button';

const LoadingButton = ({ isloading, onClick, title, style, children, type = 'button' }) => {
  return (
    <Button
      type={type}
      disabled={isloading}
      startIcon={isloading ? <CircularProgress size={20} /> : null}
      sx={style}
      onClick={onClick}>
      {title || children}
    </Button>
  );
};

export default LoadingButton;
