import React, { useState } from 'react';
import { Box } from '@mui/material';
import Button from 'ui/components/Button/Button';
import EditIcon from '@mui/icons-material/Edit';
import useEditCoach from 'hooks/usecase/useEditCoach';
import EditCoach from '../../../pages/coach/EditCoach';
import GoBackButton from 'ui/components/shared/GoBackButton';
import ViewModal from '../ViewModal';
import Switch from 'ui/components/form/Switch';
import { useMeQuery } from 'hooks/queries';
import CoachView from './CoachView';
import { t } from 'i18next';

const CoachViewModal = ({
  coach = {},
  open,
  company = {},
  onClose,
  isLoading,
  onClickPreviousCoach,
  onClickNextCoach,
  userRole,
  availabilitiesDisabled = false,
  directionalArrowsActivated = false,
  editable = false,
  typeView = 'coach',
  refetch,
}) => {
  const [contentType, setContentType] = useState('coach');
  const styleBox = editable ? { paddingBottom: 0 } : {};

  if (isLoading) return <></>;

  return (
    <ViewModal
      open={open}
      onClose={onClose}
      customStyleBox={{
        width: '90%',
        borderRadius: '16px',
        position: 'relative',
        height: 'none',
        maxHeight: '80%',
        ...styleBox,
      }}>
      {contentType === 'coach' && (
        <CoachDetail
          coach={coach}
          isLoading={isLoading}
          company={company}
          editable={editable}
          directionalArrowsActivated={directionalArrowsActivated}
          availabilitiesDisabled={availabilitiesDisabled}
          typeView={typeView}
          userRole={userRole}
          refetch={refetch}
          onClickPreviousCoach={onClickPreviousCoach}
          onClickNextCoach={onClickNextCoach}
          onClickEditProfile={() => setContentType('edit')}
        />
      )}

      {contentType === 'edit' && (
        <CoachEditContent
          coach={coach}
          company={company}
          onClickShowProfile={() => setContentType('coach')}
          refetch={refetch}
        />
      )}
    </ViewModal>
  );
};

const CoachDetail = ({
  coach,
  isLoading,
  company,
  directionalArrowsActivated,
  availabilitiesDisabled,
  onClickPreviousCoach,
  onClickNextCoach,
  onClickEditProfile,
  editable,
  userRole,
  typeView,
  refetch,
}) => {
  const { actions, state } = useEditCoach(coach.id, company.id, !editable, refetch);

  return (
    <>
      <CoachView
        userRole={userRole}
        availabilitiesEditable
        coach={coach}
        isLoading={isLoading}
        company={company}
        typeView={typeView}
        directionalArrowsActivated={directionalArrowsActivated}
        availabilitiesDisabled={availabilitiesDisabled}
        onClickPreviousCoach={onClickPreviousCoach}
        onClickNextCoach={onClickNextCoach}
      />

      {editable && !state?.query?.isLoading && (
        <CoachViewModalFooter
          actions={actions}
          state={state}
          onClickEditProfile={onClickEditProfile}
          showProfileEditButton={true}
        />
      )}
    </>
  );
};

const CoachEditContent = ({ coach, company, onClickShowProfile, refetch }) => {
  const { data: user } = useMeQuery();
  const { actions, cacheBuster, state } = useEditCoach(coach.id, company.id, false, refetch);

  if (state.query.isLoading) return <></>;

  return (
    <>
      <Box>
        <GoBackButton onClick={onClickShowProfile} />
        <EditCoach
          user={user}
          actions={actions}
          cacheBuster={cacheBuster}
          state={state}
          coachId={coach.id}
          showBackToProfile={false}
          showSaveButton={false}
          showActiveProfile={false}
        />
      </Box>

      <CoachViewModalFooter actions={actions} state={state} showProfileSaveButton={true} />
    </>
  );
};

const CoachViewModalFooter = ({
  actions,
  state,
  onClickEditProfile,
  showProfileEditButton = false,
  showProfileSaveButton = false,
}) => {
  return (
    <Box
      sx={{
        padding: '16px 32px',
        background: '#FFFFFFE6',
        position: 'sticky',
        bottom: '0',
        marginTop: '16px',
        borderBottomLeftRadius: '16px',
        borderBottomRightRadius: '16px',
        marginLeft: '-32px',
        marginRight: '-32px',
        justifyContent: 'space-between',
        display: 'flex',
        zIndex: '10000',
      }}>
      <Switch
        control={state.form.control}
        label={state.query.data.active ? t('activated_profile') : t('disabled_profile')}
        name="active"
        color="primary"
        labelPlacement="end"
        onChange={() => actions.submitForm()}
      />

      {showProfileEditButton && (
        <Button variant="primary" onClick={onClickEditProfile}>
          <EditIcon sx={{ width: '20px', height: '20px', marginRight: '6px' }} />
          {t('coach_view_edit_profile')}
        </Button>
      )}

      {showProfileSaveButton && (
        <Button
          onClick={() => {
            actions.submitForm();
          }}>
          {t('save_profile')}
        </Button>
      )}
    </Box>
  );
};

export default CoachViewModal;
