import React from 'react';
import Paper from '@mui/material/Paper';
import GoBackButton from 'ui/components/shared/GoBackButton';
import { useParams } from 'react-router-dom';
import useEditCoach from 'hooks/usecase/useEditCoach';
import { useMeQuery } from 'hooks/queries';
import EditCoach from './EditCoach';

export default function EditCoachPage() {
  const { coachId } = useParams();
  const { data: user } = useMeQuery();
  const { actions, cacheBuster, state } = useEditCoach(coachId, user?.companyId || '');

  return (
    <Paper
      sx={{
        width: '90%',
        alignSelf: 'center',
        mt: '24px',
        height: '80%',
        overflowY: 'auto',
        pb: '24px',
      }}>
      <GoBackButton sx={{ mt: '12px', ml: '12px', position: 'fixed' }} />

      <EditCoach user={user} actions={actions} cacheBuster={cacheBuster} state={state} coachId={coachId} />
    </Paper>
  );
}
