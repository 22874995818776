import React, { useRef, useState } from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import CircularProgress from '@mui/material/CircularProgress';
import Input from 'ui/components/Input';
import Switch from 'ui/components/Switch';
import TabNavigation from 'ui/pages/program/components/TabNavigation';
import Scopes from './components/scopes/Scopes';
import Themes from './components/themes/Themes';
import Users from './components/users/Users';
import EmailTemplates from './components/emailTemplates/EmailTemplates';
import UploadField from 'ui/components/form/UploadField';
import TargetTemplateItems from './components/targetTemplateItems/TargetTemplateItems';
import TextField from '@mui/material/TextField';
import Departments from 'ui/pages/settings/components/departments/Departments';
import GoBackButton from 'ui/components/shared/GoBackButton';
// Validation
import validation from './validators/company.validator';
import SeniorityLevels from 'ui/pages/settings/components/seniorityLevels/SeniorityLevels';
// Navigation
import { useNavigate } from 'react-router-dom';

import { useEditCompany, useCompanyData } from 'hooks/usecase/useCompany';
import { useMeQuery } from 'hooks/queries';

import { useParams } from 'react-router-dom';

import { getUrlApplicationLink, getUrlEvergreenLink } from 'utils/company';

const EditCompany = ({
  company = {
    name: '',
    hasTerms: false,
    linkTerms: '',
    feedbackLink: '',
  },
  onCancel,
}) => {
  const { data: user } = useMeQuery();
  // Refs
  const refName = useRef();
  const refLinkTerms = useRef();
  const refFeedbackLin = useRef();
  const refExpertLabel = useRef();
  const refClientLabel = useRef();
  const refSessionLabel = useRef();
  const refFeedbackLinkMentor = useRef();
  const refHasTerms = useRef();
  const inputRef = useRef();
  const delayProgramDeletion = useRef();

  const [selectedTab, setSelectedTab] = useState('tab-1');
  const [isLoadingEdit, setIsLoadingEdit] = useState(false);
  let { companyId } = useParams();
  const navigate = useNavigate();
  const {
    isLoading,
    control,
    watch,
    submitEditCompanyWithNavigate,
    submitEditLogoCompany,
    company: companyData,
  } = useCompanyData(companyId);
  const [loadedLogo, setLoadedLogo] = useState(false);

  const getOptionsTab = () => {
    return [
      { id: 'tab-1', text: 'General informations', selected: true },
      { id: 'tab-2', text: 'Scopes', selected: false },
      { id: 'tab-3', text: 'Themes', selected: false },
      { id: 'tab-4', text: 'Users', selected: false },
      { id: 'tab-5', text: 'Email templates', selected: false },
      { id: 'tab-6', text: 'Departments', selected: false, disabled: false },
      { id: 'tab-7', text: 'Seniority Levels', selected: false, disabled: false },
      { id: 'tab-8', text: 'Target Templates', selected: false, disabled: false },
    ];
  };

  const renderComponent = () => {
    switch (selectedTab) {
      case 'tab-2':
        return <Scopes />;
      case 'tab-3':
        return <Themes user={user} />;
      case 'tab-4':
        return <Users user={user} />;
      case 'tab-5':
        return <EmailTemplates user={user} />;
      case 'tab-6':
        return <Departments user={user} isAdmin={true} />;
      case 'tab-7':
        return <SeniorityLevels user={user} isAdmin={true} />;
      case 'tab-8':
        return <TargetTemplateItems user={user} />;

      default:
        return null;
    }
  };

  const handleUpload = () => {
    inputRef?.current?.click();
  };

  if (isLoading)
    return (
      <Stack spacing={1} sx={{ padding: '30px 40px' }}>
        <Skeleton variant="rounded" height={60} />
        <Skeleton variant="rounded" height={60} />
        <Skeleton variant="rounded" height={60} />
        <Skeleton variant="rounded" height={60} />
        <Skeleton variant="rounded" height={60} />
        <Skeleton variant="rounded" height={60} />
        <Skeleton variant="rounded" height={100} />
      </Stack>
    );

  function isObject(variable) {
    return typeof variable === 'object' && variable !== null;
  }

  return (
    <>
      <Box sx={{ margin: '10px 33px' }}>
        <GoBackButton
          onClick={() => {
            navigate(-1);
          }}
        />
      </Box>
      <Stack
        sx={{
          overflowY: 'scroll',
          maxHeight: '730px',
          padding: '20px',
          margin: '0px 33px',
          backgroundColor: 'white',
          '&::-webkit-scrollbar': {
            '-webkit-appearance': 'none',
            width: '4px',
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '25px',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            '-webkit-box-shadow': '0 0 1px rgba(255, 255, 255, 0.5)',
          },
        }}>
        <Stack>
          <Box sx={{ padding: '8px 0px', display: 'flex', alignItems: 'center' }}>
            {isObject(watch('logo')) ? (
              <Box
                sx={{
                  height: '44.38px',
                  width: '76.04px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <CircularProgress size="1rem" color="secondary" />{' '}
              </Box>
            ) : (
              <Box
                height={'44.38px'}
                width={'76.04px'}
                component="img"
                name="logo"
                src={
                  watch('logo')
                    ? `${watch('logo')}?${Date.now()}`
                    : 'https://res.cloudinary.com/dirpe8qjq/image/upload/v1701108669/hvcfy7yngstb5qfuod9n.png'
                }
                sx={{ objectFit: 'contain' }}
                onLoad={() => setLoadedLogo(false)}
                onLoadStart={() => setLoadedLogo(true)}
                loading="lazy"
              />
            )}

            <Box sx={{ width: '24px' }} />
            <Typography
              sx={{
                color: 'primary.main',
                fontSize: '40px',
                fontWeight: 800,
                lineHeight: '32px',
                whiteSpace: 'nowrap',
              }}>
              {watch('name')}
            </Typography>
            <Box sx={{ width: '20px' }} />
            <Box style={{ display: 'none' }}>
              <UploadField
                label="logo"
                name="logo"
                folder={'company-logo/'}
                fileId={companyId}
                control={control}
                ref={inputRef}
                setDisabledButton={() => {}}
                accept={'picture'}
                submitForm={submitEditLogoCompany}
                onUploadSuccess={() => {}}
              />
            </Box>
            <Button
              variant="contained"
              sx={{ backgroundColor: 'primary.dark', whiteSpace: 'nowrap', width: '82px' }}
              onClick={handleUpload}
              size="small">
              {'Edit logo'}
            </Button>
          </Box>
          <Box sx={{ paddingLeft: '90px' }}>
            <TabNavigation
              options={getOptionsTab()}
              onChange={(tab) => {
                setSelectedTab(tab);
              }}
            />
          </Box>
          {selectedTab !== 'tab-1' ? (
            renderComponent()
          ) : (
            <Box sx={{ paddingLeft: '90px' }}>
              <Box sx={{ height: '20px' }} />
              <Box
                sx={{
                  padding: '8px 0px',
                  display: 'flex',
                  maxWidth: '500px',
                  height: '70px',
                }}>
                <Input
                  ref={refName}
                  rules={validation.name}
                  control={control}
                  variant="outlined"
                  label="Name"
                  name="name"
                />
              </Box>
              <Box sx={{ padding: '8px 0px', display: 'flex', maxWidth: '500px', height: '70px' }}>
                <Typography
                  style={{
                    color: '#000000DE',
                    fontSize: '12px',
                    fontWeight: 500,
                    lineHeight: '32px',
                  }}>
                  {'Has terms'}
                </Typography>
                <Switch
                  ref={refHasTerms}
                  control={control}
                  variant="outlined"
                  label="hasTerms"
                  name="hasTerms"
                  fullWidth
                />
              </Box>
              <Box sx={{ padding: '8px 0px', display: 'flex', maxWidth: '500px', height: '70px' }}>
                <Input
                  ref={refLinkTerms}
                  control={control}
                  variant="outlined"
                  label="Link terms"
                  name="linkTerms"
                  rules={{ required: !watch('hasTerms') ? false : 'This field is required' }}
                  fullWidth
                />
              </Box>
              <Box sx={{ padding: '8px 0px', display: 'flex', maxWidth: '500px', height: '70px' }}>
                <Input
                  ref={refFeedbackLin}
                  control={control}
                  variant="outlined"
                  label="Feedback link"
                  name="feedbackLink"
                  rules={validation.feedbackLin}
                  fullWidth
                />
              </Box>
              <Box sx={{ padding: '8px 0px', display: 'flex', maxWidth: '500px', height: '70px' }}>
                <Input
                  ref={refFeedbackLinkMentor}
                  control={control}
                  variant="outlined"
                  label="Feedback Link Mentor"
                  name="feedbackLinkMentor"
                  fullWidth
                />
              </Box>
              <Box sx={{ padding: '8px 0px', display: 'flex', maxWidth: '500px', height: '70px' }}>
                <Input
                  ref={refExpertLabel}
                  control={control}
                  variant="outlined"
                  label="Expert label"
                  name="expertLabel"
                  rules={validation.expertLabel}
                  fullWidth
                />
              </Box>
              <Box sx={{ padding: '8px 0px', display: 'flex', maxWidth: '500px', height: '70px' }}>
                <Input
                  ref={refClientLabel}
                  control={control}
                  variant="outlined"
                  label="Client label"
                  name="clientLabel"
                  rules={validation.clientLabel}
                  fullWidth
                />
              </Box>
              <Box sx={{ padding: '8px 0px', display: 'flex', maxWidth: '500px', height: '70px' }}>
                <Input
                  ref={refSessionLabel}
                  control={control}
                  variant="outlined"
                  label="Session label"
                  name="sessionLabel"
                  rules={validation.sessionLabel}
                  fullWidth
                />
              </Box>
              <Box sx={{ padding: '8px 0px', display: 'flex', maxWidth: '500px', height: '70px' }}>
                <Input
                  ref={delayProgramDeletion}
                  control={control}
                  variant="outlined"
                  label="Delay before deactivated program deletion"
                  name="deactivatedPrograms_delay_before_deletion"
                  rules={validation.deactivatedPrograms_delay_before_deletion}
                  fullWidth
                />
              </Box>
              <Box sx={{ padding: '8px 0px', display: 'flex', maxWidth: '500px', height: '70px' }}>
                <TextField
                  sx={{
                    container: {
                      width: '100%',
                      '& .MuiFilledInput-root': { bgcolor: 'white' },
                      '& .MuiInputBase-root.MuiFilledInput-root:before': { borderBottom: 'none' },
                    },
                    inputProps: { style: { fontSize: 15 } },
                    InputLabelProps: { style: { fontSize: 15 } },
                  }}
                  variant="outlined"
                  label="Application link"
                  fullWidth
                  value={getUrlApplicationLink(
                    companyData && companyData.applicationLink ? companyData.applicationLink : ''
                  )}
                />
              </Box>
              <Box sx={{ padding: '8px 0px', display: 'flex', maxWidth: '500px', height: '70px' }}>
                <TextField
                  sx={{
                    container: {
                      width: '100%',
                      '& .MuiFilledInput-root': { bgcolor: 'white' },
                      '& .MuiInputBase-root.MuiFilledInput-root:before': { borderBottom: 'none' },
                    },
                    inputProps: { style: { fontSize: 15 } },
                    InputLabelProps: { style: { fontSize: 15 } },
                  }}
                  variant="outlined"
                  label="Evergreen link"
                  fullWidth
                  inputProps={{ readOnly: true }}
                  value={getUrlEvergreenLink(companyData && companyData.evergreenLink ? companyData.evergreenLink : '')}
                />
              </Box>
              <Box
                sx={{
                  padding: '8px 0px',
                  height: '40px',
                  display: 'flex',
                  margin: '0px 0px',
                  backgroundColor: 'white',
                }}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    navigate(-1);
                  }}
                  size="large">
                  {'BACK'}
                </Button>
                <Box sx={{ width: '33px' }} />
                <Button
                  variant="contained"
                  sx={{ backgroundColor: 'primary.dark', width: '80px' }}
                  onClick={() => {
                    submitEditCompanyWithNavigate();
                  }}
                  size="large">
                  {isLoadingEdit ? <CircularProgress size="1rem" color="secondary" /> : 'SAVE'}
                </Button>
              </Box>
            </Box>
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default EditCompany;
