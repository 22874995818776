import React, { useState } from 'react';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';

import styles from './Header.styles';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import { useAlert } from 'common/alertContext';
import { useMeQuery } from 'hooks/queries';
import EditButton from '../EditButton/EditButton';
import EditThemeModal from '../EditThemeModal/EditThemeModal';
import EditMentorImpossibleModal from '../EditMentorImpossibleModal/EditMentorImpossibleModal';
import EditMentorModal from '../EditMentorModal/EditMentorModal';
import AvatarGroup from 'ui/components/shared/AvatarGroup/AvatarGroup';
import GoBackButton from 'ui/components/shared/GoBackButton';
import { StatusChip } from 'ui/components/StatusChip/StatusChip';
import { formatSessionDate } from 'utils/sessions';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Button from 'ui/components/Button/Button';
import FinishProgramModal from '../FinishProgramModal/FinishProgramModal';
import ReopenProgramModal from '../ReopenProgramModal/ReopenProgramModal';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { roleList } from 'utils/constUtils';

const Header = ({
  coachee,
  coach,
  coachId,
  companyName,
  expertLabel,
  selectedCoaches,
  startedAt,
  rhEmail,
  isRh,
  status,
  statusBeforeDeactivation,
  showEditButton,
  showEndProgram,
  showReopenProgram,
  topic,
  themeId,
  onChangeTheme,
  onChangeMentor,
  onDeactivateProgram,
  onFinishProgram,
  onReopenProgram,
}) => {
  const { t } = useTranslation();
  const { data: user } = useMeQuery();
  const [openThemeModal, setOpenThemeModal] = useState(false);
  const [openEditMentorImpossible, setOpenEditMentorImpossible] = useState(false);
  const [openMentorModal, setOpenMentorModal] = useState(false);
  const [finishProgramModal, setFinishProgramModal] = useState(false);
  const [reopenProgramModal, setReopenProgramModal] = useState(false);

  let avatarConfigCoaches = [];

  if (status === 'pending' || (status === 'deactivated' && statusBeforeDeactivation === 'pending')) {
    avatarConfigCoaches = selectedCoaches.map(({ id, user, picture }) => {
      const { firstname, lastname } = user;
      return { id, picture, firstname, lastname };
    });
  }

  const onClickExpert = () => {
    if (status === 'pending') {
      setOpenEditMentorImpossible(true);
    } else {
      setOpenMentorModal(true);
    }
  };

  const coacheTitle = () => {
    if (user.role === roleList.COACHEE) {
      return t('your_coach', { expertLabel: user?.company?.expertLabel });
    }

    return user?.company?.expertLabel;
  };

  const coacheeTitle = () => {
    if (user.role === roleList.COACH) {
      return t('your_coachee', { clientLabel: user?.company?.clientLabel });
    }

    return user?.company?.clientLabel;
  };

  const isValidCoachee = coachee.firstname && coachee.lastname;

  return (
    <Stack direction="row" useFlexGap flexWrap="wrap" sx={styles.container}>
      <Box sx={styles.containerGoBack}>
        <GoBackButton />
      </Box>
      <Box sx={styles.programInformation}>
        <Typography sx={styles.topicName}>{topic}</Typography>
        <Box sx={styles.programInformationDetail}>
          <StatusChip expertLabel={expertLabel} status={status} style={styles.statusChip} />
          <Typography sx={styles.startedAt} variant="h6">
            {t('session_launch_date', { sessionDate: formatSessionDate(startedAt) })}
          </Typography>
        </Box>
        {!isRh && rhEmail && (
          <Tooltip placement="bottom" title={t('program_header_manager_contact_tooltip')}>
            <Box sx={styles.containerRhContact}>
              <MailOutlineIcon sx={{ fontSize: '18px' }} />
              <Typography
                sx={styles.contactManager}
                variant="h6"
                onClick={(e) => {
                  window.location.href = `mailto:${rhEmail}`;
                  e.preventDefault();
                }}>
                {t('program_header_manager_contact', { companyName })}
              </Typography>
            </Box>
          </Tooltip>
        )}
      </Box>
      {!!avatarConfigCoaches.length && (
        <Box sx={styles.containerItemMedium}>
          <Typography sx={styles.title}>
            {t('program_header_proposed_expert', { expertLabel: user?.company?.expertLabel })}
          </Typography>
          <Box sx={styles.containerUser}>
            <AvatarGroup avatarConfig={avatarConfigCoaches} width={40} />
          </Box>
        </Box>
      )}
      {((status !== 'pending' && status !== 'deactivated') ||
        (status === 'deactivated' && statusBeforeDeactivation !== 'pending')) &&
        coach &&
        user.role !== roleList.COACH && (
          <Box sx={styles.containerItemMedium}>
            <UserInformation
              email={coach.user.email}
              picture={coach.picture}
              title={coacheTitle()}
              userName={`${coach.user.firstname} ${coach.user.lastname}`}
              userTitle={coach.title}
            />
          </Box>
        )}
      {user.role !== roleList.COACHEE && isValidCoachee && (
        <Box sx={styles.containerItemMedium}>
          <UserInformation
            email={coachee.email}
            picture={coachee.picture}
            title={coacheeTitle()}
            userName={`${coachee.firstname} ${coachee.lastname}`}
            userTitle={coachee.title}
          />
        </Box>
      )}
      {showEditButton && (
        <Box sx={styles.containerActions}>
          <EditButton
            expertLabel={user?.company?.expertLabel}
            clientLabel={user?.company?.clientLabel}
            onClickTheme={() => {
              setOpenThemeModal(true);
            }}
            onClickExpert={onClickExpert}
          />
        </Box>
      )}
      {showEndProgram && (
        <Box sx={styles.containerActions}>
          <Button onClick={() => setFinishProgramModal(true)}>{t('finish_program_button')}</Button>
        </Box>
      )}
      {showReopenProgram && (
        <Box sx={styles.containerActions}>
          <Button onClick={() => setReopenProgramModal(true)}>{t('reopen_program_button')}</Button>
        </Box>
      )}
      {openThemeModal && (
        <EditThemeModal
          company={user?.company}
          open={openThemeModal}
          themeId={themeId}
          onClose={() => {
            setOpenThemeModal(false);
          }}
          onChangeTheme={onChangeTheme}
        />
      )}
      <EditMentorImpossibleModal
        expertLabel={user?.company?.expertLabel}
        clientLabel={user?.company?.clientLabel}
        open={openEditMentorImpossible}
        onDeactivateProgram={onDeactivateProgram}
        onClose={() => {
          setOpenEditMentorImpossible(false);
        }}
      />
      {openMentorModal && (
        <EditMentorModal
          coachee={coachee}
          expertLabel={user?.company?.expertLabel}
          mentorIdSelected={coachId}
          open={openMentorModal}
          onChangeMentor={onChangeMentor}
          onClose={() => {
            setOpenMentorModal(false);
          }}
        />
      )}
      <FinishProgramModal
        programName={topic}
        open={finishProgramModal}
        onClose={() => {
          setFinishProgramModal(false);
        }}
        onFinishProgram={onFinishProgram}
      />
      <ReopenProgramModal
        programName={topic}
        open={reopenProgramModal}
        onClose={() => {
          setReopenProgramModal(false);
        }}
        onReopenProgram={onReopenProgram}
      />
    </Stack>
  );
};

const UserInformation = ({ title, picture, userName, userTitle, email }) => {
  const { t } = useTranslation();
  const { setStateAlert } = useAlert();

  const onClick = (field) => {
    navigator.clipboard.writeText(field);
    setStateAlert({ open: true, message: t('email_copied'), type: 'info' });
  };

  return (
    <>
      <Typography sx={styles.title}>{title}</Typography>
      <Box sx={styles.containerUser}>
        <Avatar sx={styles.avatar} src={picture} />
        <Box sx={styles.containerUserInformations}>
          <Box sx={styles.userInformation}>
            <Typography sx={styles.userName}>{userName}</Typography>
            <EmailOutlinedIcon sx={styles.mailIcon} onClick={() => onClick(email)} />
          </Box>
          {userTitle && <Typography sx={styles.userTitle}>{userTitle}</Typography>}
        </Box>
      </Box>
    </>
  );
};

export default Header;
