import React, { useMemo, useState } from 'react';
import Box from 'ui/components/system/Box';
import { Switch, Case } from 'ui/components/system/SwitchCase';
import useCreateSubscription from 'hooks/usecase/useCreateSubscription';

import ChooseTheme from './ChooseTheme';
import InviteCoachee from './InviteCoachee';
import Confirmations from './Confirmation';
import ConfirmationSuccess from './ConfirmationSuccess';

import Paper from '@mui/material/Paper';

import List from '@mui/material/List';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { TransitionGroup } from 'react-transition-group';
import Collapse from '@mui/material/Collapse';

import ChatIcon from '@mui/icons-material/Chat';
import SchoolIcon from '@mui/icons-material/School';
import GroupsIcon from '@mui/icons-material/Groups';
import { DataListField, DataField } from './components';
import CoachCardMini from 'ui/components/shared/coaches/CoachCardMini';
import CoacheeCardMini from 'ui/components/shared/CoacheeCardMini';
import { useTranslation } from 'react-i18next';
import Fab from '@mui/material/Fab';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CircularProgress from '@mui/material/CircularProgress';
import Button from 'ui/components/Button/Button';

import {
  ADD_YOUR,
  COACH,
  COACHEE,
  COLLABORATORS,
  DESCRIPTION_TOPIC,
  EXPERT,
  NEXT,
  PREV,
  PROGRAM_CREATION_STEP4_TITLE,
  CONFIRMATION,
  TOPIC,
} from 'litterals';
import { useMeQuery } from 'hooks/queries';
import useScopeList from 'hooks/usecase/useScopeList';
import Experts from './Experts';
import dayjs from 'dayjs';
import styles from './styles';
import Divider from '@mui/material/Divider';

var relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);

const StepHeader = ({ activeStep, DESCRIPTIONS }) => {
  return (
    <Switch value={activeStep}>
      {DESCRIPTIONS.map((descr, index) => (
        <Case value={index} key={`step-header-case-${index}`}>
          <Typography sx={styles.title} variant="h6">
            {index + 1}. {descr}
          </Typography>
        </Case>
      ))}
    </Switch>
  );
};

export const SubmitButton = ({ disabled, onSubmit, loading }) => {
  const { t } = useTranslation();
  return (
    <Button sx={{ width: 'fit-content', alignSelf: 'end', mb: '12px' }} disabled={disabled} onClick={onSubmit}>
      {t(CONFIRMATION)}
      {loading ? (
        <CircularProgress sx={{ color: 'white', marginLeft: '5px' }} size={20} />
      ) : (
        <TaskAltIcon sx={{ ml: '4px' }} />
      )}
    </Button>
  );
};

const FormNavButtons = ({ step, state, actions, onClickPrev, onClickNext, disabledPrev, disabledNext }) => {
  const { t } = useTranslation();
  const [acceptTerms, setAcceptTerms] = React.useState(false);

  const isInternal =
    state && state.form && state.form.coaches && Object.values(state.form.coaches).length > 0
      ? Object.values(state.form.coaches)[0].internalCoach
      : false;

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-around', mb: '24px' }}>
      <Button disabled={disabledPrev} onClick={onClickPrev}>
        {t(PREV)}
      </Button>
      {step == 3 ? (
        <SubmitButton
          loading={state.query.loadingConfirmation}
          disabled={(!acceptTerms && !isInternal) || state.query.loadingConfirmation}
          onSubmit={actions.createSubscription}
        />
      ) : (
        <Button disabled={disabledNext} onClick={onClickNext}>
          {t(NEXT)}
        </Button>
      )}
    </Box>
  );
};

const SummaryBox = ({ state, actions }) => {
  const { data: user } = useMeQuery();
  const { t } = useTranslation();

  return (
    <Paper elevation={2} sx={{ overflowY: 'auto', mr: '12px', mt: '24px', height: '100%' }}>
      <List sx={{ maxHeight: '80vh', overflowY: 'auto' }}>
        <DataField label={t(TOPIC)} value={state.form.theme?.name} nbSessions={state.form.theme?.nbSessions}>
          <ChatIcon />
        </DataField>
        <Divider />
        <DataListField label={user?.company?.clientLabel || t(COLLABORATORS)} icon={<GroupsIcon />}>
          <TransitionGroup>
            {Object.values(state.form.coachees).map((coachee) => (
              <Collapse key={`coachee-card-mini-${coachee.id}`}>
                <CoacheeCardMini
                  {...coachee}
                  onClickDelete={state.nav.activeStep == 1 ? actions.removeCoachee : null}
                  sx={{ mb: '6px' }}
                  isProgramCreation={true}
                />
              </Collapse>
            ))}
          </TransitionGroup>
        </DataListField>
        <Divider />
        <DataListField label={user?.company?.expertLabel || t(EXPERT)} icon={<SchoolIcon />}>
          <TransitionGroup>
            {Object.values(state.form.coaches).map((coach) => (
              <Collapse key={`coach-card-mini-${coach.id}`}>
                <CoachCardMini
                  {...coach}
                  onClickDelete={state.nav.activeStep == 2 ? actions.removeCoach : null}
                  sx={{ mb: '6px' }}
                  isAddMentor={true}
                />
              </Collapse>
            ))}
          </TransitionGroup>
        </DataListField>{' '}
      </List>
    </Paper>
  );
};

const StepForm = ({ state, actions, setQueryParams, queryParams, onCloseModal }) => {
  const { t } = useTranslation();
  const { data: user } = useMeQuery();
  const { simpleScopeOption } = useScopeList();

  const descriptionExpert = useMemo(
    () => (user?.company?.expertLabel ? `${t(ADD_YOUR)} ${user?.company?.expertLabel}` : `${t(ADD_YOUR)} ${t(COACH)}`),
    [user?.company?.expertLabel, ADD_YOUR, COACH]
  );

  const descriptionCoachee = useMemo(
    () =>
      user?.company?.clientLabel ? `${t(ADD_YOUR)} ${user?.company?.clientLabel}` : `${t(ADD_YOUR)} ${t(COACHEE)}`,
    [user?.company?.clientLabel, ADD_YOUR, COACHEE]
  );

  const descriptionConfirmation = t(PROGRAM_CREATION_STEP4_TITLE);

  const DESCRIPTIONS = useMemo(
    () => [t(DESCRIPTION_TOPIC), descriptionCoachee, descriptionExpert, descriptionConfirmation],
    [descriptionExpert, descriptionCoachee]
  );

  const switchStep = (stepValue) => {
    switch (stepValue) {
      case 0:
        return <ChooseTheme themes={state.query.themes.data} onClickSelect={actions.selectTheme} t={t} />;
      case 1:
        return (
          <InviteCoachee
            state={state}
            actions={actions}
            control={state.inviteForm.control}
            onSubmit={actions.submitInviteForm}
            onRowClicked={(data) => {
              // eslint-disable-next-line no-unused-vars
              const { user, ...rest } = data;
              const filtredData = Object.fromEntries(Object.entries(rest).filter(([_, v]) => v != null));
              actions.submitInviteCoachee(filtredData);
              actions.clickNext();
            }}
            onClickReset={actions.resetInviteForm}
            clickNext={actions.clickNext}
            disabled={state.nav.inviteFormDisabled}
            simpleScopeOption={simpleScopeOption}
            coachs={Object.values(state.form.coaches)}
          />
        );
      case 2:
        return (
          <>
            {state && state.form && state.form.coachees[Object.keys(state.form.coachees)[0]] ? (
              <Experts
                experts={state.query.coaches.coaches && state.query.coaches.coaches}
                onClickSelect={actions.selectCoach}
                setQueryParams={setQueryParams}
                queryParams={queryParams}
                isLoading={state.query.coaches.isLoading}
                coachee={state && state.form ? state.form.coachees[Object.keys(state.form.coachees)[0]] : ''}
              />
            ) : null}
          </>
        );
      case 3:
        return (
          <Confirmations
            state={state}
            actions={actions}
            themeName={state && state.form && state.form.theme ? state.form.theme.name : ''}
            nbSessions={state && state.form.theme && state.form.theme.nbSessions ? state.form.theme.nbSessions : ''}
            coachee={state && state.form ? state.form.coachees[Object.keys(state.form.coachees)[0]] : ''}
            coaches={Object.values(state.form.coaches)}
            rh={state && state.user ? state.user : {}}
            expertLabel={user && user.company && user.company.expertLabel ? user.company.expertLabel : ''}
            clientLabel={user && user.company && user.company.clientLabel ? user.company.clientLabel : ''}
          />
        );
      case 4:
        return <ConfirmationSuccess />;
      default:
        return <></>;
    }
  };

  return (
    <Stack
      direction="column"
      alignItems="stretch"
      justifyContent="space-between"
      component={Paper}
      sx={{ mt: '24px', ml: '12px', height: '100%' }}>
      <Typography />
      <StepHeader activeStep={state.nav.activeStep} DESCRIPTIONS={DESCRIPTIONS} />
      <Box sx={{ height: '85%', maxHeight: '85%', width: '100%' }}>{switchStep(state.nav.activeStep)}</Box>
      <FormNavButtons
        disabledNext={state.nav.disabledNext}
        disabledPrev={state.nav.disabledPrev}
        onClickPrev={actions.clickPrev}
        onClickNext={actions.clickNext}
        state={state}
        actions={actions}
        step={state.nav.activeStep}
      />
    </Stack>
  );
};

export default function CreateSubscription() {
  const [queryParams, setQueryParams] = useState({ currentPage: 1, size: 10 });

  const { state, actions } = useCreateSubscription(queryParams);

  return (
    <Grid container spacing={4} sx={{ height: '88vh' }}>
      <Grid item xs={10}>
        <StepForm queryParams={queryParams} setQueryParams={setQueryParams} state={state} actions={actions} />
      </Grid>
      <Grid item xs={2}>
        <SummaryBox state={state} actions={actions} />
      </Grid>
    </Grid>
  );
}
