/* -------------------------------------------------------------------------- */
/*                                Email Template                              */
/* -------------------------------------------------------------------------- */

/**
 * Defines an email template where occurrences of ${customContent} are replaced with $customContent$.
 *
 * @const {customContent}
 */

export const emailTemplate = ` <!DOCTYPE html>
<html xmlns="http://www.w3.org/1999/xhtml">
  <head>
    <title>Pathline</title>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <style type="text/css">
      * {
        font-family: "arial";
      }

      body {
        margin: 0;
        background-color: #F6F6F6;
      }

      table {
        border-spacing: 0;
        width: 100%;
      }

      p {
        font-size: 13px;
      }

      span {
        font-size: 13px;
      }

      img {
        border: 0;
        height: auto;
        display: block;
      }

      a {
        padding: 3px;
      }

      .wrapper {
        width: 100%;
        table-layout: fixed;
        background-color: #EFEFEF;
        padding-bottom: 60px;
      }

      .main {
        background-color: #ffffff;
        margin: 0 auto;
        max-width: 600px;
        border-spacing: 0;
        font-family: sans-serif;
        color: #4a4a4a;
      }

      .coordonnees {
        font-size: 12px;
        color: #585858;
        font-family: Arial, Helvetica, sans-serif;
        text-align: center;
        padding-top: 10px;
        border: 50%;
      }

      .border {
        border-top: 2px solid #c3c2c2;
        width: 60%;
        margin-left: 17%;
      }

      .border_full {
        border: 1px solid #c3c2c2;
      }

      .row {
        padding: 30px;
        line-height: 16px;
        font-size: 11px;
        color: #585858;
        font-family: Tahoma, Geneva, sans-serif;
        position: relative;
        text-align: justify;
      }

      .logo {
        padding: 30px 0px 0px 30px;
      }

      .row-text {
        padding: 30px 0px 0px 30px;
      }

      .footer-logo {
        border: 2px solid red;
        text-align: center;
        vertical-align: middle;
        width: 86px
      }
    </style>
  </head>
  <body>
    <center class="wrapper">
      <table class="main" width="600">
        <tr>
          <td style="border:4px solid #081856; background-color:#081856"></td>
        </tr>
        <tr>
          <td style="padding:0px">
            <img width="600" title="Banner" src="https://pathline-prod-bucket.s3.fr-par.scw.cloud/email_pictures/email_background.png" />
          </td>
        </tr>
        <tr>
          <td class="row-text">
            $customMessage$
          </td>
        </tr>
        

        <tr>
          <td align="center" valign="top">
            <img src="https://pathline-prod-bucket.s3.fr-par.scw.cloud/email_pictures/email_logo.png" alt="" style="
              display: block;
              margin: 0px auto;
              height: auto;
            " width="86" border="0" class="wlkm-resp" onerror="this.src='/imgs/invalidImg.png'" />
          </td>
        </tr>
        <tr style="border:5px solid red;background-color:#F6F6F6">
          <td align="center" valign="top">
            <p style="font-family:Tahoma;word-break:break-word;color:black;font-size:14px;">Pathline,10, Rue de Penthièvre, Ile de France 75008,  <br />
              France - <a href="mailto:contact@pathline.co">
                <span>Se désabonner</span>
              </a>
            </p>
          </td>
        </tr>
      </table>
    </center>
  </body>
</html> `;
