import { useState } from 'react';
import { useMeQuery, useListProgramsQueryWithParams } from 'hooks/queries';
import useScopeList from './useScopeList';
import { roleList } from 'utils/constUtils';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import api from 'api';
import { useAlert } from 'common/alertContext';

const useViewSubscription = ({ initialFilterState }) => {
  const [filterSubscriptionState, setFilterSubscriptionState] = useState(initialFilterState);
  const queryClient = useQueryClient();
  let query = useListProgramsQueryWithParams(initialFilterState);

  const { data: user } = useMeQuery();
  const { setStateAlert, stateAlert } = useAlert();

  const [programId, setProgramId] = useState('');
  const [userId, setUserId] = useState('');
  const [requestBody, setRequestBody] = useState({
    accept: true,
  });

  const { scopeOption } = useScopeList({
    enabled: [roleList.RH, roleList.RH_MANAGER].includes(user.role),
  });

  const updateFilterSubscription = (newValue) => {
    return setFilterSubscriptionState({
      ...newValue,
    });
  };

  const mutationCoachingRequest = useMutation(api.Programs.respond, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['list-programs']);
    },
    onError: (error) => {
      setStateAlert({ open: true, message: `${error.response.data.error}`, type: 'error' });
      queryClient.invalidateQueries(['list-programs']);
    },
  });

  const { mutate: mutationInvite, isFetched } = useMutation(api.Guests.invite, {
    onSuccess: (data) => {
      console.log('body : ', { ...requestBody, coacheeId: data.id });
      mutationCoachingRequest.mutate({ id: programId, body: { ...requestBody, coacheeId: data.id } });
    },
    onError: (error) => {
      setStateAlert({ open: true, message: `${error.response.data.error}`, type: 'error' });
      queryClient.invalidateQueries(['list-programs']);
    },
  });

  const handleCoachingRequest = (guestId, programId, body) => {
    setProgramId(programId);
    setRequestBody(body);
    mutationInvite(guestId);
  };

  const reloadListPrograms = () => {
    queryClient.invalidateQueries(['list-programs']);
  };

  return {
    state: { filterSubscriptionState, scopeOption },
    query: query,
    actions: { reloadListPrograms, updateFilterSubscription, handleCoachingRequest },
  };
};

export default useViewSubscription;
