/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Lib dependencies
import React from 'react';

// UI lib dependencies;

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

// Icons
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';

import { useTranslation } from 'react-i18next';
import { ADD, PROFILE } from 'litterals';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function CellActionsButtons({ rowData, onAdd, onShow, hiddenAddButton = false }) {
  /* ********************************** HOOKS ********************************* */

  const { t } = useTranslation();
  /* ********************************** FUNCTIONS ********************************* */

  /* ******************************** RENDERING ******************************* */
  return (
    <Stack direction={'row'}>
      {!hiddenAddButton && (
        <Button
          sx={{ padding: '5px' }}
          variant="outlined"
          onClick={(event) => {
            event.stopPropagation();
            onAdd(rowData);
          }}
          size="small">
          <Stack direction="row" alignItems="center">
            <AddIcon />
            <Typography sx={{ fontSize: '14px' }}>{t(ADD)}</Typography>
          </Stack>
        </Button>
      )}
      <Box sx={{ width: '12px' }} />
      <Button
        sx={{ padding: '5px' }}
        variant="outlined"
        onClick={(event) => {
          event.stopPropagation();
          onShow(rowData);
        }}
        size="small">
        <Stack direction="row" alignItems="center">
          <VisibilityIcon />
          <Typography sx={{ fontSize: '14px', paddingLeft: '2px' }}>{t('view')}</Typography>
        </Stack>
      </Button>
    </Stack>
  );
}

export default CellActionsButtons;
