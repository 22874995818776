import React, { useEffect, useState } from 'react';
import { Box, Grid, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import TabNavigation from 'ui/pages/program/components/TabNavigationSettings';
import { mostFrequent } from 'common/utils';

// Style
import { useSelectProgram } from 'hooks/usecase/useEvergreen';
import { useTranslation } from 'react-i18next';

import ThemeViewModal from 'ui/components/shared/theme/ThemeViewModal';

// Components
import CardProgram from '../components/Cards/CardProgram/CardProgram';
import styles from 'ui/components/CodeMirror/CodeMirror.styles';

// constants
const imageNoData = `${process.env.PUBLIC_URL}/uploads/images/file.png`;

const SelectProgram = ({
  onSelectProgram,
  idCompany,
  company,
  onThemeDetail,
  themeIdSelected,
  showObjectives = true,
  showSelectButton = true,
  showTabs = false,
  activeHorizontalScroll = false,
  showThemeViewInModal = true,
}) => {
  // eslint-disable-next-line no-unused-vars
  const { t } = useTranslation();
  const { themes, isLoading } = useSelectProgram(idCompany, company.evergreenLink);
  const [themeToShow, setThemeToShow] = useState({});
  const [isOpenThemePreview, setIsOpenThemePreview] = useState(false);
  const [selectedTab, setSelectedTab] = useState('');
  const [filteredThemes, setFilteredThemes] = useState([]);
  const [tabsThemes, setTabsThemes] = useState([]);

  const horizontalScrollStyles = activeHorizontalScroll
    ? {
        overflowX: 'scroll',
        flexFlow: 'row',
        display: 'flow',
        paddingBottom: '12px',
        paddingLeft: '1px',
        whiteSpace: 'nowrap',
        overflowY: 'scroll',
      }
    : {};

  useEffect(() => {
    if (!themes) {
      setFilteredThemes([]);
      return;
    }

    const groupLabels = themes?.map((theme) => theme.groupLabel) || [];
    const mostPresentGroupLabel = mostFrequent(groupLabels);

    const groupLabelsUnique = [...new Set([mostPresentGroupLabel, ...groupLabels])];

    const tabsThemes = groupLabelsUnique.map((groupLabel) => {
      return { id: groupLabel, text: groupLabel, selected: groupLabel === mostPresentGroupLabel, disabled: false };
    });

    setTabsThemes(tabsThemes);

    if (!selectedTab) {
      setSelectedTab(mostPresentGroupLabel);
    }

    if (showTabs) {
      setFilteredThemes(themes.filter((theme) => theme.groupLabel === mostPresentGroupLabel));
    } else {
      setFilteredThemes(themes);
    }
  }, [themes]);

  if (isLoading)
    return (
      <Grid sx={{ paddingTop: '100px', paddingLeft: '50px' }} container spacing={5}>
        <Grid item>
          <Skeleton variant="rounded" width={368} height={406} />
        </Grid>
        <Grid item>
          <Skeleton variant="rounded" width={368} height={406} />
        </Grid>
        <Grid item>
          <Skeleton variant="rounded" width={368} height={406} />
        </Grid>
        <Grid item>
          <Skeleton variant="rounded" width={368} height={406} />
        </Grid>
        <Grid item>
          <Skeleton variant="rounded" width={368} height={406} />
        </Grid>
      </Grid>
    );

  return (
    <>
      {showTabs && tabsThemes.length > 1 && (
        <Box sx={styles.tabsGroupThemes}>
          <TabNavigation
            options={tabsThemes}
            onChange={(tab) => {
              setSelectedTab(tab);
              setFilteredThemes(themes.filter((theme) => theme.groupLabel === tab));
            }}
            customStyle={{ marginTop: '0px!important', color: 'red' }}
          />
        </Box>
      )}
      <Grid
        container
        spacing={3}
        sx={{
          paddingBottom: '5px',
          width: '100%',
          margin: '0 auto',
          ...horizontalScrollStyles,
        }}>
        {filteredThemes &&
          filteredThemes.map((theme) => (
            <Grid
              key={'id-' + Math.random()}
              item={!activeHorizontalScroll}
              sx={
                activeHorizontalScroll
                  ? { display: 'inline-block', paddingRight: '16px', '&:last-child': { paddingRight: 0 } }
                  : {}
              }>
              <CardProgram
                t={t}
                picture={theme.marketingPicture}
                onSelect={() => {
                  onSelectProgram(theme);
                }}
                onShowMoreInfo={() => {
                  setThemeToShow(theme.id);
                  setIsOpenThemePreview(true);
                  onThemeDetail && onThemeDetail(theme.id);
                }}
                selected={themeIdSelected === theme.id}
                title={theme.name}
                nbSessions={theme.nbSessions}
                showSelectButton={showSelectButton}
                objectives={
                  showObjectives && company && company.target_template_items
                    ? company.target_template_items.map((target) => target.name)
                    : []
                }
              />
            </Grid>
          ))}
        {filteredThemes && filteredThemes.length === 0 ? (
          <Grid key={'id-' + Math.random()} item>
            <Stack alignItems={'center'}>
              {imageNoData ? <Box onClick={() => {}} component="img" src={imageNoData} /> : null}
              <Typography>{'No data'}</Typography>
            </Stack>
          </Grid>
        ) : null}
      </Grid>
      {showThemeViewInModal && (
        <ThemeViewModal
          theme={filteredThemes && filteredThemes.find((item) => item.id === themeToShow)}
          themeId={themeToShow && themeToShow.id ? themeToShow.id : ''}
          open={isOpenThemePreview}
          onClose={() => {
            setThemeToShow(null);
            setIsOpenThemePreview(false);
          }}
        />
      )}
    </>
  );
};

export default SelectProgram;
