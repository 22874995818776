import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { ANSWER_REQUEST, LAUNCH, OPEN_PROGRAM, SCOPE } from 'litterals';
import Button from 'ui/components/Button/Button';

// UI MUI lib
import TableCell from '@mui/material/TableCell';
import Stack from '@mui/material/Stack';

// UI local lib
import DataGrid from 'ui/components/DataGrid';
import CoachCardMini from 'ui/components/shared/coaches/CoachCardMini';
import CoachViewModalWithRequest from 'ui/components/shared/coaches/CoachViewModalWithRequest';

// Configs
import { tableSchemaBase as columnsCoachee } from './Programs-coachee.config';
import { tableSchemaBase as columnsCoach } from './Programs-coach.config';

import TopicFilterOperators from 'ui/components/DataGrid/Filters/TopicFilter/TopicFilter';
import ScopeFilterOperators from 'ui/components/DataGrid/Filters/ScopeFilter/ScopeFilter';
import DateFilterOperators from 'ui/components/DataGrid/Filters/DateFilter/DateFilter';
import StatusFilterOperators from 'ui/components/DataGrid/Filters/StatusFilter/StatusFilter';
import CoachingRequest from './components/CoachingRequest';
import ChangeStatusRequest from './components/ChangeStatusRequest/ChangeStatusRequest';
import { datesDiff } from 'utils/dateUtils';

const ProgramsTable = ({
  programs,
  isActiveEvergreenColumns = false,
  isDisablesRowClick = false,
  isLoading,
  asCoachee = false,
  disableColumnsRh = [],
  role,
  updateFilter,
  queryParams,
  setQueryParams,
  totalPage,
  totalItems,
  user,
  handleCoachingRequest,
  onDeactivateProgram,
  onReactivateProgram,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleOnFilter = (filters, currentPage, currentSize) => {
    let queryParamsFilter = {};

    if (filters.items) {
      filters.items.map((filter) => {
        switch (filter.field) {
          case 'coachee':
            queryParamsFilter = { ...queryParamsFilter, coacheeName: filter.value };
            break;
          case 'scopeMentee':
            queryParamsFilter = { ...queryParamsFilter, coacheeScope: filter.value };
            break;
          case 'scopeMentor':
            queryParamsFilter = { ...queryParamsFilter, coachScope: filter.value };
            break;
          case 'coach':
            queryParamsFilter = { ...queryParamsFilter, coachName: filter.value };
            break;
          case 'theme':
            queryParamsFilter = { ...queryParamsFilter, themeId: filter.value };
            break;
          case 'createdAt':
          case 'deactivatedAt':
            queryParamsFilter = {
              ...queryParamsFilter,
              [filter.field]: filter.value && dayjs(filter.value).format('YYYY-MM-DD'),
            };
            break;
          default:
            queryParamsFilter = { ...queryParamsFilter, [filter.field]: filter.value };
            break;
        }
      });
    }

    updateFilter({
      ...{ orderBy: queryParams.orderBy, orderDirection: queryParams.orderDirection },
      page: currentPage,
      size: currentSize,
      ...queryParamsFilter,
    });
    setQueryParams({
      ...{ orderBy: queryParams.orderBy, orderDirection: queryParams.orderDirection },
      page: currentPage,
      size: currentSize,
      ...queryParamsFilter,
    });
  };

  const handleOnSort = (sort, currentPage, currentSize) => {
    let queryParamsSort = {};

    switch (sort.orderBy) {
      case 'dayToDeletion':
        queryParamsSort = { ...sort, orderBy: 'deactivatedAt' };
        break;
      case 'scopeName':
        queryParamsSort = { ...sort, orderBy: 'scopeId' };
        break;
      case 'user':
        queryParamsSort = { ...sort, orderBy: 'firstname' };
        break;
      case 'theme':
        queryParamsSort = { ...sort, orderBy: 'theme.name' };
        break;
      case 'scopeMentee':
        queryParamsSort = { ...sort, orderBy: 'coacheeScope' };
        break;
      case 'scopeMentor':
        queryParamsSort = { ...sort, orderBy: 'coachScope' };
        break;
      case 'coachee':
        queryParamsSort = { ...sort, orderBy: 'coachee.firstname' };
        break;
      case 'coach':
        queryParamsSort = { ...sort, orderBy: 'coach.user.firstname' };
        break;
      case 'progress':
        queryParamsSort = { ...sort, orderBy: 'progressRatio' };
        break;
      case '':
        queryParamsSort = { orderBy: '' };
        break;
      default:
        queryParamsSort = { ...sort };
        break;
    }
    setQueryParams({ ...queryParams, page: currentPage, size: currentSize, ...queryParamsSort });
    updateFilter({ ...queryParams, page: currentPage, size: currentSize, ...queryParamsSort });
  };

  const coacheeItem = (currentItem) => {
    const { coachee, guest, status, type, evergreenCoachesStatus } = currentItem;
    if (coachee) {
      return { name: coachee.firstname + ' ' + coachee.lastname, picture: '', title: '', email: coachee?.email };
    }

    if (guest && ((status == 'pending' && type == 'EVERGREEN') || isActiveEvergreenColumns)) {
      return { name: guest.firstname + ' ' + guest.lastname, picture: '', title: '', email: coachee?.email };
    }

    if (type == 'EVERGREEN' && evergreenCoachesStatus?.guestDeleted) {
      const { firstname, lastname } = evergreenCoachesStatus.guestDeleted;
      return { name: firstname + ' ' + lastname, picture: '', title: '', email: coachee?.email };
    }

    return { name: '', picture: '', title: '', email: '' };
  };

  const updateList = (programs) =>
    programs.reduce((acc, currentItem) => {
      const expertLabel = user.company.expertLabel;
      const coachsForOTHERS =
        currentItem && currentItem.coach ? currentItem.coach : { name: '', picture: '', title: '' };

      const evergreenClosingDate = currentItem?.evergreenCoachesStatus?.dateRequestFinished
        ? dayjs(currentItem.evergreenCoachesStatus.dateRequestFinished).format('DD/MM/YYYY')
        : '-';

      const evergreenClosingDateConfig = {
        date: evergreenClosingDate,
        estimate: currentItem?.evergreenCoachesStatus?.estimate,
      };

      const modifiedItem = {
        ...currentItem,
        id: currentItem.id,
        coachee: coacheeItem(currentItem),
        status: currentItem.status,
        theme: currentItem.theme,
        progress: currentItem.progress,
        scopeMentee: currentItem.coachee && currentItem.coachee.scope ? currentItem.coachee.scope.name : '',
        coach: coachsForOTHERS,
        scopeMentor:
          currentItem.coach && currentItem.coach.user && currentItem.coach.user.scope
            ? currentItem.coach.user.scope.name
            : '',
        createdAt: dayjs(currentItem.createdAt).format('DD/MM/YYYY'),
        evergreenClosingDateConfig,
        deactivatedAt: dayjs(currentItem.deactivatedAt).format('DD/MM/YYYY'),
        dayToDeletion:
          currentItem.status === 'archived'
            ? 'NA'
            : Math.max(
                user.company.deactivatedPrograms_delay_before_deletion +
                  1 -
                  datesDiff(new Date(currentItem.deactivatedAt)),
                0
              ),
        next_session:
          currentItem && currentItem.next_session && currentItem.next_session.date
            ? dayjs(currentItem.next_session.date).format('DD/MM/YYYY')
            : '',
        expertLabel,
      };
      acc.push(modifiedItem);
      return acc;
    }, []);

  const createdAtColumn = {
    field: 'createdAt',
    headerName: 'creation_date',
    type: 'text',
    minWidth: 130,
    maxWidth: 150,
    resizable: true,
    flex: 1,
    filterOperators: DateFilterOperators,
    hideable: false,
  };

  const coacheeColumn = { field: 'coachee', headerName: 'coachee', type: 'user', minWidth: 200, flex: 1 };

  const themeColumn = {
    field: 'theme',
    headerName: 'theme',
    type: 'topic',
    minWidth: 150,
    filterOperators: TopicFilterOperators,
    flex: 1,
  };

  const columnsRh = [
    !disableColumnsRh.includes('deactivatedAt') && {
      field: 'deactivatedAt',
      headerName: 'deactivated_date',
      type: 'text',
      minWidth: 130,
      maxWidth: 150,
      resizable: true,
      flex: 1,
      filterOperators: DateFilterOperators,
      hideable: false,
    },
    { ...createdAtColumn },
    { ...coacheeColumn },
    !disableColumnsRh.includes('scopeMentee') && {
      field: 'scopeMentee',
      headerName: 'scopeMentee',
      type: 'smallText',
      minWidth: 130,
      flex: 1,
      translateHeaderName: false,
      filterOperators: ScopeFilterOperators,
    },
    { ...themeColumn },
    {
      field: 'coach',
      headerName: 'coach',
      type: 'coach',
      minWidth: 190,
      flex: 1,
    },
    !disableColumnsRh.includes('scopeMentor') && {
      field: 'scopeMentor',
      headerName: 'scopeMentor',
      type: 'smallText',
      minWidth: 150,
      translateHeaderName: false,
      flex: 1,
      filterOperators: ScopeFilterOperators,
    },
    !disableColumnsRh.includes('status') && {
      field: 'status',
      headerName: 'status',
      type: 'status',
      filterOperators: StatusFilterOperators,
      minWidth: 150,
      maxWidth: 170,
      flex: 1,
    },
    !disableColumnsRh.includes('progress') && {
      field: 'progress',
      headerName: 'progress',
      type: 'linearProgress',
      minWidth: 120,
      maxWidth: 160,
      sortable: true,
      filterable: false,
      flex: 1,
    },
    !disableColumnsRh.includes('daysToDeletion') && {
      field: 'dayToDeletion',
      headerName: 'days_to_deletion',
      type: 'text',
      minWidth: 130,
      maxWidth: 150,
      resizable: true,
      flex: 1,
      filterOperators: DateFilterOperators,
      hideable: false,
    },
    {
      field: 'actions',
      headerName: 'actions',
      sortable: false,
      minWidth: 130,
      filter: false,
      filterable: false,
      valueGetter: (params) => `${params.row.firstName || ''} ${params.row.lastName || ''}`,
      type: 'actionsProgramRh',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      hideable: false,
      resizable: true,
    },
  ].filter((x) => x);

  const evergreenColumns = [
    { ...createdAtColumn },
    { ...coacheeColumn },
    { ...themeColumn },
    {
      field: 'evergreenCoach1',
      headerName: 'evergreenCoach1',
      type: 'evergreenCoach',
      minWidth: 190,
      flex: 1,
      sortable: false,
      filterable: false,
    },
    {
      field: 'evergreenCoach2',
      headerName: 'evergreenCoach2',
      type: 'evergreenCoach',
      minWidth: 190,
      flex: 1,
      sortable: false,
      filterable: false,
    },
    {
      field: 'evergreenCoach3',
      headerName: 'evergreenCoach3',
      type: 'evergreenCoach',
      minWidth: 190,
      flex: 1,
      sortable: false,
      filterable: false,
    },
    {
      field: 'evergreenStatus',
      headerName: 'evergreen_status',
      type: 'evergreenStatus',
      minWidth: 190,
      flex: 1,
      sortable: false,
      filterable: false,
    },
    {
      field: 'evergreenClosingDateConfig',
      headerName: 'evergreen_closing_date',
      type: 'evergreenClosingDateConfig',
      minWidth: 150,
      maxWidth: 170,
      sortable: false,
      resizable: true,
      filterable: false,
    },
  ];

  const formatHeaderRh = (col) => {
    if (col.headerName === 'scopeMentee') {
      col.headerName = t(SCOPE) + ' ' + (user?.company?.clientLabel || '');
    }
    if (col.headerName === 'coachee') {
      col.headerName = user?.company?.clientLabel || '';
    }
    if (col.headerName === 'coach') {
      col.headerName = user?.company?.expertLabel || '';
    }
    if (col.headerName === 'scopeMentor') {
      col.headerName = t(SCOPE) + ` ${user?.company?.expertLabel || ''}`;
    }
    if (col.headerName.startsWith('evergreenCoach')) {
      col.headerName = `${user?.company?.expertLabel} ${col.headerName.replace('evergreenCoach', '')}` || '';
    }

    return { ...col };
  };

  const getColumns = () => {
    if (isActiveEvergreenColumns) {
      return evergreenColumns.map(formatHeaderRh).filter(Boolean);
    }

    switch (role) {
      case 'RH':
        return columnsRh.map(formatHeaderRh).filter(Boolean);
      case 'COACH':
        return columnsCoach.map((col) => {
          if (col.headerName === 'coachee') {
            col.headerName = user && user.company && user.company.clientLabel ? user.company.clientLabel : '';
          }
          return { ...col };
        });
      case 'COACHEE':
        return columnsCoachee.map((col) => {
          if (col.headerName === 'coach') {
            col.headerName = user && user.company && user.company.expertLabel ? user.company.expertLabel : '';
          }
          return { ...col };
        });

      default:
        break;
    }
  };
  // Coaching request
  const [isRequestCoachingOpen, setIsRequestCoachingOpen] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState({});

  // Deactivate request
  const [isDeactivateRequestOpen, setIsStatusRequestOpen] = useState(false);
  const [programForDeactivateRequest, setProgramForStatusRequest] = useState({});
  const [typeChangeStatusModal, setTypeChangeStatusModal] = useState('');

  const onDeactivate = (program) => {
    setTypeChangeStatusModal('deactivation');
    setIsStatusRequestOpen(true);
    setProgramForStatusRequest(program);
  };

  const onReactivate = (program) => {
    setTypeChangeStatusModal('reactivation');
    setIsStatusRequestOpen(true);
    setProgramForStatusRequest(program);
  };

  return (
    <Stack sx={{ height: '600px' }}>
      <DataGrid
        user={user}
        columns={getColumns()}
        rows={programs ? updateList(programs) : []}
        role={role}
        totalItems={totalItems}
        rowHeight={70}
        isDisablesRowClick={isDisablesRowClick}
        onDeactivate={onDeactivate}
        onReactivate={onReactivate}
        onRowClicked={(program, event) => {
          if (!event.defaultMuiPrevented) {
            navigate(`/program/${program.id}`);
          }
        }}
        onAnswerRequest={(program) => {
          setIsRequestCoachingOpen(true);
          setSelectedProgram(program);
        }}
        asCoachee={asCoachee}
        onFilter={handleOnFilter}
        onSort={handleOnSort}
        onPaginationChange={(page, nbrPages) => {
          setQueryParams({ ...queryParams, page: page, size: nbrPages });
        }}
        totalPages={totalPage ? totalPage : 0}
        loading={isLoading}
      />
      <CoachingRequest
        t={t}
        sessionLabel={user && user.company && user.company.sessionLabel ? user.company.sessionLabel : ''}
        expertLabel={user && user.company && user.company.expertLabel ? user.company.expertLabel : ''}
        program={selectedProgram}
        coach={user}
        onDecline={(guestId, programId, body) => {
          handleCoachingRequest(guestId, programId, body);
          setIsRequestCoachingOpen(false);
        }}
        onAccept={(guestId, programId, body) => {
          handleCoachingRequest(guestId, programId, body);
          setIsRequestCoachingOpen(false);
        }}
        isOpen={role == 'COACH' ? isRequestCoachingOpen : false}
        setOpen={setIsRequestCoachingOpen}
      />
      {isDeactivateRequestOpen && (
        <ChangeStatusRequest
          type={typeChangeStatusModal}
          isOpen={isDeactivateRequestOpen}
          program={programForDeactivateRequest}
          expertLabel={user.company.expertLabel}
          clientLabel={user.company.clientLabel}
          customerLabel={user.company.customerLabel}
          sessionLabel={user.company.sessionLabel}
          onAccept={({ programId, sendEmailToCoach, sendEmailToCoachee, isArchived }) => {
            if (typeChangeStatusModal === 'deactivation') {
              onDeactivateProgram({ programId, sendEmailToCoach, sendEmailToCoachee, isArchived });
            } else {
              onReactivateProgram({ programId, sendEmailToCoach, sendEmailToCoachee });
            }
            setIsStatusRequestOpen(false);
          }}
          onClose={() => {
            setIsStatusRequestOpen(false);
            setProgramForStatusRequest('');
          }}
        />
      )}
    </Stack>
  );
};

export const CoachCell = ({ coach }) => {
  const [modalOpened, setModalOpened] = useState(false);
  const onClick = (event) => {
    event.stopPropagation();
    coach?.id ? setModalOpened(true) : setModalOpened(false);
  };
  const handleClose = (event) => {
    event.stopPropagation();
    setModalOpened(false);
  };
  return (
    <>
      <CoachCardMini {...coach} onClick={onClick} sx={{ maxWidth: '12vw' }} />
      {coach && modalOpened && (
        <CoachViewModalWithRequest coachId={coach.id} open={modalOpened} onClose={handleClose} />
      )}
    </>
  );
};

export const ChooseCoachButtonCell = ({ programId }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <TableCell align="center">
      <Button
        sx={{
          whiteSpace: 'nowrap',
          minWidth: '200px',
          zIndex: 0,
          color: 'white',
          borderRadius: '4px',
          height: '42px',
        }}
        variant="primary"
        onClick={(e) => {
          e.stopPropagation();
          navigate(`/program/onboarding/${programId}`);
        }}>
        {t(LAUNCH)}
      </Button>
    </TableCell>
  );
};

export const RequestAnswerButtonCell = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <TableCell align="center">
      <Button
        sx={{
          whiteSpace: 'nowrap',
          width: '200px',
          zIndex: 0,
          backgroundColor: 'primary.main',
          color: 'white',
          borderRadius: '4px',
          height: '42px',
        }}
        variant="primary"
        onClick={onClick}>
        {t(ANSWER_REQUEST)}
      </Button>
    </TableCell>
  );
};

export const OpenProgramCell = () => {
  const { t } = useTranslation();
  return (
    <TableCell align="center">
      <Button variant="secondary" onClick={() => {}}>
        {t(OPEN_PROGRAM)}
      </Button>
    </TableCell>
  );
};

export default ProgramsTable;
