import { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import api from 'api';
import { useAlert } from 'common/alertContext';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

// Create
const useCreateProgramDocument = () => {
  const queryClient = useQueryClient();
  const { setStateAlert } = useAlert();
  const mutation = useMutation(api.Program_Documents.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-program-documents']);
      setStateAlert({ open: true, message: t('message_success_resource') });
    },
    onError: (error) => {
      setStateAlert({ open: true, message: error.response.data.error, type: 'error' });
    },
  });
  const submit = (payload) => {
    mutation.mutate(payload);
  };
  return {
    actions: { submit, isAddLoading: mutation.isLoading },
  };
};

// Delete
export const useDeleteProgramDocument = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(api.Program_Documents.delete, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-program-documents']);
    },
  });

  const remove = (id) => {
    mutation.mutate(id);
  };

  return {
    actions: { remove, isDelLoading: mutation.isLoading },
  };
};

// Update
export const useUpdateProgramDocument = () => {
  const queryClient = useQueryClient();
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [resource, setResource] = useState(null);
  const { setStateAlert } = useAlert();
  const { formState, handleSubmit, control, reset, resetField, setValue } = useForm({
    mode: 'all',
    defaultValues: { file: null },
  });

  const mutation = useMutation(api.Program_Documents.edit, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-program-documents']);
      if (formState.isSubmitted) setShowEditDialog(false);
    },
  });

  const edit = (id, data) => {
    mutation.mutate({ id: id, payload: data });
  };

  const clickUpdate = (obj) => {
    setValue('filename', obj.filename);
    setValue('type', obj.type);
    setValue('language', obj.language);
    setValue('filepath', obj.filepath);
    setShowEditDialog(true);
    setResource(obj);
  };

  const close = () => {
    setShowEditDialog(false);
    reset();
  };

  return {
    state: { form: { control }, nav: { showEditDialog } },
    actions: { edit, clickUpdate, close, isEditLoading: mutation.isLoading },
    formState,
  };
};

// Upload file for edit
export const useUploadDocumentUpdate = (programId) => {
  const { setStateAlert } = useAlert();
  const { t } = useTranslation();
  const [showEditDialog, setShowEditDialog] = useState(false);

  const { formState, handleSubmit, control, reset, resetField, setValue } = useForm({
    mode: 'all',
    defaultValues: { file: null },
  });
  const [showDialog, setShowDialog] = useState(false);
  const [showResourceDialog, setShowResourceDialog] = useState(false);
  const [resource, setResource] = useState(null);
  const [id, setId] = useState('');

  const { actions: programActions } = useUpdateProgramDocument();

  const mutation = useMutation(api.Users.upload, {
    onSuccess: (data) => {
      const parts = data.url.Key.split('/');
      const fileName = parts[parts.length - 1];
      const payload = {
        filename: fileName,
        filepath: data.url.Location,
        key: data.url.Key,
        programId: programId,
        language: resource.language ? resource.language : 'english',
        type: resource.type ? resource.type : 'Document',
        picture: resource.picture ? resource.picture : '',
      };
      if (formState.isSubmitted) setShowEditDialog(false);
      programActions.edit(id, payload);
      reset({ file: null });
      setShowResourceDialog(false);
    },
    onError: (error) => {
      setShowResourceDialog(false);
    },
  });

  const edit = handleSubmit((data) => {
    setResource(data);
    if (data.file) {
      mutation.mutate(data.file);
    } else {
      programActions.edit(id, data);
      setShowEditDialog(false);
    }
  });

  const close = () => {
    setShowDialog(false);
    setShowEditDialog(false);
    reset();
  };

  const clickUpdate = (obj) => {
    setId(obj.id);
    setValue('filename', obj.filename);
    setValue('type', obj.type);
    setValue('language', obj.language);
    setValue('filepath', obj.filepath);
    setShowEditDialog(true);
  };
  const handleShowDialog = () => {
    reset();
    setShowEditDialog(true);
  };

  return {
    state: { form: { control }, nav: { showDialog, showResourceDialog, showEditDialog } },
    actions: { close, edit, resetField, clickUpdate, handleShowDialog, isEditLoading: programActions.isEditLoading },
    formState,
  };
};

// Upload file
export const useUploadDocument = (programId) => {
  const { setStateAlert } = useAlert();

  const { formState, handleSubmit, control, reset, resetField } = useForm({
    mode: 'all',
    defaultValues: { file: null },
  });
  const [showDialog, setShowDialog] = useState(false);
  const [showResourceDialog, setShowResourceDialog] = useState(false);
  const [resource, setResource] = useState(null);
  const { actions: programActions } = useCreateProgramDocument();

  const mutation = useMutation(api.Users.upload, {
    onSuccess: (data) => {
      const parts = data.url.Key.split('/');
      const fileName = parts[parts.length - 1];

      const payload = {
        filename: resource.filename ? resource.filename : fileName,
        filepath: resource.filepath ? resource.filepath : data.url.Location,
        key: data.url.Key,
        programId: programId,
        language: resource.language ? resource.language : 'english',
        type: resource.type ? resource.type : 'Document',
        picture: resource.picture ? resource.picture : '',
      };
      programActions.submit(payload);
      reset({ file: null });
      setShowResourceDialog(false);
    },
    onError: (error) => {
      setShowResourceDialog(false);
    },
  });

  const submit = handleSubmit((data) => {
    setResource(data);
    // Check if data has file then upload file else only create document
    if (data.file) {
      mutation.mutate(data.file);
    } else {
      const { file, ...payload } = data;
      if (payload.filepath.length > 0) {
        const newPayload = { ...payload, ...{ programId } };
        programActions.submit(newPayload);
        setShowResourceDialog(false);
      } else {
        setStateAlert({
          open: true,
          message: 'Resource must have a file or a link',
          type: 'error',
        });
      }
    }
  });

  const close = () => {
    setShowDialog(false);
    setShowResourceDialog(false);
    reset();
  };

  const clickAdd = () => setShowDialog(true);
  const handleShowDialog = () => {
    reset();
    setShowResourceDialog(true);
  };

  return {
    state: { form: { control }, nav: { showDialog, showResourceDialog } },
    actions: { close, submit, resetField, clickAdd, handleShowDialog, isAddLoading: programActions.isAddLoading },
    formState,
  };
};

export default useCreateProgramDocument;
