import theme from 'theme';

const styles = {
  container: {
    position: 'relative',
    overflowY: 'auto',
    overflowX: 'hidden',
    borderRadius: '4px',
    maxHeight: '430px',
    maxWidth: '368px',
    minWidth: '290px',
    boxSizing: 'border-box',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'white',
      borderColor: 'primary.main',
    },
    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      width: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '25px',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      '-webkit-box-shadow': '0 0 1px rgba(255, 255, 255, 0.5)',
    },
    border: '1px solid',
    borderColor: '#d4d4d4',
  },
  containerSelected: {
    borderColor: 'primary.main',
    border: '2px solid',
    backgroundColor: 'card.selected',
    '&:hover': {
      backgroundColor: 'card.selected',
    },
  },
  picture: {
    width: '100%',
    height: '200px',
    objectFit: 'cover',
  },
};
export default styles;
