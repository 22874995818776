import React, { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import Button from 'ui/components/Button/Button';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTranslation } from 'react-i18next';
import { useListEmailsWithName } from 'hooks/queries';
import Tooltip from '@mui/material/Tooltip';

import styles from './styles';
import {
  ACTIVATED,
  DESACTIVATED,
  EMAIL,
  EMAIL_TEMPLATE_NOT_FOUND,
  MINIMUM_NUMBER_OF_SESSIONS,
  PROGRAM_CREATION_STEP4_TEXT1,
  PROGRAM_CREATION_STEP4_TEXT2,
  PROGRAM_CREATION_STEP4_TEXT3,
  PROGRAM_CREATION_STEP4_TEXT_SWITCH,
  THEME,
  VIEW,
} from 'litterals';
import CopyButton from 'ui/components/CopyButton';
import PreviewEmail from './Modals';
import { useAlert } from 'common/alertContext';

//Icons
import MailOutlineIcon from '@mui/icons-material/MailOutline';

// Helpers

import {
  variablesReplacementContent,
  variablesReplacementCoachInformations,
  variablesReplacementCoacheeInformations,
} from './helpers/email-templates-functions';

import {
  COACH_PREVIEW_EMAIL_TEMPLATE_NAME,
  COACHEE_PREVIEW_EMAIL_TEMPLATE_NAME,
  LEADER_PREVIEW_EMAIL_TEMPLATE_NAME,
} from './constants/email-templates-constants';

const Confirmations = ({
  actions,
  state,
  expertLabel,
  clientLabel,
  themeName,
  nbSessions,
  coachee = { email: '', firstname: '', lastname: '', name: '' },
  coaches,
  rh,
}) => {
  const { t } = useTranslation();

  const [templateEmailNameSelected, setTemplateEmailNameSelected] = useState(null);
  const [emailTemplateDataSelected, setEmailTemplateDataSelected] = useState('');
  const [isEmailTemplateOpen, setIsEmailTemplateOpen] = useState(false);

  const {
    data: dataEmailTemplate,
    isLoading: isLoadingEmailTemplate,
    refetch,
  } = useListEmailsWithName(templateEmailNameSelected);
  const { setStateAlert } = useAlert();

  // Constants
  const LEADER_LABEL = 'Leader';
  const hasLeader = coachee && !!coachee.managerEmail;

  const onShowTemplateClick = (templateName) => {
    setTemplateEmailNameSelected(templateName);
    refetch();
  };

  const clientEmailTemplateEmail = () => {
    if (templateEmailNameSelected === COACH_PREVIEW_EMAIL_TEMPLATE_NAME) {
      return coaches && coaches.length == 1 ? coaches[0].email : expertLabel + ' ' + t(EMAIL);
    }

    if (templateEmailNameSelected === LEADER_PREVIEW_EMAIL_TEMPLATE_NAME) {
      return coachee.managerEmail;
    }

    if (templateEmailNameSelected === COACHEE_PREVIEW_EMAIL_TEMPLATE_NAME) {
      return coachee.email;
    }
  };

  const titleTemplateEmail = () => {
    if (templateEmailNameSelected === COACH_PREVIEW_EMAIL_TEMPLATE_NAME) {
      return expertLabel;
    }

    if (templateEmailNameSelected === LEADER_PREVIEW_EMAIL_TEMPLATE_NAME) {
      return LEADER_LABEL;
    }

    if (templateEmailNameSelected === COACHEE_PREVIEW_EMAIL_TEMPLATE_NAME) {
      return clientLabel;
    }
  };

  const contentTemplateEmail = () => {
    if (templateEmailNameSelected === COACH_PREVIEW_EMAIL_TEMPLATE_NAME) {
      return emailTemplateDataSelected && coaches && coaches.length == 1
        ? variablesReplacementContent(
            emailTemplateDataSelected ? emailTemplateDataSelected.content : '',
            variablesReplacementCoachInformations(coaches[0], rh, themeName)
          )
        : emailTemplateDataSelected.content;
    }

    if (templateEmailNameSelected === LEADER_PREVIEW_EMAIL_TEMPLATE_NAME) {
      return emailTemplateDataSelected
        ? variablesReplacementContent(
            emailTemplateDataSelected ? emailTemplateDataSelected.content : '',
            variablesReplacementCoacheeInformations(coachee, rh, themeName)
          )
        : '';
    }

    if (templateEmailNameSelected === COACHEE_PREVIEW_EMAIL_TEMPLATE_NAME) {
      return emailTemplateDataSelected
        ? variablesReplacementContent(
            emailTemplateDataSelected ? emailTemplateDataSelected.content : '',
            variablesReplacementCoacheeInformations(coachee, rh, themeName)
          )
        : '';
    }
  };

  useEffect(() => {
    if (!templateEmailNameSelected || isLoadingEmailTemplate) return;

    if (dataEmailTemplate.id) {
      setIsEmailTemplateOpen(true);
      setEmailTemplateDataSelected(dataEmailTemplate);
    } else {
      setStateAlert({
        open: true,
        message: t(EMAIL_TEMPLATE_NOT_FOUND),
        type: 'error',
      });
    }
  }, [dataEmailTemplate, isLoadingEmailTemplate]);

  return (
    <Stack sx={styles.step4.container}>
      {/**TEXT 1 */}
      <Box sx={styles.step4.row}>
        <Typography sx={styles.step4.text}>{t(PROGRAM_CREATION_STEP4_TEXT1)}</Typography>
      </Box>
      {/**THEME */}
      <Box sx={styles.step4.row}>
        <Typography sx={styles.step4.title}>{t(THEME)} : </Typography>
        <Box sx={{ width: '5px' }} />
        <Typography sx={styles.step4.text}>{themeName} </Typography>
      </Box>
      {/**NUMBER OF SESSIONS */}
      <Box sx={styles.step4.row}>
        <Typography sx={styles.step4.title}>{t(MINIMUM_NUMBER_OF_SESSIONS)} : </Typography>
        <Box sx={{ width: '5px' }} />
        <Typography sx={styles.step4.text}>{nbSessions} </Typography>
      </Box>
      {/**COACHEE TEXT */}
      <Box sx={styles.step4.rowCoacheeWithCopyBtn}>
        <Typography sx={styles.step4.title}>{clientLabel} : </Typography>
        <Box sx={{ width: '5px' }} />
        {coachee && (
          <Typography sx={styles.step4.text}>
            {`${coachee.name ? coachee.name : coachee.firstname + ' ' + coachee.lastname} (${coachee.email}) `}
            <CopyButton data={coachee.email} />
          </Typography>
        )}
      </Box>
      {/**EXPERT TEXT */}
      <Box sx={styles.step4.rowWithCopyBtn}>
        {coaches && coaches.length == 1 ? (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={styles.step4.title}>{expertLabel} : </Typography>
            <Box sx={{ width: '5px' }} />
            <Typography sx={styles.step4.text}>
              {`${coaches[0].firstname} ${coaches[0].lastname} (${coaches[0].email}) `}
              <CopyButton data={coaches[0].email} />
            </Typography>
          </Box>
        ) : (
          <Box>
            <Box sx={styles.step4.rowWithCopyBtn}>
              <Typography sx={styles.step4.title}>{expertLabel} : </Typography>
              <Box sx={{ width: '5px' }} />
              <Typography sx={styles.step4.title}>{t(PROGRAM_CREATION_STEP4_TEXT2)}</Typography>
              <Box sx={{ width: '5px' }} />
              <Typography sx={styles.step4.title}>{clientLabel} : </Typography>
            </Box>
            <ul style={{ margin: '0px' }}>
              {coaches
                ? coaches.map((coach) => (
                    <li key={coach.id}>
                      <Typography sx={styles.step4.text}>
                        {`${coach.firstname} ${coach.lastname} (${coach.email})`}
                        <CopyButton data={coach.email} />
                      </Typography>
                    </li>
                  ))
                : ''}
            </ul>
          </Box>
        )}
      </Box>
      <Box sx={{ height: '12px' }} />
      {/**TEXT 3 */}
      <Box sx={styles.step4.row}>
        <Typography sx={styles.step4.text}>{t(PROGRAM_CREATION_STEP4_TEXT3)}</Typography>
      </Box>
      <Box sx={{ height: '8px' }} />
      {/** Switch COACH */}
      <MailSettings
        buttonLabel={t('program_deactivate_expert_send_email_button', { expertLabel })}
        switchLabel={`${t(PROGRAM_CREATION_STEP4_TEXT_SWITCH)} ${expertLabel} ${
          state.sendEmailToCoach ? t(ACTIVATED).toLowerCase() : t(DESACTIVATED).toLowerCase()
        }`}
        switchValue={state.sendEmailToCoach}
        onShowTemplateClick={() => onShowTemplateClick(COACH_PREVIEW_EMAIL_TEMPLATE_NAME)}
        onSwitch={(e) => actions.setSendEmailToCoach(e.target.checked)}
      />
      <Box sx={{ height: '35px' }} />
      {/** Switch COACHEE */}
      <MailSettings
        buttonLabel={t('program_deactivate_client_send_email_button', { clientLabel })}
        switchLabel={`${t(PROGRAM_CREATION_STEP4_TEXT_SWITCH)} ${clientLabel} ${
          state.sendEmailToCoachee ? t(ACTIVATED).toLowerCase() : t(DESACTIVATED).toLowerCase()
        }`}
        switchValue={state.sendEmailToCoachee}
        onShowTemplateClick={() => onShowTemplateClick(COACHEE_PREVIEW_EMAIL_TEMPLATE_NAME)}
        onSwitch={(e) => actions.setSendEmailToCoachee(e.target.checked)}
      />
      <Box sx={{ height: '35px' }} />
      {/** Switch LEADER */}
      {hasLeader && (
        <MailSettings
          buttonLabel={t(VIEW) + ` ${LEADER_LABEL} ` + t(EMAIL)}
          switchLabel={`${t(PROGRAM_CREATION_STEP4_TEXT_SWITCH)} ${LEADER_LABEL} ${
            state.sendEmailToCoachee ? t(ACTIVATED).toLowerCase() : t(DESACTIVATED).toLowerCase()
          }`}
          switchTooltip={t('switch_tooltip_leader_create_program', { clientLabel })}
          switchValue={state.sendEmailToCoachee}
          switchDisabled
          onShowTemplateClick={() => onShowTemplateClick(LEADER_PREVIEW_EMAIL_TEMPLATE_NAME)}
        />
      )}
      <Box sx={{ height: '35px' }} />
      {/** Preview email : COACH */}
      <PreviewEmail
        title={titleTemplateEmail()}
        clientEmail={clientEmailTemplateEmail()}
        isOpen={isEmailTemplateOpen}
        setOpen={(valid) => {
          setIsEmailTemplateOpen(valid);

          if (!valid) {
            setEmailTemplateDataSelected('');
            setTemplateEmailNameSelected(null);
          }
        }}
        templateName={setTemplateEmailNameSelected}
        content={contentTemplateEmail()}
        fromName={emailTemplateDataSelected ? emailTemplateDataSelected.fromName : ''}
        subject={emailTemplateDataSelected ? emailTemplateDataSelected.subject : ''}
      />
    </Stack>
  );
};

const MailSettings = ({
  buttonLabel,
  switchLabel,
  switchValue,
  onShowTemplateClick,
  onSwitch,
  switchTooltip,
  switchDisabled = false,
}) => {
  return (
    <Box sx={styles.step4.row}>
      <Box sx={{ display: 'flex', minWidth: '250px' }}>
        <Button variant="secondary" onClick={onShowTemplateClick} endIcon={<MailOutlineIcon sx={{ height: '20px' }} />}>
          {buttonLabel}
        </Button>
      </Box>
      <Box sx={{ width: '19px' }} />

      <Tooltip title={switchTooltip}>
        <FormControlLabel
          control={<Switch onChange={onSwitch} checked={switchValue} disabled={switchDisabled} />}
          label={switchLabel}
        />
      </Tooltip>
    </Box>
  );
};

export default Confirmations;
