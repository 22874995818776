/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import api from 'api';
import { useLocation } from 'react-router-dom';
import { useAlert } from 'common/alertContext';
import { useParams } from 'react-router-dom';

export function useApplication(ref) {
  const {
    handleSubmit,
    control,
    setError,
    reset,
    setFocus,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: {
      /** Profile Info */
      firstname: '',
      lastname: '',
      email: '',
      phoneNumber: '',
      linkedinUrl: '',
      idNumber: '',

      /** Matching info */
      location: '',
      scopeId: '',
      seniorityLevelId: '',
      gender: '',
      languages: [],
      departmentId: '',

      /** Role */
      isMentor: false,

      /** Is Mentor */
      isInternal: '',

      /** Max Capacity */
      maxCapacity: 1,

      /** Expertises */
      coachingSkills: '',

      /** Expectations */
      expectations: '',

      /** {{expertLabel}} Profile Info */
      title: '',
      calendarLink: '',
      videoLink: '',
      descriptionExperience: '',
      hoursOfExperience: '',

      /** Picture */
      picture: '',

      /** {{clientLabel}} Profile Info */
      targetList: '',
    },
  });
  const [scopesList, setScopesList] = useState([]);
  const [company, setCompany] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [customError, setCustomError] = useState(null);
  const [cacheBuster, setCacheBuster] = useState(Date.now());

  const [isApplied, setIsApplied] = useState(false);
  const [isLoadingApply, setIsLoadingApply] = useState(false);
  const { setStateAlert, stateAlert } = useAlert();
  const queryClient = useQueryClient();
  const { selfRegistration, themeId } = useParams();

  // Query params
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const applicationLink = queryParams.get('applicationLink');

  const mutation = useMutation(api.Application.create, {
    onSuccess: () => {
      setIsApplied(true);
      setTimeout(() => {
        setIsLoadingApply(false);
      }, 2000);
      reset();
    },
    onError: (error) => {
      setTimeout(() => {
        setIsLoadingApply(false);
      }, 2000);

      setStateAlert({ open: true, message: `${error.response.data.error}`, type: 'error' });
    },
  });

  const mutateGetScopesList = useMutation(api.Application.getScopesList, {
    onSuccess: (data) => {
      setScopesList(data);
      setIsLoading(false);
    },
    onError: (error) => setStateAlert({ open: true, message: `${error.response.data.error}`, type: 'error' }),
  });

  const mutateGetCompany = useMutation(api.Application.getCompany, {
    onSuccess: (data) => {
      setCompany(data);
      setIsLoading(false);
    },
    onError: (error) => setStateAlert({ open: true, message: `${error.response.data.error}`, type: 'error' }),
  });

  useEffect(() => {
    mutateGetScopesList.mutate(applicationLink);
    mutateGetCompany.mutate(applicationLink);
  }, []);

  const handleSubmitApplication = handleSubmit((data) => {
    // console.log(data);
    // data = removeEmptyFields(data);
    // console.log(data);

    setIsLoadingApply(true);
    const newLanguages = data.languages.map((lang) => lang.label);

    const {
      phoneNumber,
      expectations1,
      expectations2,
      firstname,
      lastname,
      email,
      linkedinUrl,
      idNumber,
      location,
      scopeId,
      seniorityLevelId,
      gender,
      languages,
      departmentId,
      title,
      calendarLink,
      videoLink,
      descriptionExperience,
      picture,
      maxCapacity,
      coachingSkills,
      targetList,
      leaderEmail,
      ...body
    } = data;

    data = { ...data, maxCapacity: Number(data.maxCapacity) };

    [
      'firstname',
      'lastname',
      'email',
      'linkedinUrl',
      'idNumber',
      'location',
      'scopeId',
      'seniorityLevelId',
      'gender',
      'languages',
      'departmentId',
    ].forEach((field) => (body[field] = data[field]));
    body.applicationLink = applicationLink;
    body.isMentor = String(body.isMentor).toLowerCase() === 'true';
    body.isInternal = String(body.isInternal).toLowerCase() === 'internal';

    if (phoneNumber && phoneNumber.length > 4) {
      body.phoneNumber = phoneNumber;
    }

    if (String(body.isMentor).toLowerCase() === 'true') {
      [
        'maxCapacity',
        'targetList',
        'title',
        'calendarLink',
        'videoLink',
        'descriptionExperience',
        'hoursOfExperience',
        'picture',
      ].forEach((field) => (body[field] = data[field]));

      body.expectations = expectations1;
    } else {
      body.expectations = expectations2;
      body.targetList = targetList;
      body.leaderEmail = leaderEmail;
    }

    if (selfRegistration && themeId) {
      body.selfRegistrationCode = selfRegistration;
      body.selfRegistrationTheme = themeId;
    }

    mutation.mutate(body);
  });

  const onUploadPicture = async (data) => {
    const pictureData = watch('picture');

    if (!!pictureData && typeof pictureData !== 'string') {
      const { url } = await api.Users.uploadpublic(pictureData);
      setValue('picture', process.env.REACT_APP_LINK_BUCKET_SCALEWAY + url.Key);
    }
  };

  const updateImage = () => {
    setCacheBuster(Date.now());
    queryClient.invalidateQueries(['get-coach']);
  };

  return {
    state: {
      form: {
        control,
        errors,
        setError,
        isApplied,
        setFocus,
        isLoadingApply,
        stateAlert,
        setStateAlert,
        clearErrors,
        reset,
        setValue,
      },
      scopes: { isLoading, list: scopesList },
      companyData: { isLoading, company },
    },
    actions: { handleSubmitApplication, watch, onUploadPicture, updateImage },
  };
}
