import useStore from 'store';
import { useForm } from 'react-hook-form';
import { useListCoachesEvergreenWithParams, useListCoachesSelfRegistrationWithParams } from 'hooks/queries';

const useChooseExpert = (linkCode, queryParams, typeRequest = 'evergreen') => {
  const { coachee } = useStore();

  const { control } = useForm({
    mode: 'all',
    defaultValues: {
      name: '',
      hasTerms: '',
      linkTerms: '',
      documentationCollaborator: '',
      documentationExpert: '',
      feedbackLink: '',
    },
  });

  const request =
    typeRequest === 'evergreen' ? useListCoachesEvergreenWithParams : useListCoachesSelfRegistrationWithParams;

  const { data, isLoading, isFetched } = request(linkCode, queryParams, {
    coacheeLanguages: coachee && coachee.languages ? coachee.languages : [],
    coacheeSkills: coachee && coachee.targetList ? coachee.targetList : [],
    coacheeScope: coachee && coachee.languages ? coachee.scopeId : [],
  });

  return {
    control,
    coaches: data && data.rows ? data.rows : data,
    totalPages: data && data.totalPages ? data.totalPages : 1,
    currentPage: data && data.currentPage ? data.currentPage : 1,
    totalItems: data && data.totalItems ? data.totalItems : 0,
    isLoadingCoaches: isLoading,
    isFetched,
  };
};

export { useChooseExpert };
