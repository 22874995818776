import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ViewModal from 'ui/components/shared/ViewModal';
import EmailField from 'ui/components/form/EmailField';
import Button from '@mui/material/Button';
import styles from './EmailCompanyModal.styles';

export default function EmailCompanyModal({ control, open, onClose, onSubmit }) {
  const { t } = useTranslation();

  return (
    <ViewModal open={open} onClose={onClose} customStyleBox={styles.container}>
      <Box>
        <Box>
          <Typography variant="h1" sx={styles.title}>
            {t('email_company_modal_title')}
          </Typography>
          <Typography variant="h3" sx={styles.subTitle}>
            {t('email_company_modal_subtile')}
          </Typography>
        </Box>

        <EmailField
          required
          name="email"
          label=""
          placeholder="hannah.green@test.com"
          InputLabelProps={{
            shrink: true,
          }}
          control={control}
          sx={styles.emailField}
        />

        <Box sx={styles.containerButtons}>
          <Button
            type="submit"
            onClick={() => {
              onSubmit();
              onClose();
            }}
            variant="contained">
            {t('email_company_modal_submit')}
          </Button>
        </Box>
      </Box>
    </ViewModal>
  );
}
