import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import api from 'api';
import { useAlert } from 'common/alertContext';
import { useListSeniorityLevelMentorsQuery, useListSeniorityLevelsQueryWithParams } from 'hooks/queries'; // Assuming you have a query hook for listing useSeniorityLevels

const useSeniorityLevels = (companyId, queryParams) => {
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [editingSeniorityLevel, setEditingSeniorityLevel] = useState(null);
  const [defaultSeniorityLevelOrder, setDefaultSeniorityLevelOrder] = useState(0);

  const { handleSubmit, control, watch, getValues, setValue, reset } = useForm({
    mode: 'all',
  });
  const queryClient = useQueryClient();
  const { setStateAlert } = useAlert();

  const { data: seniorityLevelList, isLoading } = useListSeniorityLevelsQueryWithParams(queryParams);
  const { data: seniorityLevelMentorList, isLoading: isLoadingMentor } = useListSeniorityLevelMentorsQuery();

  useEffect(() => {
    if (seniorityLevelList && seniorityLevelList.rows.length > 0) {
      const maxOrder = Math.max(...seniorityLevelList.rows.map((obj) => obj.order)) + 1;
      setDefaultSeniorityLevelOrder(maxOrder);
    } else {
      setDefaultSeniorityLevelOrder(1);
    }
  }, [seniorityLevelList]);

  const mutationDelete = useMutation(api.SeniorityLevels.delete, {
    onSuccess: () => {
      queryClient.invalidateQueries(['list-seniorityLevels-params']);
      setStateAlert({ open: true, message: 'Seniority Level successfully deleted' });

      if (seniorityLevelList.rows.length >= 2) {
        setDefaultSeniorityLevelOrder(defaultSeniorityLevelOrder - 1);
      } else {
        setDefaultSeniorityLevelOrder(1);
      }
    },
    onError: (error) => setStateAlert({ open: true, message: `${error.response.data.error}`, type: 'error' }),
  });

  const onRemoveSeniorityLevel = (id) => {
    mutationDelete.mutate(id);
  };

  const mutationAdd = useMutation(api.SeniorityLevels.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(['list-seniorityLevels-params']);
      setStateAlert({ open: true, message: 'Seniority Level successfully created' });
      setIsOpenAddModal(false);
      setDefaultSeniorityLevelOrder(defaultSeniorityLevelOrder + 1);
      reset();
    },
    onError: (error) => setStateAlert({ open: true, message: `${error.response.data.error}`, type: 'error' }),
  });

  const onAddSeniorityLevel = handleSubmit(async (data) => {
    let quary;
    if (!companyId) {
      quary = { ...data };
    } else {
      quary = { ...data, companyId: companyId };
    }
    mutationAdd.mutate(quary);
  });

  const mutationEdit = useMutation(api.SeniorityLevels.edit, {
    onSuccess: () => {
      queryClient.invalidateQueries(['list-seniorityLevels-params']);
      setStateAlert({ open: true, message: 'Seniority Level successfully edited' });
      setIsOpenEditModal(false);
      reset();
    },
    onError: (error) => setStateAlert({ open: true, message: `${error.response.data.error}`, type: 'error' }),
  });

  useEffect(() => {
    if (editingSeniorityLevel && !isOpenEditModal) {
      setValue('name', editingSeniorityLevel.name);
      setValue('order', editingSeniorityLevel.order);
      setIsOpenEditModal(true);
    }
  }, [editingSeniorityLevel]);

  useEffect(() => {
    if (isOpenAddModal) {
      setValue('name', '');
      setValue('order', defaultSeniorityLevelOrder);
    }
  }, [isOpenAddModal]);

  const onEditSeniorityLevel = handleSubmit(async (data) => {
    let quary;
    if (!companyId) {
      quary = { ...data };
    } else {
      quary = { ...data, companyId: companyId };
    }
    mutationEdit.mutate({ id: editingSeniorityLevel.id, data: quary });
  });

  return {
    isEditLoading: mutationEdit.isLoading,
    isAddLoading: mutationAdd.isLoading,
    isLoading: isLoading,
    seniorityLevels: seniorityLevelList,
    seniorityLevelMentor: {
      seniorityLevels: seniorityLevelMentorList,
      isLoading: isLoadingMentor,
    },
    totalItems: seniorityLevelList && seniorityLevelList.totalItems,
    totalPages: seniorityLevelList && seniorityLevelList.totalPages,
    control,
    watch,
    reset,
    getValues,
    onRemoveSeniorityLevel,
    onAddSeniorityLevel,
    isOpenAddModal,
    setIsOpenAddModal,
    onEditSeniorityLevel,
    setIsOpenEditModal,
    isOpenEditModal,
    setEditingSeniorityLevel,
  };
};

export default useSeniorityLevels;
