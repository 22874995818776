import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'ui/components/system/Box';
import MuiTextField from '@mui/material/TextField';
import {
  Button,
  InputAdornment,
  IconButton,
  Tooltip,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Stack,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useListFormatThemeQuery, useSelfRegistrationCode } from 'hooks/queries';
import { getUrlSelfRegistrationLink } from 'utils/company';
import Otp from 'ui/components/Otp/Otp';
import CopyButton from 'ui/components/CopyButton';
import clipboardCopy from 'clipboard-copy';
import { useAlert } from 'common/alertContext';

const SelfRegistration = ({
  selfRegistrationLinkCode,
  applicationLinkCode,
  companyId,
  activeClipboardCopy = false,
}) => {
  const { t } = useTranslation();
  const { setStateAlert } = useAlert();
  const [selfRegistrationLink, setSelfRegistrationLink] = useState('');
  const [themeIdSelected, setThemeIdSelected] = useState('');
  const [showCode, setShowCode] = useState(false);

  const { data: listThemeData, isLoading } = useListFormatThemeQuery(companyId);
  const { data: selfRegistrationCode } = useSelfRegistrationCode(companyId, false);

  if (isLoading) return <p>Loading...</p>;

  const styleInput = { maxWidth: '500px', shrink: 'true', textTransform: 'capitalize', width: '100%' };

  const onChangeTheme = (themeId) => {
    setThemeIdSelected(themeId);
    setSelfRegistrationLink(getUrlSelfRegistrationLink(selfRegistrationLinkCode, applicationLinkCode, themeId));
  };

  return (
    <Box component={'form'} display={'flex'} flexDirection={'column'} gap={'20px'} sx={{ marginTop: '32px' }}>
      <FormControl fullWidth>
        <InputLabel id="self-registration-theme-label">{t('self_registration_theme_label')}</InputLabel>
        <Select
          labelId="self-registration-theme-label"
          sx={styleInput}
          value={themeIdSelected}
          label={t('self_registration_theme_label')}
          onChange={(event) => onChangeTheme(event.target.value)}>
          {listThemeData.map(({ id, name }) => {
            return (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <Stack direction={'row'}>
        <MuiTextField
          value={selfRegistrationLink}
          label={t('self_registration_link_generated')}
          placeholder=""
          type="text"
          style={styleInput}
          inputProps={{ readOnly: true }}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title={t('self_registration_link_tooltip')}>
                  <IconButton edge="end">
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />

        {activeClipboardCopy && (
          <Button
            disabled={!selfRegistrationLink}
            onClick={() => {
              clipboardCopy(selfRegistrationLink);
              setStateAlert({ open: true, message: t('invitation_link_copied') });
            }}
            variant="outlined">
            {t('copy')}
          </Button>
        )}
      </Stack>
      {false && (
        <Box sx={{ display: 'flex' }}>
          <Otp
            value={selfRegistrationCode}
            length={selfRegistrationCode.length}
            type={showCode ? 'text' : 'password'}
            onChange={() => {}}
          />
          <CopyButton
            data={selfRegistrationCode}
            message={t('code_copied')}
            sx={{ marginLeft: '6px' }}
            onMouseEnter={() => setShowCode(true)}
            onMouseLeave={() => setShowCode(false)}
          />
          <Tooltip title={t('self_registration_code_tooltip')}>
            <IconButton edge="end">
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
};

export default SelfRegistration;
