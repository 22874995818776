import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import api from 'api';
import { useAlert } from 'common/alertContext';
import { useListScopeQuery } from 'hooks/queries';

const useScopes = (companyId) => {
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [editingScope, setEditingScope] = useState(null);

  const { handleSubmit, control, watch, getValues, setValue, reset } = useForm({
    mode: 'all',
  });
  const queryClient = useQueryClient();
  const { setStateAlert } = useAlert();

  const { data: scopeList, isLoading } = useListScopeQuery({});

  const mutationDelete = useMutation(api.Scopes.delete, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['list-scopes']);
      setStateAlert({ open: true, message: 'scope successfully deleted' });
    },
    onError: (error) => setStateAlert({ open: true, message: `${error.response.data.error}` }),
  });

  const onRemoveScope = (id) => {
    console.log(id);
    mutationDelete.mutate(id);
  };

  const mutationAdd = useMutation(api.Scopes.create, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['list-scopes']);
      setStateAlert({ open: true, message: 'scope successfully created' });
      setIsOpenAddModal(false);
      reset();
    },
    onError: (error) => setStateAlert({ open: true, message: `${error.response.data.error}` }),
  });

  const onAddScope = handleSubmit(async (data) => {
    mutationAdd.mutate({ ...data, companyId: companyId });
  });

  const mutationEdit = useMutation(api.Scopes.edit, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['list-scopes']);
      setStateAlert({ open: true, message: 'scope successfully edited' });
      setIsOpenEditModal(false);
      reset();
    },
    onError: (error) => setStateAlert({ open: true, message: `${error.response.data.error}` }),
  });

  useEffect(() => {
    if (editingScope) {
      setValue('name', editingScope.name);
      setIsOpenEditModal(true);
    }
  }, [editingScope]);

  const onEditScope = handleSubmit(async (data) => {
    mutationEdit.mutate({ id: editingScope.id, data: { ...data, companyId: companyId } });
  });

  return {
    isEditLoading: mutationEdit.isLoading,
    isAddLoading: mutationAdd.isLoading,
    isLoading: isLoading,
    scopes: scopeList,
    control,
    watch,
    reset,
    getValues,
    onRemoveScope,
    onAddScope,
    isOpenAddModal,
    setIsOpenAddModal,
    setEditingScope,
    setIsOpenEditModal,
    isOpenEditModal,
    onEditScope,
  };
};

export default useScopes;
