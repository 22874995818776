/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Lib dependencies
import React from 'react';

// UI lib dependencies;
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

// Icons
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteIcon from '@mui/icons-material/Delete';

import EditIcon from '@mui/icons-material/Edit';

// Navigation
import { useNavigate } from 'react-router-dom';

// Style
import style from './styles';
import { useAlert } from 'common/alertContext';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function CellActionsSimple({ rowData, role, onDelete, onEdit }) {
  /* ********************************** HOOKS ********************************* */
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const { setStateAlert, stateAlert } = useAlert();
  /* ********************************** FUNCTIONS ********************************* */

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickDelete = () => {
    onDelete(rowData);
    setAnchorEl(false);
  };

  /* ******************************** RENDERING ******************************* */
  return (
    <Stack>
      <IconButton onClick={handleClick} size="small">
        <SettingsIcon fontSize="inherit" />
      </IconButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        <MenuItem
          onClick={() => {
            onEdit(rowData);
            setAnchorEl(false);
          }}>
          <EditIcon />
          Edit
        </MenuItem>
        <MenuItem sx={style.redItem} onClick={handleClickDelete}>
          <DeleteIcon />
          Delete
        </MenuItem>
      </Menu>
    </Stack>
  );
}

export default CellActionsSimple;
