import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import api from 'api';
import { useAlert } from 'common/alertContext';
import { useCompanyQuery } from 'hooks/queries';
import { MESSAGE_SUCCESS_CHANGES } from 'litterals';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import { extractAttributesWithNullValues } from 'utils/functions';

const defaultValues = {
  name: '',
  hasTerms: false,
  linkTerms: '',
  feedbackLink: '',
  deactivatedPrograms_delay_before_deletion: 30,
};

const useCompany = () => {
  const { setStateAlert } = useAlert();
  const { t } = useTranslation();
  const { handleSubmit, control, reset, getValues, watch } = useForm({
    mode: 'all',
    defaultValues: defaultValues,
  });
  const [showDialog, setShowDialog] = useState(false);
  const queryClient = useQueryClient();

  const mutation = useMutation(api.Companies.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-list-companies-params']);
      setShowDialog(false);
      reset();
    },
  });

  const submitForm = handleSubmit((data) => {
    const payload = {
      ...data,
    };
    mutation.mutate(payload);
    setStateAlert({ open: true, message: `${t(MESSAGE_SUCCESS_CHANGES)}` });
  });

  const close = () => {
    setShowDialog(false);
    reset();
  };

  const clickAdd = () => setShowDialog(true);

  return {
    state: { form: { control }, nav: { showDialog } },
    actions: { clickAdd, close, submitForm, getValues, watch },
  };
};

export const useEditCompany = (company) => {
  const queryClient = useQueryClient();
  const { data, isLoading } = useCompanyQuery(company.id);
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(true);

  const [cacheBuster, setCacheBuster] = useState(Date.now());

  const {
    handleSubmit,
    control,
    watch,
    reset,
    resetField,
    formState: { isDirty },
    setValue,
  } = useForm({
    mode: 'all',
    defaultValues: {
      name: company.name,
      hasTerms: company.hasTerms,
      linkTerms: company.linkTerms,
      feedbackLink: company.feedbackLink,
      logo: company.logo,
      deactivatedPrograms_delay_before_deletion: company.deactivatedPrograms_delay_before_deletion,
    },
  });
  const { setStateAlert } = useAlert();

  useEffect(() => {
    reset({
      name: data?.name || undefined,
      hasTerms: data?.hasTerms || undefined,
      linkTerms: data?.linkTerms || undefined,
      feedbackLink: data?.feedbackLink || undefined,
      expertLabel: data?.expertLabel || undefined,
      sessionLabel: data?.sessionLabel || undefined,
      clientLabel: data?.clientLabel || undefined,
      feedbackLinkMentor: data?.feedbackLinkMentor || undefined,
      deactivatedPrograms_delay_before_deletion: data?.deactivatedPrograms_delay_before_deletion || undefined,
    });
  }, [data, reset]);

  const updateImage = () => {
    setCacheBuster(Date.now());
    queryClient.invalidateQueries(['get-coach']);
  };

  const onNavigate = () => {
    navigate(`/companies/${company.id}`);
  };

  const mutation = useMutation(api.Companies.edit, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['get-company']);
      queryClient.invalidateQueries(['get-list-companies-params']);
      setStateAlert({ open: true, message: t(MESSAGE_SUCCESS_CHANGES) });
      setShowDialog(false);
      setValue('logo', data.logo);
    },
    onError: (error) => {
      setStateAlert({ open: true, message: error.response.data.error, type: 'error' });
    },
  });

  const submitForm = handleSubmit(async (data) => {
    if (!!data.logo && typeof data.logo !== 'string') {
      const { url } = await api.Users.upload(data.logo);

      data.logo = process.env.REACT_APP_LINK_BUCKET_SCALEWAY + url.Key;
    } else {
      delete data.logo;
    }

    mutation.mutate({ id: company.id, data: extractAttributesWithNullValues(data) });

    updateImage();
  });

  return {
    state: {
      form: { control: control, resetField, isDirty },
      query: { data, isLoading },
      nav: { showDialog, setShowDialog },
    },
    actions: { watch, submitForm, onNavigate, updateImage },
  };
};

export const useDeleteCompany = () => {
  const queryClient = useQueryClient();
  const { setStateAlert } = useAlert();
  const mutation = useMutation(api.Companies.delete, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-list-companies-params']);
      setStateAlert({ open: true, message: 'company successfully deleted' });
    },
  });

  const remove = (id) => {
    mutation.mutate(id);
  };

  return {
    actions: { remove },
  };
};

export const useCompanyData = (companyId) => {
  const { handleSubmit, control, watch, setValue, getValues } = useForm({
    mode: 'all',
  });
  const queryClient = useQueryClient();
  const { setStateAlert } = useAlert();
  const navigate = useNavigate();
  const [company, setCompany] = useState({});

  // Mount
  const mutation = useMutation(api.Companies.get, {
    onSuccess: (data) => {
      setValue('name', data.name);
      setValue('hasTerms', data.hasTerms);
      setValue('linkTerms', data.linkTerms);
      setValue('feedbackLink', data.feedbackLink);
      setValue('expertLabel', data.expertLabel);
      setValue('clientLabel', data.clientLabel);
      setValue('sessionLabel', data.sessionLabel);
      setValue('feedbackLinkMentor', data.feedbackLinkMentor);
      setValue('logo', data.logo);
      setValue('preprogramLink', data.preprogramLink);
      setValue('deactivatedPrograms_delay_before_deletion', data.deactivatedPrograms_delay_before_deletion);
      setCompany(data);
    },
    onError: (error) => setStateAlert({ open: true, message: `${error.response.data.error}` }),
  });

  useEffect(() => {
    if (companyId) {
      mutation.mutate(companyId);
    }
  }, []);

  // Edit company
  const mutationEdit = useMutation(api.Companies.edit, {
    onSuccess: async (data) => {
      await queryClient.invalidateQueries(['get-list-companies-params']);
      setStateAlert({ open: true, message: t(MESSAGE_SUCCESS_CHANGES) });
    },
    onError: (error) => {
      setStateAlert({ open: true, message: error.response.data.error, type: 'error' });
    },
  });

  const _submitEditCompany = async (data) => {
    if (!!data.logo && typeof data.logo !== 'string') {
      const { url } = await api.Users.upload(data.logo);
      data.logo = url.Location;
      setValue('logo', url.Location);
    } else {
      delete data.logo;
    }
    mutationEdit.mutate({ id: companyId, data: extractAttributesWithNullValues(data) });
  };

  const submitEditCompanyWithNavigate = handleSubmit(async (data) => {
    await _submitEditCompany(data);
    navigate(-1);
  });

  const submitEditCompany = handleSubmit(async (data) => {
    await _submitEditCompany(data);
  });

  const submitEditLogoCompany = handleSubmit(async (data) => {
    if (!!data.logo && typeof data.logo !== 'string') {
      const { url } = await api.Users.upload(data.logo);
      data.logo = process.env.REACT_APP_LINK_BUCKET_SCALEWAY + url.Key;
      setTimeout(() => {
        setValue('logo', '' + process.env.REACT_APP_LINK_BUCKET_SCALEWAY + url.Key);
      }, 100);
    } else {
      delete data.logo;
    }
    mutationEdit.mutate({ id: companyId, data: extractAttributesWithNullValues(data) });
  });

  return {
    isLoading: mutation.isLoading,
    company,
    control,
    watch,
    getValues,
    submitEditCompany,
    submitEditCompanyWithNavigate,
    submitEditLogoCompany,
  };
};

export default useCompany;
