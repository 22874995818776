import React, { useState, useEffect, useRef } from 'react';
import { AppBar, Toolbar, Box, Alert, Typography } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Snackbar from '@mui/material/Snackbar';
import Button from 'ui/components/Button/Button';
import { showIntercomVisitor } from 'utils/intercom';

// Style
import styles from './Main.styles';

// Hooks
import { useTranslation } from 'react-i18next';

import useStore from 'store';
import { useEvergreen } from 'hooks/usecase/useEvergreen';

// Components
import Welcome from '../Welcome';
import SelectProgram from '../SelectProgram';
import About from '../About';
import ChooseExpert from '../ChooseExpert';
import Congratulations from '../Congratulations';

import { STEP1_SELECT_PROGRAM, STEP1_SUBTITLE } from 'litterals';
import ViewModal from 'ui/components/shared/ViewModal';

// constants
const PLACEHOLDER_RESOURCE = `${process.env.PUBLIC_URL}/uploads/images/Pathline-logo.svg`;

const Main = () => {
  const [isLaunched, setIsLaunched] = useState(false);
  const {
    steps,
    activeStep,
    setActiveStep,
    company,
    selectedProgram,
    setSelectedProgram,
    orderedSelectedExperts,
    coachee,
  } = useStore();
  const { isLoading, control, actions, state } = useEvergreen();

  const { t } = useTranslation();

  showIntercomVisitor();

  const handleCreateEvergreen = (overwriteProgramAuthorized = false) => {
    const body = {
      coaches: orderedSelectedExperts.map((item) => item.id),
      themeId: selectedProgram.id,
      ...coachee,
      overwriteProgramAuthorized,
    };
    actions.handleCreateEvergreen(body);
  };

  const getContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <Box sx={{ padding: '32px 48px 32px 48px', backgroundColor: 'white' }}>
            <Typography sx={{ fontSize: '30px', lineHeight: '37px', fontWeight: 700 }}>
              {t(STEP1_SELECT_PROGRAM)}{' '}
            </Typography>
            <Typography sx={{ fontSize: '20px', lineHeight: '37px', fontWeight: 400, color: '#00000099' }}>
              {t(STEP1_SUBTITLE, { sessionLabel: company.sessionLabel })}
            </Typography>
            <Box height={'20px'} />
            <SelectProgram
              onSelectProgram={(program) => {
                setActiveStep(1);
                setSelectedProgram(program);
              }}
              idCompany={company && company.id ? company.id : ''}
              company={company ? company : ''}
            />
          </Box>
        );
      case 1:
        return (
          <About
            onSubmit={() => {
              actions.handleSubmitCoachee();
            }}
            company={company ? company : ''}
            control={control}
            actions={actions}
            state={state}
          />
        );
      case 2:
        return (
          <ChooseExpert
            onSubmit={() => handleCreateEvergreen()}
            isLoadingSubmit={state.isLoadingApply}
            company={company ? company : ''}
            state={state}
            actions={actions}
          />
        );
      case 3:
        return <Congratulations company={company ? company : ''} sessionLabel={company.sessionLabel} />;
      default:
        break;
    }
  };
  const refContainer = useRef();
  useEffect(() => {
    if (refContainer && activeStep == 2) {
      refContainer.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [activeStep]);

  return (
    <div>
      {isLaunched ? (
        <Box
          ref={refContainer}
          sx={{
            maxHeight: 200,
            overflow: 'auto',
            minHeight: '100vh',
            padding: '0 50px',
            backgroundColor: 'background.secondary',
            overflowY: 'scroll',
          }}>
          {activeStep !== 3 ? (
            <AppBar sx={styles.navbar} elevation={5}>
              <Toolbar>
                <Box
                  component="img"
                  sx={{
                    maxHeight: '60px',
                    maxWidth: '100px',
                    width: 'auto',
                    height: 'auto',
                    objectFit: 'contain',
                  }}
                  src={company && company.logo ? company.logo : PLACEHOLDER_RESOURCE}
                />
              </Toolbar>
            </AppBar>
          ) : null}
          <Box sx={{ marginTop: activeStep !== 3 && '60px', paddingTop: '40px' }}>
            {activeStep !== 3 ? (
              <Box sx={{ padding: '0px 40px' }}>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label, index) => (
                    <Step key={label}>
                      <StepLabel>
                        <>
                          {t(label).replace(
                            '{{expertLabel}}',
                            company && company.expertLabel ? company.expertLabel : ''
                          )}
                          <br />
                          {index == 0 ? (selectedProgram && selectedProgram.name ? selectedProgram.name : '') : null}
                        </>
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>
            ) : null}
            {activeStep !== 3 && <Box height={'32px'} />}
            {/**Content */}
            {getContent()}
          </Box>
        </Box>
      ) : (
        <Welcome
          isLoading={isLoading}
          company={company}
          onStart={() => {
            setIsLaunched(true);
          }}
        />
      )}
      <ViewModal
        open={state.isOpenModalProgramAlreadyExists}
        onClose={actions.setIsOpenModalProgramAlreadyExists}
        customStyleBox={{
          padding: '24px 32px',
          borderRadius: '16px',
          position: 'relative',
          height: 'none',
          maxHeight: '80%',
          width: '500px',
        }}>
        <Typography
          sx={{
            fontSize: '24px',
            fontWeight: 500,
          }}>
          {t('program_already_exists_evergreen.title')}
        </Typography>
        <Typography
          sx={{
            paddingTop: '8px',
            fontSize: '16px',
            fontWeight: 300,
            textAlign: 'justify',
          }}>
          {t('program_already_exists_evergreen.desc')}
        </Typography>

        <Box sx={{ marginTop: '16px', textAlign: 'right' }}>
          <Button variant="secondary" onClick={() => actions.setIsOpenModalProgramAlreadyExists(false)}>
            {t('go_back')}
          </Button>
          <Button
            sx={{ marginLeft: '12px' }}
            variant="primary"
            onClick={() => {
              actions.setIsOpenModalProgramAlreadyExists(false);
              handleCreateEvergreen(true);
            }}>
            {t('program_already_exists_evergreen.continue_button')}
          </Button>
        </Box>
      </ViewModal>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={state.stateAlert.open}
        autoHideDuration={3000}
        onClose={() => state.setStateAlert((previousState) => ({ ...previousState, open: false }))}>
        <Alert
          severity={state.stateAlert.type}
          variant="filled"
          sx={{ width: '100%' }}
          onClose={() => state.setStateAlert((previousState) => ({ ...previousState, open: false }))}>
          {state.stateAlert.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Main;
