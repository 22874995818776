import React from 'react';
import { LOGIN_MICROSOFT } from 'litterals';
import Button from 'ui/components/Button/Button';
import { useTranslation } from 'react-i18next';
import BusinessIcon from '@mui/icons-material/Business';

export default function Switch({ onSubmit, label, sx }) {
  const { t } = useTranslation();

  return (
    <Button
      variant="secondary"
      onClick={onSubmit}
      sx={{
        m: '12px',
        color: '#5E5E5E',
        width: 'fill-available',
        borderColor: '#D0D5DD',
        ...sx,
      }}>
      <BusinessIcon sx={{ mr: '12px' }} />
      {label || t(LOGIN_MICROSOFT)}
    </Button>
  );
}
