import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import styles from './EditMentorModal.styles';
import { useTranslation } from 'react-i18next';
import ViewModal from 'ui/components/shared/ViewModal';
import Experts from 'ui/pages/subscription/create/Experts';
import { useExperts } from 'hooks/usecase/useExperts';
import CoachView from 'ui/components/shared/coaches/CoachView';
import GoBackButton from 'ui/components/shared/GoBackButton';

const EditMentorModal = ({ open, coachee, expertLabel, mentorIdSelected, onClose, onChangeMentor }) => {
  const { t } = useTranslation();
  const [mentorIdDetailSelected, setMentorIdDetailSelected] = useState('');
  const [temporaryMentorSelected, setTemporaryMentorSelected] = useState({});
  const [queryParams, setQueryParams] = useState({ active: true, page: 1, size: 10 });

  const { experts, isLoading, totalPages, totalItems } = useExperts(queryParams);

  const expertsRequest = {
    rows: experts,
    totalPages,
    totalItems,
  };

  const onCloseModal = () => {
    setMentorIdDetailSelected('');
    onClose();
  };

  const stylesContainer = !mentorIdDetailSelected
    ? styles.container
    : { ...styles.container, padding: '0 0 20px 0', minWidth: '80%', overflowY: 'auto !important' };

  return (
    <div>
      <ViewModal open={open} onClose={onCloseModal} customStyleBox={stylesContainer}>
        {!mentorIdDetailSelected && (
          <Box>
            <Box>
              <Typography variant="h1" sx={styles.title}>
                {t('edit_mentor_modal_title', { expertLabel })}
              </Typography>
              <Typography variant="h3" sx={styles.subTitle}>
                {t('edit_mentor_modal_subtitle', { expertLabel })}
              </Typography>
            </Box>
            <Box sx={{ width: '100%', margin: 'auto' }}>
              {
                <Experts
                  activeBorder
                  coachee={coachee}
                  experts={expertsRequest}
                  expertIdSelected={temporaryMentorSelected?.id || mentorIdSelected}
                  hiddenAddButton={true}
                  onClickSelect={(mentor) => {
                    setTemporaryMentorSelected(mentor);
                  }}
                  setQueryParams={setQueryParams}
                  queryParams={queryParams}
                  isLoading={isLoading}
                  showCoachViewInModal={false}
                  onCoachDetail={(mentorId) => {
                    setMentorIdDetailSelected(mentorId);
                  }}
                />
              }
            </Box>
            <Box sx={styles.containerButtons}>
              <Button onClick={onCloseModal} variant="outlined">
                {t('cancel')}
              </Button>
              <Button
                disabled={!temporaryMentorSelected?.id}
                onClick={() => {
                  onChangeMentor(temporaryMentorSelected.id);
                  onCloseModal();
                }}
                sx={styles.validButton}
                variant="contained">
                {t('edit_theme_modal_valid')}
              </Button>
            </Box>
          </Box>
        )}
        {mentorIdDetailSelected && (
          <Box>
            <GoBackButton
              onClick={() => {
                setMentorIdDetailSelected(null);
              }}
              sx={styles.returnButton}
            />

            <Box sx={{ margin: '0 32px 32px 32px' }}>
              <CoachView coach={expertsRequest.rows.find(({ id }) => id === mentorIdDetailSelected)} editable={false} />
            </Box>
          </Box>
        )}
      </ViewModal>
    </div>
  );
};

export default EditMentorModal;
