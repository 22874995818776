import { useReducer, useEffect, useState } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import api from 'api';
import useCoachesView from 'hooks/shared/useCoachesView';
import useInviteCoachee from './useInviteCoachee';
import { useListScopeQuery, useMeQuery } from 'hooks/queries';
import useNavStepperReducer from 'hooks/shared/useNavStepper';

const useFormReducer = () =>
  useReducer(
    (state, action) => {
      switch (action.type) {
        case 'SELECT_THEME':
          return { ...state, theme: action.payload };
        case 'ADD_COACH':
          return {
            ...state,
            coaches: { ...state.coaches, [action.payload.id]: action.payload },
          };
        case 'REMOVE_COACH':
          delete state.coaches[action.payload];
          return { ...state };
        case 'ADD_COACHEE':
          return {
            ...state,
            coachees: { ...state.coachees, [action.payload.id]: action.payload },
          };
        case 'REMOVE_COACHEE':
          delete state.coachees[action.payload];
          return { ...state };
        case 'RESET_STATE':
          return {
            theme: { id: null, name: '' },
            coaches: {},
            coachees: {},
          };
      }
    },
    {
      theme: { id: '', name: '' },
      coaches: {},
      coachees: {},
    }
  );

const useCreateSubscription = ({ currentPage, size, ...rest }) => {
  const [navState, navDispatch] = useNavStepperReducer();
  const [subId, setSubId] = useState();
  const [loadingConfirmation, setLoadingConfirmation] = useState(false);
  const [sendEmailToCoach, setSendEmailToCoach] = useState(true);
  const [sendEmailToCoachee, setSendEmailToCoachee] = useState(true);
  const [formState, formDispatch] = useFormReducer();
  const { data: user } = useMeQuery();

  const themeQuery = useQuery(
    ['list-format-themes', user?.company?.id],
    () => api.Companies.listTheme(user?.company?.id),
    { enabled: !!user?.company?.id }
  );

  const scopesQuery = useListScopeQuery();
  const { state: stateCoaches, actions: actionsCoaches } = useCoachesView({
    initialFilters: {
      active: true,
      page: currentPage,
      size: size,
      ...rest,
    },
  });
  const { state: stateCoachees, actions: actionsCoachees } = useInviteCoachee((payload) =>
    formDispatch({ type: 'ADD_COACHEE', payload })
  );

  const state = {
    nav: {
      ...navState,
      ...stateCoaches.nav,
      inviteFormDisabled: stateCoachees.nav.disabled,
      subId: subId,
    },
    form: formState,
    user: user,
    inviteForm: { control: stateCoachees.form.control },
    sendEmailToCoach: sendEmailToCoach,
    sendEmailToCoachee: sendEmailToCoachee,
    query: {
      themes: themeQuery,
      coaches: {
        coaches: stateCoaches?.query?.coaches,
        total: stateCoaches?.query?.data?.total,
        isLoading: stateCoaches?.query.isLoading,
      },
      scopes: scopesQuery,
      loadingConfirmation,
    },
  };

  const clickNext = () => {
    navDispatch({ type: 'NEXT_STEP' });
    navDispatch({ type: 'ACTIVE_PREV' });
  };

  const clickPrev = () => {
    navDispatch({ type: 'PREV_STEP' });
  };

  const selectTheme = (theme) => {
    formDispatch({ type: 'SELECT_THEME', payload: theme });
    clickNext();
  };

  const selectCoach = (coach) => {
    formDispatch({ type: 'ADD_COACH', payload: coach });
    actionsCoaches.disableTab(coach.internalCoach ? 'EXTERNAL' : 'INTERNAL');
  };

  const removeCoach = (id) => {
    if (Object.keys(state.form.coaches).length === 1) {
      actionsCoaches.disableTab(null);
    }
    formDispatch({ type: 'REMOVE_COACH', payload: id });
  };

  const removeCoachee = (id) => {
    if (Object.keys(state.form.coachees).length === 1) {
      actionsCoaches.disableTab(null);
    }
    formDispatch({ type: 'REMOVE_COACHEE', payload: id });
  };

  const linkMutation = useMutation(api.Subscriptions.linkCoachees, {
    onSuccess: () => {
      navDispatch({ type: 'NEXT_STEP' });
      navDispatch({ type: 'DISABLE_PREV' });
    },
  });
  const createMutation = useMutation(api.Programs.create, {
    onSuccess: () => {
      navDispatch({ type: 'NEXT_STEP' });
      navDispatch({ type: 'DISABLE_PREV' });
      setLoadingConfirmation(false);
    },
  });

  const createSubscription = () => {
    const subscription = {
      sendEmailToCoach: sendEmailToCoach,
      sendEmailToCoachee: sendEmailToCoachee,
      themeId: state.form.theme.id,
      coaches: Object.values(state.form.coaches).map((item) => item.id),
      coacheeId:
        typeof state.form.coachees === 'object' &&
        state.form.coachees !== null &&
        Object.keys(state.form.coachees).length > 0
          ? Object.keys(state.form.coachees)[0]
          : '',
      scopeId:
        formState.coachees !== null &&
        Object.keys(formState.coachees).length > 0 &&
        Object.values(formState.coachees)[0].userScope
          ? Object.values(formState.coachees)[0].userScope
          : Object.values(formState.coachees)[0].scopeId && Object.values(formState.coachees)[0].scopeId,
    };
    setLoadingConfirmation(true);
    createMutation.mutate(subscription);
  };

  const reset = () => {
    formDispatch({ type: 'RESET_STATE' });
    navDispatch({ type: 'RESET_STEP' });
    actionsCoachees.resetForm();
  };

  useEffect(() => {
    switch (state.nav.activeStep) {
      case 0:
        return state.form.theme.id ? navDispatch({ type: 'ACTIVE_NEXT' }) : navDispatch({ type: 'DISABLE_NEXT' });
      case 2:
        return Object.keys(state.form.coaches).length
          ? navDispatch({ type: 'ACTIVE_NEXT' })
          : navDispatch({ type: 'DISABLE_NEXT' });
      case 1:
        return Object.keys(state.form.coachees).length
          ? navDispatch({ type: 'ACTIVE_NEXT' })
          : navDispatch({ type: 'DISABLE_NEXT' });
      case 3:
        navDispatch({ type: 'DISABLE_NEXT' });
        return;
    }
  }, [state.nav.activeStep, state.form]);

  const changeTab = (value) => {
    actionsCoaches.updateFilterCoachState(value);
  };

  return {
    state,
    actions: {
      clickNext,
      clickPrev,
      selectTheme,
      selectCoach,
      removeCoach,
      removeCoachee,
      createSubscription,
      reset,
      changeTab,
      isAddLoading: actionsCoachees.isAddLoading,
      submitInviteForm: actionsCoachees.submitForm,
      submitInviteCoachee: actionsCoachees.handleInviteCoachee,
      resetInviteForm: actionsCoachees.resetForm,
      updateFilterCoachState: actionsCoaches.updateFilterCoachState,
      setSendEmailToCoach,
      setSendEmailToCoachee,
    },
  };
};

export default useCreateSubscription;
