import React, { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import { useMeQuery } from 'hooks/queries';
import { useTranslation } from 'react-i18next';
import useFormSettings from 'hooks/usecase/useFormSettings';
import separateData from './DataProcessor';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { styled, alpha } from '@mui/system';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import Button from 'ui/components/Button/Button';

const GreenSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#2E7D32 !important',
    '&:hover': {
      backgroundColor: alpha('#2E7D32 !important', theme.palette.action.hoverOpacity),
    },
    '&.Mui-disabled ': {
      opacity: 0.5,
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#2E7D32 !important',
  },
}));

const MANDATORY_LIST = [
  'role',
  'firstname',
  'lastname',
  'email',
  'employeeID',
  'title',
  'currentLocation',
  'sector',
  'seniorityLevel',
  'genderIdentity',
  'spokenLanguages',
  'department',
  'internal',
  'objectives_expertises',
  'bio',
  'expectations',
  'maxCapacity',
  'timeZone',
];

const isMandatory = (value) => {
  return MANDATORY_LIST.includes(value);
};

const FormSection = ({ title, switchData, handelSwitch, company }) => {
  const { t } = useTranslation();

  return (
    <Stack
      sx={{
        maxWidth: '780px',
        border: '1px solid rgba(0, 0, 0, 0.15)',
        borderRadius: '4px',
        padding: '15px',
        margin: '10px',
      }}>
      {Object.entries(switchData).length > 1 ? (
        <>
          <Typography
            variant="h4"
            sx={{
              fontFamily: 'Karla',
              fontSize: '20px',
              fontWeight: 700,
              lineHeight: '133.4%',
              letterSpacing: '0.15px',
              fontFeatureSettings: "'clig' off, 'liga' off",
              color: 'rgba(0, 0, 0, 0.75)',
            }}>
            {t(title)}
          </Typography>
          <div
            style={{
              display: 'flex',
              gap: '40px',
              flexWrap: 'wrap',
              alignItems: 'center',
              padding: '15px',
              justifyContent: 'space-between',
            }}>
            {Object.entries(switchData).map(([field, value]) => (
              <>
                {value.Label && (
                  <div
                    key={field}
                    style={{
                      width: '300px',
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '60px',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                      <p>
                        {t(value.Label)}
                        {isMandatory(value.fieldName) ? '*' : null}
                      </p>
                      {value?.Tooltip && (
                        <Tooltip
                          title={t(value?.Tooltip)
                            .replace(
                              '{{expertLabel}}',
                              company && company.expertLabel ? company.expertLabel.toLowerCase() : ''
                            )
                            .replace(
                              '{{clientLabel}}',
                              company && company.clientLabel ? company.clientLabel.toLowerCase() : ''
                            )
                            .replace(
                              '{{sessionLabel}}',
                              company && company.sessionLabel ? company.sessionLabel.toLowerCase() : ''
                            )}>
                          <InfoIcon fontSize="small" style={{ color: '#04040480' }} />
                        </Tooltip>
                      )}
                    </div>

                    {!value.isDisableable ? (
                      <Tooltip title={t('tooltip_mandatory_fields_applicationForm')}>
                        <div>
                          <GreenSwitch
                            {...{ [field]: value.isActivated }}
                            checked={value.isActivated}
                            onChange={() => handelSwitch(value)}
                            disabled={!value.isDisableable}
                          />
                        </div>
                      </Tooltip>
                    ) : (
                      <GreenSwitch
                        {...{ [field]: value.isActivated }}
                        checked={value.isActivated}
                        onChange={() => handelSwitch(value)}
                      />
                    )}
                  </div>
                )}
              </>
            ))}
          </div>
        </>
      ) : (
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',

            justifyContent: 'space-between',
          }}>
          {Object.entries(switchData).map(([field, value]) => (
            <div
              key={field}
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingRight: '15px',
                alignItems: 'center',
              }}>
              <Typography
                variant="h4"
                sx={{
                  fontFamily: 'Karla',
                  fontSize: '20px',
                  fontWeight: 700,
                  lineHeight: '120.4%',
                  letterSpacing: '0.15px',
                  fontFeatureSettings: "'clig' off, 'liga' off",
                  color: 'rgba(0, 0, 0, 0.75)',
                  alignItems: 'center',
                }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                  <p>
                    {t(value.Label).replace(
                      '{{clientLabel}}',
                      company && company.clientLabel ? company.clientLabel.toLowerCase() : ''
                    )}
                    {isMandatory(value.fieldName) ? ' *' : null}
                  </p>
                  {value?.Tooltip && (
                    <Tooltip
                      title={t(value?.Tooltip)
                        .replace(
                          '{{clientLabel}}',
                          company && company.clientLabel ? company.clientLabel.toLowerCase() : ''
                        )
                        .replace(
                          '{{clientLabel}}',
                          company && company.clientLabel ? company.clientLabel.toLowerCase() : ''
                        )
                        .replace(
                          '{{expertLabel}}',
                          company && company.expertLabel ? company.expertLabel.toLowerCase() : ''
                        )
                        .replace(
                          '{{sessionLabel}}',
                          company && company.sessionLabel ? company.sessionLabel.toLowerCase() : ''
                        )}>
                      <InfoIcon fontSize="small" style={{ color: '#04040480' }} />
                    </Tooltip>
                  )}
                </div>
              </Typography>
              <GreenSwitch
                {...{ [field]: value.isActivated }}
                checked={value.isActivated}
                onChange={() => handelSwitch(value)}
                disabled={!value.isDisableable}
              />
            </div>
          ))}
        </div>
      )}
    </Stack>
  );
};

const RegistrationForm = () => {
  const { t } = useTranslation();
  const { data: user } = useMeQuery();
  const [userRole, setUserRole] = useState('Caoch');
  const [queryParams, setQueryParams] = useState({ isMentor: true });
  const { formSettingsList, isLoading, onEdit } = useFormSettings(user.company.id, queryParams);
  const [result, setResult] = useState();
  const [updateField, setUpdateField] = useState(false);

  useEffect(() => {
    if (formSettingsList && formSettingsList.formFields) {
      const processedData = separateData(formSettingsList.formFields, userRole);
      setResult(processedData);
      // Do something with the result in your main component
    }
  }, [formSettingsList, updateField]);

  const handleChange = (event) => {
    const selectedRole = event.target.value;
    setUserRole(selectedRole);
    const updatedParams = {
      isMentor: selectedRole === 'Caoch',
    };
    setQueryParams(updatedParams);
  };

  const handelSwitch = (field) => {
    onEdit({ id: field.id, isActivated: !field.isActivated });
    setUpdateField(!updateField);
  };

  return (
    <Stack sx={{ minHeight: '630px', width: '100%', padding: '25px 0px', backgroundColor: 'white' }}>
      <div style={{ gap: '23px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <Paper
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '16px 0px',
            boxShadow: 'none',
            width: 'fit-content',
          }}>
          <Box>
            <Typography
              variant="subtitle1"
              sx={{
                color: 'rgba(0, 0, 0, 0.87)',
                fontFamily: 'Karla',
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '123.5%',
                letterSpacing: '0.25px',
                fontFeatureSettings: "'clig' off, 'liga' off",
              }}>
              {t('registration_form_tab_information')}
            </Typography>
          </Box>
        </Paper>

        <Button
          sx={{ marginTop: '10px' }}
          variant="primary"
          onClick={() =>
            window.open(window.location.origin + '/program-application?applicationLink=' + user.company.applicationLink)
          }>
          {t('view_form')}
        </Button>
        <div style={{ gap: '23px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Typography
            component="h1"
            variant="h4"
            sx={{
              fontFamily: 'Karla',
              fontSize: '24px',
              fontStyle: 'normal',
              fontWeight: 700,
              lineHeight: '133.4%',
              letterSpacing: '0.15px',
              fontFeatureSettings: "'clig' off, 'liga' off",
            }}>
            {t('user_role')}
          </Typography>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 160, maxWidth: '220px' }}>
            <Select
              style={{ fontSize: '20px', fontWeight: 400, lineHeight: '150%' }}
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={userRole}
              onChange={handleChange}>
              <MenuItem value="Caoch">{user?.company?.expertLabel}</MenuItem>
              <MenuItem value="Coachee">{user?.company?.clientLabel}</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      {result && (
        <>
          <FormSection
            title={result.ProfileInfo.title}
            switchData={result.ProfileInfo?.info}
            handelSwitch={handelSwitch}
            company={user.company}
          />
          <FormSection
            title={t('application_form_title_matching_info')}
            switchData={result.MatchingInfo?.info}
            handelSwitch={handelSwitch}
            company={user.company}
          />
          <FormSection
            title={t('portrait_photo_upload')}
            switchData={result.FormPic?.info}
            handelSwitch={handelSwitch}
            company={user.company}
          />
          {result?.internal && (
            <FormSection
              title={t('internal_external')}
              switchData={result.internal?.info}
              handelSwitch={handelSwitch}
              company={user.company}
            />
          )}
          {result?.maxCapacity && (
            <FormSection
              title={t('maxCapacity')}
              switchData={result.maxCapacity?.info}
              handelSwitch={handelSwitch}
              company={user.company}
            />
          )}
          <FormSection
            title={t('expertises')}
            switchData={result.objectives_expertises?.info}
            handelSwitch={handelSwitch}
            company={user.company}
          />
          {result?.bio && (
            <FormSection
              title={t('bio_label')}
              switchData={result.bio?.info}
              handelSwitch={handelSwitch}
              company={user.company}
            />
          )}
          <FormSection
            title={t('expectations_label')}
            switchData={result.expectations?.info}
            handelSwitch={handelSwitch}
            company={user.company}
          />
          {result?.managerEmail && (
            <FormSection
              title={t('managerEmail')}
              switchData={result.managerEmail?.info}
              handelSwitch={handelSwitch}
              company={user.company}
            />
          )}
        </>
      )}
    </Stack>
  );
};
//  internal, objectives_expertises, bio , expectations
export default RegistrationForm;
